import {introductionVideos, openIntroductionVideo} from "../../../utils/introduction-videos";
//import "./orders-prepare.scss";
import Cookies from "js-cookie";
import moment from "moment/moment";
import {list} from "postcss";
import {getUserInfo} from "../../../utils/users";

class ShelfUpController {
    constructor(API, ToastService, DialogService, $state, $location, $window, $sce, $timeout, $scope, $rootScope, $mdBottomSheet) {
        "ngInject";

        this.API = API;
        this.window = $window;
        this.$scope = $scope;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$location = $location;
        this.sce = $sce
        this.$timeout = $timeout
        this.$rootScope = $rootScope;
        this.$mdBottomSheet = $mdBottomSheet;

        this.falseBeep = new Audio('./files/error.wav');
        this.trueBeep = new Audio('./files/success.wav');

        this.priority = {
            1: 'normal',
            2: 'high',
            3: 'very-high',
            4: 'urgent',
        }

        this.userInfo = getUserInfo();
        this.currentUserId = this.userInfo.uid;

        this.shelfUpScreen = null;
        this.shelfUpTabScreen = 'list';
        this.shelfUpDetail = null;
        this.barcode = null;
        this.shelfBarcode = null;
        this.activeProduct = null;

        this.selectLots = [];
        this.shelfBarcodeFocus = true;
        this.prodBarcodeFocus = false;
    }

    $onInit() {
        let parent = this;
        parent.getPrepareProcess()
        parent.$scope.$on('prepareEvent', function (event, data) {
            if (data.screen === 'shelfUp') {
                parent.getPrepareProcess()
            }else {
                document.querySelector('md-toolbar').hidden = false;
                document.querySelector('md-tabs-wrapper').hidden = false;
            }
        });

        this.$scope.$on('selectProductEvent', function (event, selectProduct){
            //if (parent.selectedTabIndex == parent.wrapperTabIndex) {
              //  parent.addOfferItem(selectProduct.id);
            //}
            // raftan mı ürün den mi geldi ayrımı yaparsak raf için de kullanabiliriz.
            parent.barcode = selectProduct.ts_barcode;
            parent.barcodeCheck(parent.barcode,'product')
        });
    }


    getPrepareProcess() {
        this.productBarcode = null
        this.API.all("action-process/prepare").doGET(null, {
            listType: 'shelfUp'
        })
            .then((response) => {
                if (204 === response.response.status) {
                    this.pendingPrepareList = []
                    this.ownPrepareList = []
                    this.$scope.$emit('prepareChildEvent', {count: {shelfUp: 0}});
                    return;
                }

                this.pendingPrepareList = response.data.list.filter(list => !list.prepareUserId)
                this.ownPrepareList = response.data.list.filter(list => list.prepareUserId === this.currentUserId)

                this.$scope.$emit('prepareChildEvent', {count: {shelfUp: response.data.count}});

            });
    }

    listAssignMe(listId = null) {

        this.API.all(`action-process/assignPrepare/${listId ?? '_'}/assign`)
            .customPUT({
                listType: 'shelfUp'
            })
            .then((response) => {
                this.ToastService.showHtml('success', 'Atama işlemi yapıldı yönlendiriliyorsunuz')
                this.getShelfUpList(response.data.listId)
            });
    }

    getShelfUpList(listId) {
        this.API.all('action-process/prepare/' + listId)
            .doGET()
            .then((response) => {
                this.shelfUpDetail = response.data.list

                this.activeProduct = this.shelfUpDetail.lines.find(line =>{
                    return line.remainingQuantity>0;
                })
                this.shelfUpTabScreen='prepare'
                this.$rootScope.$broadcast('toggleSidebarEvent', {});
                document.querySelector('md-toolbar').hidden = true;
                document.querySelector('md-tabs-wrapper').hidden = true;
            });
        this.shelfBarcodeFocus = true;
        this.shelfInput = false;
    }

    showOtherShelves() {
        let shelves = this.activeProduct.shelves;
        let dialog = this.DialogService
        var options = {
            controllerAs      : 'vm',
            bindToController  : true,
            onShowing         : function (scope, element, options, controller){
                scope.dialog = dialog;
                scope.shelves = shelves;
                scope.current = -1;
                scope.selectShelf = (shelf) =>{
                    scope.dialog.$mdDialog.hide(shelf)
                }

            },
        };

        var dialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/orders-prepare/partials/dialogs/other-shelves-dialog.html',
            options
        );
        dialogPromise.then(shelf=>{
            this.activeProduct.selectedShelf = shelf
            this.shelfBarcode = shelf.barcode
            this.trueBeep.play()
        })
        dialogPromise.catch(err=>{
            console.log(err)
        })

    }

    barcodeCheck(barcode, type) {
        this.API.all(`action-process/prepare/${this.shelfUpDetail.listId}/barcode-check`)
            .customGET(null, {
                barcode : barcode,
                type    : type
            })
            .then((response) => {
                let list = response.data.list
                if (list.usable !== true) {
                    this.falseBeep.play()
                    this.ToastService.showHtml('error', 'Bu barkod sistemde tanımlı değil')
                    return
                }

                if ('shelf' === list.barcodeType) {
                    let newShelf = this.activeProduct.shelves.filter(l => l.barcode === barcode)
                    if (newShelf.length === 0 && this.activeProduct.shelves.length > 0){
                        var options = {
                            controller: "OrdersPrepareController",
                            controllerAs: 'vm',
                        };
                        let OPDialogPromise = this.DialogService.fromTemplateV2(
                            './views/angular/app/components/orders-prepare/partials/dialogs/shelf-up-confirm.component.html',
                            options
                        ).then(() => {
                            this.activeProduct.selectedShelf = this.activeProduct.shelves.filter(shelf => shelf.barcode === barcode)[0]
                            this.ToastService.showHtml('success', 'Raf Değiştirildi')
                            this.shelfBarcode = barcode
                            this.trueBeep.play()
                            this.shelfInput = true;
                            // this.shelfBarcodeFocus = false;
                            this.prodBarcodeFocus = true;
                        }).catch(()=>{
                            this.shelfBarcode = null;
                        })
                    } else {
                        this.activeProduct.selectedShelf = this.activeProduct.shelves.filter(shelf => shelf.barcode === barcode)[0]
                        this.ToastService.showHtml('success', 'Raf Değiştirildi')
                        this.shelfBarcode = barcode
                        this.trueBeep.play()
                        this.shelfInput = true;
                        this.prodBarcodeFocus = true;
                    }
                    return;
                }
                this.selectActiveProduct(barcode)
            }).catch(err => {
            this.falseBeep.play()
            this.ToastService.showHtml('error', 'Bir hata oluştu');
            console.log(err);
        });

        this.barcode = null;
    }
    manuelQuantityCheck(){
        if (this.activeProduct.quantity === 1 || this.activeProduct.remainingQuantity === 1) {
            this.sendListItem();
            return;
        }
        this.multipleQuantity()
    }
    selectShelf(shelf){
        this.activeProduct.selectedShelf = shelf
        this.shelfBarcode = shelf.barcode
        this.ToastService.showHtml('success', 'Raf Seçildi')
        this.barcode = null;
        this.trueBeep.play()
        this.shelfInput = true;
        this.prodBarcodeFocus = true;
    }
    shelfInputClear(){
        this.shelfBarcode = null;
        this.shelfBarcodeFocus = true;
        this.shelfInput = false;
    }
    selfChanges(){
        this.$state.params.tab = 5;
        this.$location.search({tab:5});
        this.closePrepareScreen();
    }

    showBottomView() {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                isShowCamera: true
            }
        })
    }
    showCameraView() {
        var parent = this;
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
            controller: "BottomCameraController",
            controllerAs: "vm",
            bindToController: true,
            locals: {}
        }).then(response => {
            parent.shelfBarcode = response;
        })
            .catch(function (error) {
                console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
            });
    }
    selectActiveProduct(barcode) {
        if (barcode === this.activeProduct.barcode){

            return this.preparedListItem()
        }
        let select = this.shelfUpDetail.lines.filter(l => l.barcode === barcode)[0]
        if (select.remainingQuantity>0){
            this.activeProduct = select;
            this.shelfBarcode = null;
            this.shelfInput = false;
            this.shelfBarcodeFocus = true;
            this.selectLots = [];
            this.barcode = barcode;
        }
    }

    previousProduct() {
        let currentIndex = this.shelfUpDetail.lines.findIndex(s=>s.barcode === this.activeProduct.barcode);
        currentIndex = currentIndex === 0 ? this.shelfUpDetail.lines.length - 1 : currentIndex - 1;
        this.activeProduct = this.shelfUpDetail.lines[currentIndex]

        if (this.activeProduct.remainingQuantity === 0){
            this.previousProduct()
            return
        }
        this.shelfBarcode = null;
        this.barcode = null;
        this.shelfBarcodeFocus = true;
        this.shelfInput = false;
        this.selectLots = [];

    };

    nextProduct() {
        let currentIndex = this.shelfUpDetail.lines.findIndex(s=>s.barcode === this.activeProduct.barcode);
        currentIndex = (currentIndex === this.shelfUpDetail.lines.length - 1) ? 0 : currentIndex + 1;
        this.activeProduct = this.shelfUpDetail.lines[currentIndex]

        if (this.activeProduct.remainingQuantity === 0){
            this.nextProduct()
            return
        }
        this.shelfBarcode = null;
        this.barcode = null;
        this.shelfBarcodeFocus = true;
        this.shelfInput = false;
        this.selectLots = [];
    };

    closePrepareScreen() {
        this.shelfUpTabScreen = 'list'
        this.activeProduct = null
        this.shelfUpDetail = null
        this.shelfBarcode = null;
        this.barcode = null;
        this.selectLots = [];

        this.getPrepareProcess()

        this.$rootScope.$broadcast('toggleSidebarEvent', {});

        document.querySelector('md-toolbar').hidden = false;
        document.querySelector('md-tabs-wrapper').hidden = false;
    }
    multipleQuantity(freeRequirement = this.activeProduct.remainingQuantity){
        this.quantityOpen = true
        var options = {
            controller: "MultipleQuantityController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                stocktakingShelfCode            : this.shelfBarcode,
                actionPrepareProductListSelect  : this.activeProduct,
                quantity                        : this.activeProduct.quantity,
                remainingQuantity               : freeRequirement,
                productQuantity                 : 1,
                productBarcode                  : this.activeProduct.barcode,
                orderListType                   : 'receiving'
            }
        }
        this.DialogService.fromTemplateV2("./views/angular/app/components/orders-prepare/partials/multiple-quantity-modal.html",options)
            .then((response)=>{
                if(response.message){
                    this.ToastService.error(response.message)
                }
                if(response.productQuantity){
                    this.sendListItem(response.productQuantity)
                }
            })
    }

    preparedListItem() {
        if (!this.shelfBarcode){
            this.ToastService.showHtml('warn','Önce Rafı Seçin')
            return;
        }
        let selectLot = this.selectLots.filter(s=>s.select)

        let lotRequirement = 0;
        let freeRequirement = 0;
        let takenFreeQuantity = 0;
        let takenLotQuantity = 0;

        this.activeProduct?.actionLotRelation?.forEach(a=> lotRequirement +=a.quantity)
        freeRequirement = this.activeProduct.quantity - lotRequirement;

        this.activeProduct?.takenShelves?.forEach(t=> {
            if (!t.lotId){
                takenFreeQuantity += t.quantity
            }else {
                takenLotQuantity +=  parseInt(t.quantity)
            }
        })

        lotRequirement -= takenLotQuantity;
        freeRequirement -= takenFreeQuantity;
        if (this.activeProduct.actionLotRelation?.length > 0 && selectLot.length === 0 && freeRequirement === 0) {
            this.ToastService.showHtml('warn','En az 1 lot seçmelisin')
            return;
        }

        if (this.activeProduct.actionLotRelation?.length ===0 || freeRequirement>0){
             return  this.multipleQuantity(freeRequirement)
        }

        let quantity = 0;
        selectLot.forEach(s => quantity += parseInt(s.remainingQuantity))

        this.sendListItem(quantity,selectLot.map(s=>{return {lotId:s.lotId,quantity:s.remainingQuantity}}))
    }

    sendListItem(quantity = 1, selectLot = []) {
        this.API.all('action-process/prepare/' + this.shelfUpDetail.listId)
            .customPOST({
                shelf     : this.shelfBarcode,
                barcode   : this.barcode,
                quantity  : quantity,
                lotList   : selectLot,
                productId : this.activeProduct.productId,
                subProductId  : this.activeProduct.subProductId,
            })
            .then((response) => {
                let parent = this;
                if (response.data.list?.processComplete){

                    var options = {
                        controllerAs      : 'vm',
                        bindToController  : true,
                        onShowing         : function (scope, element, options, controller){
                            scope.reportName          = 'Rafa Yerleştirme';
                            scope.report              = response.data.list.report;
                            scope.collapsed           = false;
                        },
                    };

                    let OPDialogPromise = this.DialogService.fromTemplateV2(
                        './views/angular/app/components/orders-prepare/partials/dialogs/prepare-report.html',
                        options
                    ).then((status) => {
                        parent.closePrepareScreen()
                    }).catch(()=>{
                        parent.closePrepareScreen()

                    })
                    return false
                }
                parent.getShelfUpList(this.shelfUpDetail.listId)
                parent.getPrepareProcess()
                this.shelfInput = false;
                this.shelfBarcodeFocus = true;
                this.shelfBarcode = null;
                this.barcode = null;
                this.trueBeep.play();
            })
            .catch((response)=>{
                this.falseBeep.play()
            })
    }
    goShelfChange(){
        this.shelfUpTabScreen = 'list';
        this.$state.params.tab =4
        this.$rootScope.$broadcast('shelfChangeEvent',{
            tab:4,
            locationId:this.activeProduct.locationId,
            barcode:this.activeProduct.barcode
        })
        this.$rootScope.$broadcast('toggleSidebarEvent', {});

        document.querySelector('md-toolbar').hidden = false;
        document.querySelector('md-tabs-wrapper').hidden = false;
        document.querySelector('.md-content-scroll').style.height = 'calc(100vh - 90px)';
    }

    showListDetail(listId) {
        let options = {
            controller: "IssuesDetailController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                listId: listId
            },
            onShowing: function (scope, element, options, controller) {
                controller.getList()
            },
        }
        this.DialogService.fromTemplateV2("./views/angular/app/components/issues/partials/issues-detail.html",options)
            .then((response)=>{
            })
    }
}

ShelfUpController.$inject = ["API", "ToastService", "DialogService", "$state", "$location", "$window", "$sce", "$timeout", "$scope", "$rootScope", "$mdBottomSheet"];
const ShelfUpComponent = {
    templateUrl: "./views/angular/app/components/location-management/partials/shelf-up.component.html",
    controller: ShelfUpController,
    controllerAs: "vm",
    bindings: {},
};
export {ShelfUpComponent, ShelfUpController}
