class BottomQuickCreateStockController{
  constructor(API, $scope, $rootScope, $state, $mdBottomSheet, $auth, $location, ToastService, $element) {
    "ngInject";
    this.$scope = $scope;
    this.$mdBottomSheet = $mdBottomSheet;
    this.$auth = $auth
    this.ToastService = ToastService;
    this.API = API
    this.$state = $state
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.$element = $element;

    this.ts_product_code = null;
    this.ts_product_name = null;
    this.ts_supplier_product_code = null;
    this.ts_barcode = null;
    this.stock_unit_id = 1;
    this.vat = 0;
    this.currency_id = 1; // şirketin defaultuna göre olmalı
    this.is_active = 1;
    this.is_new_product = 1;
  }
  $onInit(){
    this.API.all("stockUnit/getList")
      .post()
      .then((response) => {
        this.stockUnit = response.data.stockUnit;
      });

    angular.element(document).ready(() => {
      // Belirli bir input elemanına odaklanmak için bir fonksiyon
      let parent = this;
      this.$scope.focusOnInput = function() {
        var inputElement = parent.$element.find('#search_box');
        if (inputElement.length > 0) {
          inputElement[0].focus();
        }
      };

      // Bottom sheet kapatıldığında çağrılacak fonksiyon
      this.$scope.$on('$mdBottomSheet.hide', function() {
        // Bottom sheet kapatıldığında yapılacak işlemler buraya gelebilir
        // ...
      });

      // Sayfa yüklendiğinde belirli bir input elemanına otomatik odaklan
      this.$scope.focusOnInput();
    });
  }

  createStock() {
    var parent = this;

    let reqObject = {
      ts_product_code: this.ts_product_code,
      ts_product_name: this.ts_product_name,
      ts_supplier_product_code: this.ts_supplier_product_code,
      ts_barcode: this.ts_barcode,
      stock_unit_id: this.stock_unit_id,
      is_active: this.is_active,
      is_new_product: this.is_new_product,
      currency_id: this.currency_id,
      vat: this.vat,
      package_products: [],
      quick:true
      //variations: this.variotionsProduct
    };


    this.API.all("stock/save")
      .post(reqObject)
      .then((response) => {
        if (response.errors === false) {
          parent.ToastService.success("Stok kartı oluşturuldu.");
          this.clearCreateForm();
          this.closeQuickView(response)
        }
      });
  }

  clearCreateForm() {
    //todo: doldur
  }

  closeQuickView(response){
    this.$mdBottomSheet.hide(response);
  }

  openMenu($mdMenu, ev) {
    this.originatorEv = ev;
    $mdMenu.open(ev);
  };


  go(tab){
    this.$mdBottomSheet.hide();
    this.options = {tab: parseInt(tab), product_id: this.product_id, editProducts: true }
    if(tab === 2){
      this.options.product_code = this.selectedProduct.ts_product_code;
    }
    if(tab === 1){
      this.options.term = this.term;
    }

    if (this.$state.current.name === 'app.stock_management') {
      this.options.tab = 3
      this.$location.search(this.options);
    } else {
      this.$state.go('app.stock_management',this.options);
    }
  }

}

BottomQuickCreateStockController.$inject = ["API", "$scope", "$rootScope", "$state", "$mdBottomSheet", "$location", "$auth", "ToastService", "$element"];

const BottomQuickCreateStockComponent = {
  templateUrl:
    "./views/angular/app/components/bottom-quick-create-stock/bottom-quick-create-stock.component.html",
  controller: BottomQuickCreateStockController,
  controllerAs: "vm",
  bindings: {},
};
export {BottomQuickCreateStockComponent, BottomQuickCreateStockController}
