import "./invoices.scss";

class InvoicesController{
    constructor(API, ToastService, uiGridConstants, $timeout, i18nService, $auth, $state, $stateParams, $log){
        'ngInject';

        this.API = API;
        this.ToastService = ToastService;
        this.uiGridConstants = uiGridConstants;
        this.$timeout = $timeout;
        this.i18nService = i18nService;
        this.state = $state;
        this.$auth = $auth;
        this.$stateParams = $stateParams;
        this.$log = $log;
        this.missingProduct = [];

    }

    $onInit(){
        this.sipNo = this.$stateParams.sipNo;
        this.activeOrderlineControl = false; // false
        this.balancedProducts = [];
        this.product_is_stock = [];
        this.cb1 = [];
        this.getCustomerData(this.sipNo);


    }

    getCustomerData (sipNo) {
        this.API.all('order/detail').post({
                                sip_no: sipNo
                            }).then((response) => {
                                response = response.data;
                                this.products = response.orderDetail.products;
                                this.cb1 = response.orderDetail.balanced;
                                this.orderHistory = response.orderHistory;
                                this.prepared_at = response.orderDetail.prepared_at;
                                this.closed_at = response.orderDetail.closed_at;
                                this.ts_payment_info = response.orderDetail.ts_payment_info;
                                this.product_is_stock = response.orderDetail.productIsStock;

                                if(response.orderDetail.prepared_at!==null && response.orderDetail.closed_at===null) {
                                    this.activeOrderlineControl = true;
                                } else {
                                    this.activeOrderlineControl = true; // false
                                }

                            });

        this.API.all('order/getBalancedProducts').post({
                                sip_no: sipNo
                            }).then((response) => {
                                if(response!==undefined) {
                                    this.balancedProducts = response.balancedProducts;
                                }

                            });
    }

    saveBalanceBulk() {
        let balanceData = {
            qty: this.qty,
            sip_no: this.sipNo,
            balanced: this.cb1
        };


        this.API
        .all('order/saveBalanceBulk')
        .post(balanceData).then((response) => {

            if(response.error) {
                this.ToastService.success(response.msg);
            } else {
                this.ToastService.success('Eksik ürün bilgisi kayıt edildi.');
            }

            this.balancedProducts = response.balancedProducts;

        });

    }

    saveBalance(status, productId, qty, orderLineId=null) {

        let balanceData = {
            productId: productId,
            sip_no: this.sipNo,
            status : status,
            qty:qty,
            order_line_id: orderLineId
        };

        this.API
        .all('order/saveBalance')
        .post(balanceData).then((response) => {
            this.ToastService.success(response.msg);
            this.cb1 = response.balanced;
            this.product_is_stock = response.is_stock_status;

            this.balancedProducts = response.balancedProducts;

        });

    }

    saveStockControl(status, productId) {

        let reqData = {
            productId: productId,
            sip_no: this.sipNo,
            isStockStatus : status
        };

        this.API
        .all('order/saveStockControl')
        .post(reqData).then((response) => {
            this.cb1 = response.balanced;
            this.product_is_stock = response.is_stock_status;

            this.ToastService.success(response.msg);


        });
    }

    startPrepareOrder() {
        this.activeOrderlineControlFunc();

        let orderData = {
            sip_no: this.sipNo
        };

        this.API
            .all('order/startPrepare')
            .post(orderData).then((response) => {


                this.ToastService.success(response.msg);
                this.prepared_at = response.prepared_at;



            });

    }

    finishPrepareOrder() {
        this.activeOrderlineControl = true; //false

        let orderData = {
            sip_no: this.sipNo
        };

        this.API
            .all('order/closePrepare')
            .post(orderData).then((response) => {

                this.closed_at = response.closed_at;
                this.ToastService.success(response.msg);




            });

    }



    activeOrderlineControlFunc() {
        this.activeOrderlineControl = true;
    }
}
InvoicesController.$inject = ["API", "ToastService", "uiGridConstants", "$timeout", "i18nService", "$auth", "$state", "$stateParams", "$log"];
export const BalanceComponent = {
    templateUrl: './views/angular/app/components/invoices/partials/balance.component.html',
    controller: InvoicesController,
    controllerAs: 'vm',
    bindings: {}
}
