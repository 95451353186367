import "./orders-prepare.scss";
import {isEmpty} from "lodash";
import {getUserInfo,getUserSetting} from "../../../utils/users";

class OrdersQualityControlPrepareController {
    constructor(
        API,
        DialogService,
        ToastService,
        $window,
        $mdBottomSheet
    ) {
        "ngInject";
        this.API = API;
        this.DialogService = DialogService;
        this.ToastService = ToastService;
        this.window = $window;
        this.actionPrepareListSection = 'search';
        this.actionOverLists = [];
        this.maxAction = 0;
        this.maxProduct = 0;
        this.activeProduct = {};
        this.activeProductIndex = 0;
        this.beepSound = new Audio('./files/success.wav');
        this.boxShowingSettings = getUserSetting('actionProcessesBoxShowing');
        this.boxShowing = null;
        this.$mdBottomSheet = $mdBottomSheet;


        this.priority = {
            1: 'normal',
            2: 'high',
            3: 'very-high',
            4: 'urgent',
        }
    }

    $onInit() {
        this.readBarcode()
    }


    readBarcode(){
        return 1;

        let parent = this;
        let barcode = '';
        let interval;
        this.terminalEvent = (e) => {
            if (interval) {
                clearInterval(interval)
            }
            if (e.key == 'Enter' && barcode) {
                parent.checkBarcode(barcode)
                barcode = '';
                return true;
            }
            if ('Shift' !== e.key) {
                barcode += e.key
            }
            interval = setInterval(() => barcode = '', 20)

        }
        this.window.document.addEventListener('keydown',this.terminalEvent  ,true)
    }

    actionDetail() {
        if (undefined !== this.productBarcode) {
            let orderNotes = [];
            this.API.all('action-process/qualityControl/' + this.productBarcode)
              .customGET()
              .then((response) => {
                  this.productBarcode = null
                  this.actionPrepareListSection = 'prepare';
                  this.maxAction = response.data.list.length;
                  this.actionPrepareList = response.data.list;
                  this.actionPrepareList.map((product, lIndex) => {
                      if (product.actionNote){
                          orderNotes[lIndex] = product;
                      }
                      if (isEmpty(this.activeProduct) && product.remainigQualityControlQuantity > 0) {
                          this.activeProductIndex = lIndex
                          this.activeProduct = product
                          product.active = true
                      }
                  })
                  if (orderNotes.length > 0){
                      let options = {
                          controller: 'OrdersPrepareController',
                          controllerAs: 'vm',
                          locals: {
                              notes : orderNotes,
                          },
                          bindToController: true,
                      }
                      var msgModal = this.DialogService.fromTemplateV2(
                          './views/angular/app/components/orders-prepare/partials/dialogs/action-message-dialog.html',
                          options
                      );
                      msgModal.catch((response)=>{
                      })
                  }

                  this.boxShowing = this.boxShowingSettings[this.activeProduct.locationId]
              }).catch(()=>{
                this.productBarcode = null
                this.focusInput()
            });
        }
    }

    listCheck(){
        let sum = 0
        let length = this.actionPrepareList.length
        this.actionPrepareList.map((product,pIndex) => {
            if(product.remainigQualityControlQuantity === 0){
                sum += 1
            }
        })
        if(length === sum){
            this.back()
        }
    }
    nextProduct(){
        this.activeProductIndex = (this.activeProductIndex + 1) % this.actionPrepareList.length
        if (this.activeProductIndex === 0) {
            this.activeProductIndex = (this.activeProductIndex + 1) % this.maxAction
        }
        this.activeProduct = this.actionPrepareList[this.activeProductIndex]
        if(this.activeProduct.remainigQualityControlQuantity === 0){
            this.nextProduct()
        }
    }
    prevProduct(){
        if (this.activeProductIndex === 0) {
            this.activeProductIndex = this.actionPrepareList.length-1;
        } else {
            this.activeProductIndex--;
        }
        this.activeProduct = this.actionPrepareList[this.activeProductIndex]
        if(this.activeProduct.remainigQualityControlQuantity === 0){
            this.prevProduct()
        }
    }
    targetProduct(productIndex){
        if(this.actionPrepareList[productIndex].remainigQualityControlQuantity > 0){
            this.activeProductIndex = productIndex;
            this.activeProduct = this.actionPrepareList[productIndex]
        }
    }

    preparePackageMultiple(){
        var options = {
            controller: "PreparePackageMultipleController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                productBarcode: this.productBarcode,
                remainingQuantity: this.activeProduct.remainigQualityControlQuantity
            }
        }
        this.DialogService.fromTemplateV2("./views/angular/app/components/orders-prepare/partials/prepare-package-multiple.html",options)
            .then((response) => {
                if(response.productQuantity){
                    this.productQuantity = response.productQuantity
                    this.checkQualityListItem();
                }
                if(response.message){
                    this.ToastService.error(response.message)
                }
            })
    }

    checkBarcode(barcode){
        if(isEmpty(this.actionPrepareList)){
            this.actionDetail()
            return;
        }else{
            let quantityBox = this.boxShowing?.quantityBox

            let checked = false;
            if(this.productBarcode === this.activeProduct.barcode || barcode === this.activeProduct.barcode){
                if(this.activeProduct.remainigQualityControlQuantity > 1 && quantityBox) {
                    this.preparePackageMultiple()
                }else{
                    this.productQuantity = 1;
                    this.checkQualityListItem()
                }
                checked = true;
            }else{
                var parent = this;
                this.actionPrepareList.map((product,pIndex) =>{
                    if(parent.productBarcode === product.barcode){
                        this.targetProduct(pIndex)
                        checked = true;
                    }
                })
                if(!checked){
                    this.ToastService.error("Doğru Barkodu Okuttuğunuzdan Emin Olun")
                    return;
                }
                this.checkBarcode(barcode)
            }
        }

    }

    checkQualityListItem(){
        let completeStatisticBox = this.boxShowing?.completeStatisticBox

        let data = {
            barcode: this.productBarcode,
            quantity: this.productQuantity,
            actionId: this.activeProduct.actionId,
        }
        this.API.all('action-process/qualityControl/' + this.activeProduct.listId)
        .customPOST(data)
        .then((response)=> {
            this.beepSound.play()
            if (response.data.list?.process === 'complete'){
                let report = response.data.list;

                if (report?.document){
                    this.printTemplate(report.document)
                }

                if (!completeStatisticBox){
                    this.back()
                    return  true;
                }

                var options = {
                    controllerAs      : 'vm',
                    bindToController  : true,
                    onShowing         : function (scope, element, options, controller){
                        scope.reportName          = 'Kalite Kontrol';
                        scope.report              = report;
                        scope.collapsed           = false;
                    },
                };

                let OPDialogPromise = this.DialogService.fromTemplateV2(
                    './views/angular/app/components/orders-prepare/partials/dialogs/prepare-report.html',
                    options
                ).then((status) => {
                    this.back()
                }).catch(()=>{
                    this.back()
                })
                return false
            }

            if(response.response.status){
                this.productBarcode = null;
                this.actionPrepareList[this.activeProductIndex].remainigQualityControlQuantity -= this.productQuantity
                this.actionPrepareList[this.activeProductIndex].prepareQualityControlQuantity += this.productQuantity
                if(response.data.list.message){
                    this.ToastService.success(response.data.list.message)
                }
                if(this.activeProduct.remainigQualityControlQuantity === 0){
                    this.listCheck()
                    this.nextProduct()
                }
            }
        })
    }

    back(){
        this.actionPrepareList = "";
        this.activeProduct = "";
        this.actionPrepareListSection = "search";
        this.productBarcode = null;
    }

    printTemplate(document){
        const printWindow = window.open('', '_blank');
        printWindow.document.write(document);
        printWindow.document.close();
        printWindow.onload = function() {
            printWindow.print();
            printWindow.close();
        };
    }

    focusInput(id){
        document.getElementById(id)?.focus();

    }
    showCameraView() {
        var parent = this;
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
            controller: "BottomCameraController",
            controllerAs: "vm",
            bindToController: true,
            locals: {}
        }).then(response => {
            parent.productBarcode = response;
        })
            .catch(function (error) {
                console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
            });
    }
}

OrdersQualityControlPrepareController.$inject = ['API', "DialogService", "ToastService", "$window", "$mdBottomSheet"]
const OrdersQualityControlPrepareComponent = {
    templateUrl: "./views/angular/app/components/orders-prepare/partials/orders-quality-control-process.component.html",
    controller: OrdersQualityControlPrepareController,
    controllerAs: "vm",
    bindings: {},
};
export {OrdersQualityControlPrepareComponent, OrdersQualityControlPrepareController}
