import { getUserInfo,me } from "../../../utils/users";
class ActionProductSummaryController {
  constructor(
    API,
    ToastService,
    i18nService,
    $auth,
    $timeout,
    $state,
    DialogService,
    UserService,
    $stateParams,
    $mdMenu,
    $rootScope,
    $filter,
    $q,
    $location,
    $scope,
    $window,
    $mdBottomSheet,
    TabActionService
  ) {
    "ngInject";
    this.UserService = UserService;
    this.API = API;
    this.$auth = $auth;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.TabActionService = TabActionService;
    this.$scope = $scope;
    this.window = $window;
    this.$mdBottomSheet = $mdBottomSheet;
    this.ToastService = ToastService;
    this.DialogService = DialogService;
    this.$mdMenu = $mdMenu;
    this.$state = $state;
    this.$stateParams = $stateParams;

    this.page               = 1;
    this.limit              = 20;
    this.total              = 0;
    this.pageList           = [];

    this.userInfo = getUserInfo();
    this.currentUserId = this.userInfo.uid;

    this.created_at_end = new Date()
    this.created_at_start = new Date()
  }
  $onInit() {
    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
    this.API.all("category/getChildCategories")
        .post()
        .then((response) => {
          this.categories = response.data.categories;
        });
    this.API.all("brand/getList")
        .post()
        .then((response) => {
          this.brands = response.data.brandArray;
        });
    this.getFilterInventory()
    this.create_brand_id = [];
    this.create_selected_categories = [];
    this.search_selected_categories = [];
    this.dropdownCategoriesSettings = {enableSearch: true, scrollableHeight: '400px', scrollable: true};
    this.dropdownCategoriesTranslationsText = {
      buttonDefaultText: "Kategori seçiniz...",
      checkAll: "Tümünü Seç",
      uncheckAll: "Seçimleri Kaldır",
      searchPlaceholder: "Ara",
      dynamicButtonTextSuffix: " kategori seçildi.",
    };
    this.dropdownBrandsTranslationsText = {
      buttonDefaultText: "Marka arayınız...",
      searchPlaceholder: "Marka Ara",
      uncheckAll: "Seçimleri Kaldır",
      dynamicButtonTextSuffix: " marka seçildi.",
    };
    this.dropdownBrandsSettings = {
      enableSearch: true, selectionLimit: 1, scrollableHeight: '400px', scrollable: true,
      smartButtonMaxItems: 1,
      smartButtonTextConverter: function (itemText, originalItem) {
        return itemText;
      }
    };
  }

  loadBrands(query = "") {
    var self_ = this;

    this.API.all("brand/get")
        .post({
          query: query,
        })
        .then((response) => {
          self_.brands = response.data.brands;
        });
    return this.brands;
  }
  getFilterInventory() {

    this.API.all("action/inventory")
        .customGET(null, {filter: 1})
        .then((response) => {
          this.actionFilterInventory = response.data.list
        });
  }
  getActionProductSummary(page){

    this.brandIdStr = "";
    this.categoryIdStr = "";

    if (this.searchBrand !== false) {
      var self_ = this;
      angular.forEach(this.searchBrand, function (brandValue, brandKey) {
        self_.brandIdStr += "," + brandValue.id;
      });
    }

    if (this.search_selected_categories !== false) {
      var self_ = this;
      angular.forEach(
          this.search_selected_categories,
          function (categoryValue, categoryKey) {
            self_.categoryIdStr += "," + categoryValue.id;
          }
      );
    }
    const customGet = {
      searchBrandIdStr  : this.brandIdStr,
      categoryIdStr     : this.categoryIdStr,
      created_at_start  : this.created_at_start,
      created_at_end    : this.created_at_end,
      marketplace       : this.filterMarketplace && this.filterMarketplace.join('|'),
      limit             : 20,
      page              : page,
    };

    this.API.all("action/product-summary")
      .customGET(null, customGet)
        .then((response) => {
            this.list = response.data.list;
            this.productList = this.list.productList
            this.page = this.list.page;
            this.limit = this.list.limit
            this.total = this.list.total
            this.startIndex = (this.page - 1) * this.limit;
            this.pageList = [];
            let max = Math.ceil(this.list.total / this.list.limit)
            max = max === 0 ? 1 : max;
            for (let p = 1; p <= max; ++p) {
                this.pageList.push(p)
            }
        });
  }
    excelOrCsvExport(fileType){
    this.brandIdStr = "";
    this.categoryIdStr = "";

    if (this.searchBrand !== false) {
      var self_ = this;
      angular.forEach(this.searchBrand, function (brandValue, brandKey) {
        self_.brandIdStr += "," + brandValue.id;
      });
    }

    if (this.search_selected_categories !== false) {
      var self_ = this;
      angular.forEach(
          this.search_selected_categories,
          function (categoryValue, categoryKey) {
            self_.categoryIdStr += "," + categoryValue.id;
          }
      );
    }
    const customGet = {
      searchBrandIdStr  : this.brandIdStr,
      categoryIdStr     : this.categoryIdStr,
      created_at_start  : this.created_at_start,
      created_at_end    : this.created_at_end,
      marketplace       : this.filterMarketplace && this.filterMarketplace.join('|'),
      limit             : 20,
      excelOrCsvExport  : fileType,
    };
    this.API.all("action/product-summary")
        .customGET(null, customGet)
        .then((response) => {
            if (response.response.status === 422){
                this.ToastService.error(response.data.errors.message[0]);
                return;
            }
            if (fileType === 'excel'){
                window.location.assign(window.__env.apiUrl+"/api/stock/exportExcel/"+response.data.fileName+"?token="+window.localStorage.satellizer_token);
            }
            if (fileType === 'csv'){
                window.location.assign(window.__env.apiUrl+"/api/stock/exportCsv/"+response.data.fileName+"?token="+window.localStorage.satellizer_token);
            }

          this.list = response.data.list;
          this.productList = this.list.productList
          this.page = this.list.page;
          this.limit = this.list.limit
          this.total = this.list.total
          this.ToastService.success(this.$translate.instant("DOWNLOADING"));
          this.pageList = [];
          let max = Math.ceil(this.list.total / this.list.limit)
          max = max ===0 ? 1 : max;
          for (let p=1; p <=max;++p){
            this.pageList.push(p)
          }
        });
  }

  selectAllOptionsByModel(model) {
    if (model === 'filterMarketplace'){
      this.filterMarketplace = this.actionFilterInventory.marketplaces.data.map(d=>d)
    }
  }
  deselectAllOptionsByModel(model) {
    this[model] = [];
  }

  clearForm() {
    this.filterMarketplace          = [];
    this.search_selected_categories = [];
    this.searchBrand                = [];
  }
    showProductBottomSheet(productId) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                id: productId
            }
        })
    }

}
ActionProductSummaryController.$inject = ["API", "ToastService", "i18nService", "$auth", "$timeout", "$state", "DialogService", "UserService", "$stateParams", "$mdMenu", "$rootScope", "$filter", "$q", "$location","$scope","$window","$mdBottomSheet","TabActionService"];
const ActionProductSummaryComponent = {
  templateUrl: "./views/angular/app/components/action-product-summary/action-product-summary.component.html",
  controller: ActionProductSummaryController,
  controllerAs: "vm",
  bindings: {},
};
export {ActionProductSummaryComponent,ActionProductSummaryController}
