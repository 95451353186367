import "./about.scss";

class AboutController {
  constructor(
    $auth, ToastService, API, $window, $state, $location, $sce) {
    'ngInject';

    this.$auth = $auth;
    this.$window = $window;
    this.ToastService = ToastService;
    this.API = API;
    this.users = {};
    this.$state = $state;
    this.$location = $location;
    this.$sce = $sce;
    this.video = undefined;
    this.appVersion = $window.__env.appVersion;

    this.supportIssues;
    this.supportReleases;
    this.showSupportBodyNumber = 0;
  }

  $onInit() {
    this.per_page = 10;
    this.filterArr = {};
    this.per_page = 10;
    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
    this.satellizer_token = this.$window.localStorage.satellizer_token;
    if (this.satellizer_token) {
      this.userInfo = this.parseJwt(this.satellizer_token);
    }

    this.support = {
      subject: '',
      description: '',
      type: '',
      phone: '',
      email: this.userInfo.email,
    }
  }

  onSelectTab(obj) {
    this.$location.search(obj);
    this.video = undefined;
    this.customerSupportIssues();
    this.supporReleases();
  }

  clearForm() {
    this.support.subject = null;
    this.support.description = null;
    this.support.type = null;
    this.support.phone = null;
  }

  supportFormSend() {
    this.API.all('support/form')
      .post(this.support)
      .then(() => {
        this.ToastService.success('Mesajınız başarıyla gönderildi.');
        this.clearForm();
      })
      .catch((response) => {
        this.ToastService.error(response.data.errors.message[0]);
      });
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  customerSupportIssues(){
    let parent = this;
    this.API.all("support")
      .customGET("customer-support-issues", {})
      .then((response) => {
        console.log(response.data);
        parent.supportIssues = response.data;
      });
  }

  supporReleases(){
    let parent = this;
    this.API.all("support")
      .customGET("releases", {})
      .then((response) => {
        console.log(response.data);
        parent.supportReleases = response.data;
      });
  }

}

AboutController.$inject = ["$auth", "ToastService", "API", "$window", "$state", "$location","$sce"];

export const AboutComponent = {
  templateUrl: './views/angular/app/components/about/about.component.html',
  controller: AboutController,
  controllerAs: 'vm',
  bindings: {}
};
