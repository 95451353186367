class CloneStockController{
  constructor($mdBottomSheet, API, ToastService) {
    "ngInject";

    this.$mdBottomSheet = $mdBottomSheet
    this.API = API;
    this.ToastService = ToastService;

    this.source_product_id = null;
    this.ts_product_code = null;
    this.ts_product_name = null;
    this.ts_supplier_product_code = null;
    this.ts_barcode = null;
  }

  $onInit(){}

  clone(){
    var parent = this;
    let reqObject = {
      source_product_id: this.source_product_id,
      ts_product_code: this.ts_product_code,
      ts_product_name: this.ts_product_name,
      ts_supplier_product_code: this.ts_supplier_product_code,
      ts_barcode: this.ts_barcode,
    };


    this.API.all("stock/clone")
      .post(reqObject)
      .then((response) => {
        if (response.errors === false) {
          parent.ToastService.success("Stok kartı kopyalanarak yeni bir kart oluşturuldu.");
          this.$mdBottomSheet.hide({});
        }
      })
      .catch((response) => {
        parent.ToastService.error(response.data.errors.message[0]);
      });

  }
  cancel(){
    this.$mdBottomSheet.cancel({close : true});
  }
}
CloneStockController.$inject = ["$mdBottomSheet", "API", "ToastService"];
const CloneStockComponent = {
  templateUrl:
    "./views/angular/app/components/clone-stock/clone-stock.component.html",
  controller: CloneStockController,
  controllerAs: "vm",
  bindings: {},
};
export {CloneStockComponent, CloneStockController}
