import {
  closeIntroductionVideo,
  dontShowIntroductionVideo,
} from "../../../utils/introduction-videos";
import {me} from "../../../utils/users";

import "./sidebar.scss";

class SidebarController {
  constructor(
    $auth,
    ToastService,
    $state,
    $mdSidenav,
    UserService,
    $http,
    $q,
    $rootScope,
    $stateParams,
    API,
    $location,
    $scope,
    $translate,
    $mdTheming,
    $mdBottomSheet
  ) {
    "ngInject";

    this.$auth = $auth;
    this.ToastService = ToastService;
    this.$state = $state;
    this.$mdSidenav = $mdSidenav;
    this.UserService = UserService;
    this.userName = "";
    this.getUserName = me();
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.API = API;
    this.activeRoute = "";
    this.$location = $location;
    this.$scope = $scope;
    this.$translate = $translate;
    this.$mdTheming = $mdTheming;
    this.branch = {};
    this.$mdBottomSheet = $mdBottomSheet;

    this.openRightButton = true;

    var data = {};

    this.your_logo_base64 = 'iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAADEklEQVR4nO2aO2hUQRSGv2jiM0YQUYskhYqKIChGC1+dFj6wEEEFUYIWFj4QrAwWIhos1RSKWKSK+EDQQtQi4hOEoLDxEQUxQjAGtFBEsjFjMXPZs3fv3Z3JbshA5oPLnjnn3Mmfe+6cuXfZKqUUAX+YMNYCAvmEgnhGKIhnhIJ4RiiIZ4SCeEYoiGeEgnhGUkFqgDeAMsfxhHi3iB8eTYEjoJWctpYx1uJMUkGywAHgnxmfBhpE/ASw1NgvgbZRUzcOSWtZr4ALxp4OXDT2fHJ33SC6cMOjpm4cUmwPaQE+G3u7OdqAqcZ3Dt26AFYCN4Bv6BXWD9wyfkknuXYyM8VfK/wdwr8YOA8MAO9K/2tW2OoG2A/0AH+ADHAGeCT01Zcxdw6lVLFjo8rxS9jdSqlJJmeLUmpQJTNo4tF8nSJWl+KvFf4O4b8p7PdFNLeKvJYieS66m1PyJPUjnDvvKPWU9RBoN3Z05w4DB9Etqxq4gt7oFbAHmAHsNuMa4LLJi+Pa6pYBq81cSxzPjeOiuxo4K87dC9QBRyswdyFF7qDomKWU6hcVviRiq4S/M3beYxFrUsVXgs0K2WGh1XaFuOheLsbPYrk9IhatENdr4rRCAH6gN/mI+8KeI+yvsfN6U/KSqLHQ8dYixxYX3bPF+EssNz52nbuA5GVjz4CwG2KxRmF/N59Z4ZsG/AYmAgss/la2dIo1LrqHxDi+cTdSiOs1yaPcN/UuoM/YG9B9shbYBaw3/j7gtbHlHbIP3YtPAXPL1OGKi+4M+gkJYB2wE70nHAEWlTl3IZZ9+Z7ofVtjsW1KqaxKJmviUe6mlDxJ2h6y0FKr3EOSeDoC3a5PWS5zO+8hpbgLrAFuo5frkPm8A6w18YgHwCHgI7nn+ZPA8wrocMVF9zWgGfgE/AU+oN/DXogc2VJd5s6jSoUfOdiwAjiGfjHOAFOAzcBVYDL6Ys+jAt9ahILY0UT+k6ZEod/i21PiToSv3+3oQm/OT4Cf6PbUC1xHb9QVKQaEFeIdYYV4RiiIZ4SCeEYoiGeEgnjGf0AFuTwyxZN6AAAAAElFTkSuQmCC';

    if (this.$stateParams.term) {
      this.inputSearchTerm = this.$stateParams.term;
    }
    //this.UserService.setUserPermbyHttpReq();
    $scope.changeTheme = function(){
      $scope.theme = $scope.theme == 'dark' ? 'default' : 'dark';
    }

    this.API.all('settings/branch?defaultBranch=1').
    doGET()
        .then(response =>{
          this.branch = response.data.list
        })

    this.API.all("ui/get")
      .post()
      .then((response) => {
        this.customerInformation = response.data.customerInformation;
        this.setLeftMenuActivity();
      });
  }

  setLang(langKey) {
    this.$translate.use(langKey);
  };

  $onInit() {
    if (!this.getUserName || this.$auth.getToken() == null){
      this.logout()
    }

    var self_ = this;
    self_.userName = self_.getUserName.user.name;

    this.email = "";
    this.password = "";
    this.setLang(self_.getUserName.user.settings.language);
  }

  $onChanges(obj) {
    if (!this.getUserName || this.$auth.getToken() == null){
      this.logout()
    }

    var self_ = this;
    self_.userName = self_.getUserName.user.name;

    this.email = "";
    this.password = "";
  }

  go(Page) {
    this.$state.go(Page);
  }

  close(sideNavName = "left") {
    this.$mdSidenav(sideNavName).close();
  }

  open(sideNavName = "left") {
    this.$mdSidenav(sideNavName).open();
  }

  closeRight() {
    this.$mdSidenav("right").close();
    alert("uieauiea");
    //this.openRightButton = true;
  }

  openRight(){
    this.$mdSidenav("right").open();
    //this.openRightButton = false;
  }

  logout() {
    localStorage.removeItem('polypus-me')
    localStorage.removeItem('notificationsUnviewed')
    localStorage.removeItem('basketProducts')
    localStorage.removeItem('notificationsSettings')
    localStorage.removeItem('notifications')

    this.$auth.logout();
    this.$state.go("app.login");
  }

  searchTerm(isShowCamera) {
    if (!this.inputSearchTerm){
      //this.ToastService.error('Arama Kriteri girlmeli')
      //return;
    }
    // nerde olursa olsun quick view açılmalı
    if (this.$state.is("app.stock_management") && false) {
      //todo:aynı state için farklı bişey gerekiyor, reload:true yerine
      this.$state.go("app.stock_management", {tab: 0, term: this.inputSearchTerm}, {reload:true});
    } else {
      // this.$state.go("app.stock_management", {tab: 1, term: this.inputSearchTerm});
      this.$mdBottomSheet.show({
        templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
        controller: "BottomViewController",
        controllerAs: "vm",
        bindToController: true,
        locals: {
          term: this.inputSearchTerm,
          isShowCamera: isShowCamera
        }
      })
    }
    this.inputSearchTerm = '';
  }

  setLeftMenuActivity() {
    this.activeRoute = window.location.href.split("#!/")[1];
  }

  closeIntroductionVideoModal() {
    closeIntroductionVideo();
  }

  dontShowIntroductionVideoModal() {
    dontShowIntroductionVideo();
  }
}
SidebarController.$inject = ["$auth", "ToastService", "$state", "$mdSidenav", "UserService", "$http", "$q", "$rootScope", "$stateParams", "API", "$location", "$scope", "$translate", "$mdTheming", "$mdBottomSheet"];

export const SidebarComponent = {
  templateUrl: "./views/angular/app/components/sidebar/sidebar.component.html",
  controller: SidebarController,
  controllerAs: "vm",
  bindings: {},
};
