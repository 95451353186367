require("./app-root.scss");
import {getUserInfo, getToken,me} from "../../../utils/users";

class AppRootController {
  constructor($mdToast, ToastService, PstorageService, $mdSidenav, $window, $state, UserService, $auth, $http, API, $rootScope, $stateParams, $location) {
    'ngInject';

    this.$window = $window;
    this.$mdToast = $mdToast;
    this.ToastService = ToastService;
    this.PstorageService = PstorageService;
    this.$mdSidenav = $mdSidenav;
    this.$state = $state;
    this.$auth = $auth;
    this.UserService = UserService;
    this.$http = $http;
    this.API = API;
    this.$rootScope = $rootScope;
    this.env = window.__env;
    this.$location = $location;
    this.envDevelopment = false;
    this.satellizer_token = getToken();
    this.appVersion = this.env.appVersion;

    if (this.satellizer_token) {
      this.userInfo = getUserInfo();

      this.Echo = new this.$window.Echo({
        broadcaster: this.$window.__env.echoBroadcaster,
        key: this.$window.__env.echoPusherAppKey,
        cluster: 'eu',
        forceTLS: true,
        disableStats: true,
        authEndpoint: this.$window.__env.apiUrl + "/broadcasting/auth",
        //Pusher.logToConsole = this.$window.__env.enableLogging;
        host: this.$window.__env.echoHost,
        transports: ["websocket", "polling"],
        auth: {
          headers: {
            Authorization: 'Bearer ' + this.satellizer_token,
            'X-Auth-Host': this.$window.__env.broadcastAuthUrl
          }
        }
      });
    }
  }

  $onInit() {
    this.registerServiceWorker();
    this.checkForNewerVersions();
    this.openSideBar = true;

    if (this.satellizer_token) {
      this.getUserInfo = me();
      let userId = this.userInfo.uid;
      let companyName = this.userInfo.companyName;

      this.PstorageService.$onInit();

      this.Echo.channel('App.Public')
        .listen('.PublicMessageEvent', (data) => {
          if (this.$state.notificationsSettings.view.public) {
            this.ToastService.showHtml('success',data.message);
          }
          this.PstorageService.addNotification(data);
        });

      this.Echo.private(`App.${companyName}`)
        .listen('.PrivateMessageEvent', (data) => {
          if (this.$state.notificationsSettings.view.company) {
            this.ToastService.showHtml('success',data.message);
          }
          this.PstorageService.addNotification(data);
        });

      this.Echo.private(`App.${companyName}.${userId}`)
        .listen('.PrivateMessageEvent', (data) => {
          if (this.$state.notificationsSettings.view.personal) {
            this.ToastService.showHtml('success',data.message);
          }
          this.PstorageService.addNotification(data);
        });


      this.API.all("settings/currency")
        .customGET()
        .then((response) => {
          this.currencies = response.data.list;
        });
      /*
      this.Echo.channel('App.log')
          .listen('.LogMessageEvent',(data) => {
            console.log(data.message, '<<<< Log Message');
          });

       */
      var self_ = this;
      self_.$rootScope.userInfo = this.getUserInfo.user;
      self_.$rootScope.locations = this.getUserInfo.locations;
      if (this.getUserInfo.company['preferred-product-code'] == null) {
        this.getUserInfo.company['preferred-product-code'] = 'ts_product_code';
      }
      self_.$rootScope.companyInfo = this.getUserInfo.company;
      self_.paymentWarning = this.getUserInfo.paymentWarning;

    }

    if (this.$location.host() === "localhost" || this.$location.host() === 'test.polypus.app') {
      this.envDevelopment = true;
    }
  }

  registerServiceWorker() {
    if (!('serviceWorker' in navigator)) {
      return false;
    }
    navigator.serviceWorker.register('/service-worker.js')
      .then(this.handleRegistration.bind(this));
  }

  handleRegistration(registration) {
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (!navigator.serviceWorker.controller) {
            this.ToastService.success('Uygulama çevrimdışı kullanıma hazır.');
          }
        }
      }
    }
  }

  checkForNewerVersions() {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.onstatechange = (e) => {

        if (e.target.state === 'redundant') {
          let toast = this.$mdToast.simple()
            .content('Yeni bir versiyon çıktı. Tarayıcı geçmişinizi silmelisiniz.')
            .position(this.ToastService.position)
            .action('Yenile')
            .hideDelay(this.ToastService.delay);

          this.$mdToast.show(toast).then(() => {
            this.$window.location.reload();
          });
        }
      };
    }
  }

  go(Page) {
    this.$state.go(Page);
  }

  close() {
    this.$mdSidenav('left').close();
  }

  open() {
    this.$mdSidenav('left').open();
  }

  logout() {
    this.$auth.logout();
    this.$state.go('app.login');
    this.token = null;
  }
}
AppRootController.$inject = ["$mdToast", "ToastService", "PstorageService", "$mdSidenav", "$window", "$state", "UserService", "$auth", "$http", "API", "$rootScope", "$stateParams", "$location", "$scope"]

class RouteController {
  constructor($location, $state) {
    'ngInject';
    this.$location = $location;
    this.$state = $state;
  }

  $onInit() {
  }

  onSelectTab(obj) {
    this.$location.search(obj);
    this.video = undefined;
  }
}
RouteController.$inject = ["$location","$state"];

const AppRootComponent = {
  templateUrl: './views/angular/app/components/app-root/app-root.component.html',
  controller: AppRootController,
  controllerAs: 'vm',
  bindings: {}
}

export {AppRootController, RouteController, AppRootComponent}
