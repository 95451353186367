var MenuEn = {
  SETTINGS: "Settings",
  HELP: "Yardım",
  QUICK_HELP: "Quick Help",
  SUPPORT: "Support",
  DARK_MODE: "Gece Modu",
  MY_SHORTCUT: "My Shortcuts"
};

var accountSettingsEn = {
  PERSONAL_INFORMATION: "Personal Information",
  TURKISH: "Türkçe",
  ENGLISH: "English",
  CURRENT_PASSWORD: "Current Password",
  NEW_PASSWORD: "New Password",
  REPEAT_NEW_PASSWORD: "New Password Again",
  UPDATE: "Update",
  CHANGE_PASSWORD: "Change Password",
  LOCALIZATION_SETTINGS: "Localization Settings",
  SCAN_PRIORITY: "Scan Priority",
  PRINTER_SETTINGS: "Printer Settings",
  PRINTER_NAME: "Printer Name",
  PRINTER_CONNECTION_PROTOCOL: "Printer Connection Protocol",
  PRINTER_IP_ADDRESS: "Printer IP Address",
  PRINTER_PORT_ADDRESS: "Printer Port Address",
  PRINT_TEST_PAGE: "Print Test Page",
  FIRM_LOGO: "Company Logo",
  CONTROLLER: "Controller",
  QUALITY_CONTROL: "Quality Control",
  PACKER: "Packer",
  SHOW: "Show",
  HIDE: "Hide",
  CONFIRM_OFFER: "Confirm Offer",
  COMPLETE_OFFER: "Complete Offer", // Eklendi
  CLOSE_OFFER: "Close Offer", // Eklendi
  CANCEL_ORDER: "Cancel Order", // Eklendi
  CANCEL_OFFER: "Cancel Offer", // Eklendi
  CONFIRM_QUICK: "Confirm Quickly", // Eklendi
  CREATE_PERMISSION_GROUP: "Create Permission Group" // Eklendi
};

var cargoEn = {
  SEARCH_ORDER: "Search Order",
  UPDATE_ORDER_STATUS: "Update Order Status",
  ORDER_COUNT: "Order Count",
  CARGO_INFO1: "To update the order statuses on the selected channel, you must select at least one order.",
  ORDER_NU: "Order No",
  PREPARE_IN_CARGO: "Orders in Cargo", // Eklendi
  OFFER_NU: "Offer Nu",
  USERNAME: "Username",
  CUSTOMER_NAME: "Customer Name",
  ADD_NEW_CUSTOMER: "Add New Customer", // Eklendi
  CARGO_PROCESSES: "Cargo Processes",
  ACTIONS: "Actions",
  ACTION: "Action",
  REMOVE_THE_TABLE: "Remove From The Table",
  ERP_NUMBER: "ERP No",
  SHIP_IT: "Ship It",
  CREATED_DATE: "Created Date",
  AMOUNT: "Amount",
  CREATOR: "Creator",
  PRINT_CARGO_BARCODE: "Print Cargo Barcode",
  CREATE_CARGO_PDF: "Create Cargo PDF",
  SELECT: "Select",
  PRINT_CARGO_REPORT: "Print Cargo Record",
  SELECT_DATE_GIVE_CARGO_FIRM: "Shipping Date",
  CARGO_STATUS: "Cargo Status",
  CARGO_MESSAGE: "Cargo Message",
  CARGO_INFO: "No Cargo Info",
  ORDER_ID: "Order ID",
  SELECT_CARGO_FIRM: "Select Cargo Company",
  CARGO_FIRM: "Cargo Firm",
  CARGO_PRICE: "Cargo Price",
  SERVICE_PRICE: "Service Price",
  DELIVER: "Deliver", // Eklendi
  DELIVER_TO_SELECTED: "Deliver to Selected", // Eklendi
  NO_ORDER_IN_PACKAGE_PROCESS_TITLE: "No Orders in Packaging Process", // Eklendi
  NO_ORDER_IN_PACKAGE_PROCESS_BODY: "You can create a new order from the Stock Movement section", // Eklendi
  QUALITY_CONTROL_INFO_TITLE: "Quality Control", // Eklendi
  QUALITY_CONTROL_INFO_BODY: "Please enter the barcode of the order you want to check", // Eklendi
  INVOICES_INFO_TITLE: "Order Search Section", // Eklendi
  INVOICES_INFO_BODY: "You can continue your process with the Order No", // Eklendi
  CHANGE_SHELF_INFO_TITLE: "Change Shelf Section", // Eklendi
  CHANGE_SHELF_INFO_BODY: "You can continue by selecting which warehouse you will operate in", // Eklendi
  PRINT_UNIQUE_CARGO_BARCODE: "Print Unique Cargo Barcode",
  PRODUCT_ERP_CODE: "ERP Code",
  START_ADD_PRODUCT_WITH_CAMERA: "Add Product with Camera",
  SEARCH_PRODUCT_WITH_CAMERA: "Search Product with Camera",
  SEARCH_PRODUCT_WITH_BARCODE: "Search Product with Barcode",
  NOT_EXIST_CONTROL_OF_STEP: "No control of the step exists yet" // Eklendi
};

var customersEn = {
  CUSTOMER: "Customer",
  CREATE_CUSTOMER: "Create Customer",
  CUSTOMER_CODE: "Customer Code",
  CUSTOMER_NAME_OR_FIRM_LABEL: "Name Surname / Company Label",
  EMAIL: "E-mail",
  PHONE_NUMBER: "Phone",
  INVOICE_ADDRESS: "Invoice Address",
  ADDRESS_TYPE: "Address Type",
  PERSONAL: "Individual",
  CORPORATE: "Institutional",
  IDENTITY_NU: "Identity No",
  TAX_NU: "Tax No",
  NO_MISSING_PRODUCTS_TITLE: "No Missing Products",
  NO_MISSING_PRODUCTS_BODY: "Currently, there are no missing products. You can add products from the Add Product section.",
  TAX_DEP: "Tax Department",
  TRADE_REGISTRATION_ID: "Trade Registration ID",
  COMPANY_LABEL: "Company Label",
  NAME_SURNAME: "Name Surname",
  GSM_NU: "Mobile Phone",
  ADDRESS: "Address",
  CITY: "City",
  TOWN: "Town",
  NEIGHBOURHOOD: "Neighbourhood",
  ZIP_CODE: "Zip Code",
  SAME_ADDRESS_INFO: "My delivery address is the same as my billing address",
  SAVE: "Save",
  SAVE_RECOMMENDED_LOCATIONS: "Add Recommended Locations",
  DELIVERY_ADDRESS: "Delivery Address",
  CUSTOMERS: "Customers",
  CLEAR: "Clear",
  CLEAR_UPPERCASE: "CLEAR",
  SEARCH: "Search",
  CUSTOMER_NAME: "Customer Name",
  PRINT: "Print",
  ACTIONS: "Actions",
  CONTACT: "Contact",
  FOOTER_MESSAGE: "Footer Message",
  SHOW_MISSING_PRODUCTS: "Show Missing Products",
  EDIT: "Edit",
  DELETE: "Delete",
  CUSTOMER_INFO1: "Used for searching, adding, and editing customer accounts",
  CUSTOMER_INFO2: "Searches by customer code, username, customer name",
  CUSTOMER_EMPTY_SEARCH_MSG: "No customer information found for the search.",
  NO_RECORD_INFO: "There are no records.",
  RETURN_TRANSACTION: "Create Return",
  CANCEL_TRANSACTION: "Create Refund",
  MISSING_QUANTITY_REPORT: "Report Missing Quantity",
  SAME_QUANTITY: "Same Quantity",
  OFFER_INFO_TITLE: "Create Offer",
  OFFER_INFO_BODY: "You can create your offer with the information you enter above",
};

var dashboardIndexEn = {
  TOTAL_ORDERS: "Total Orders",
  TOTAL_ORDER_QTY: "Total Order Quantities",
  PREPARATION_STEP: "Preparation Step",
  CONTROL_STEP: "Control Step",
  CARGO_PROCESSES: "Cargo Processes",
  ORDER_ITEM_QUANTITY: "Item Quantity of Order",
  GENERAL_VIEW: "General View",
  STOCK_TURNOVER_RATE: "Stock Turnover",
  TURNOVER_RATE: "Turnover",
  MOST_ACTIVE_PRODUCTS: "The Most Moving Products",
  DATA_NOT_READY_YET: "Data for this day is not ready yet",
  NOT_ENOUGH_DATA_FOR_REPORT: "You don't have enough data for the report",
  THE_MOST_MOVING_PRODUCTS: "The Most Moving Products",
  REPORT_CREATE_TIME: "Report Generation Time",
  REPORT_BEING_PREPARED: "Your report is being prepared. It will be displayed here when finished",
  CLICK_GENERATE_REPORT_NOW: "Click to generate the report now",
  HIGHEST: "Highest",
  LOWEST: "Lowest",
  DAILY: "Daily",
  DAILY_REVENUE: "Daily Revenue",
  MONTH: "Month",
  WEEK: "Week",
  DAY: "Day",
  YEAR: "Year",
  NOW_YEAR: "This Year",
  ALL_TIME: "All Time",
  BRANCH_REVENUE: "Branch Revenue",
  DATE: "Date",
  BRANCH: "Branch",
  PAYMENT_TYPE: "Payment Type",
  PREPARED_PRODUCT_COUNT: "Count of Prepared Products",
  PREPARED_PRODUCT_QTY: "Quantity of Products Prepared",
  SERVICE: "Service",
  STATUS: "Status",
  ELASTIC_SEARCH_ENGINE: "Elastic Search Engine",
  ACTIVE: "Active",
  COMPLETE: "Completed",
  PASSIVE: "Passive",
  RECORD_COUNT: " number of records",
  WHOLESALES: "Wholesale",
  SELECT_MONTH: "Select Month",
  JAN: "January",
  FEB: "February",
  MARCH: "March",
  APR: "April",
  MAY: "May",
  JUN: "June",
  JULY: "July",
  AUGUST: "August",
  SEPTEMBER: "September",
  OCTOBER: "October",
  NOVEMBER: "November",
  DECEMBER: "December",
  TOTAL_AMOUNT: "Total Amount",
  SEARCH_ENGINE: "Search Engine",
};

var integrationPageEn = {
  SIPARISLER: "Orders",
  INTEGRATION_FILTER_1: "Start Date The Task Runs",
  SAAT_SECINIZ: "Select Hour",
  INTEGRATION_FILTER_2: "End Date The Task Runs",
  SELECT_CHANNEL: "Select Channel",
  ORDER_UPDATED_START_DATE_ENTER: "Update Start Date",
  ORDER_UPDATED_END_DATE_ENTER: "Update End Date",
  SELECT_ORDER_STATUS: "Select Order Status",
  SALES_CHANNEL: "Sales Channel",
  USER: "User",
  ORDER_START_DATE: "Starting Date",
  ORDER_END_DATE: "End Date",
  TRANSFERRED_ORDER_COUNT: "Count of Orders Transferred",
  ORDER_STATUSSES: "Order Statuses",
  WORKING_TIME: "Working Hours",
  WORKING_ELAPSED_TIME: "Operation Time",
  KILL_PROCESS: "Kill Process",
  STOCK: "Stock",
  SELECT_DATE: "Select Date",
  SELECT_OPERATOR: "Operator",
  PREV_SELECT_DATE: "Before the Selected Date",
  PREV: "Previous",
  AFTER: "Next",
  NEXT_SELECT_DATE: "After the Selected Date",
  SELECT_CURRENT_DATE: "Selected Date",
  SELECT_DATE_FIELD_PROPERTIES: "Date Property",
  BY_CREATED_DATE: "By Created Date",
  BY_UPDATE_DATE: "By Updated Date",
  BY_STOCK_UPDATE_DATE: "By Stock Update Date",
  BY_PRICE_UPDATE_DATE: "By Price Update Date",
  STOCK_START_DATE: "Start Date",
  STOCK_END_DATE: "End Date",
  OPERATOR: "Operator",
  DATE_PROPERTIES_FIELD: "Date Type",
  SESSION_ID: "Session ID",
  TRANSFERRED_PRODUCT_COUNT: "Count of Stock Transferred",
  UPDATE_CURRENCY: "Update Currency",
  CURRENCY: "Currency",
  EXCHANGE_RATE: "Exchange Rate",
  CURRENCY_DECIMAL: "Currency Decimal",
  INTEGRATION_INFORMATION: "Integration Information",
  PROVIDER_PRODUCT_ID: "Integration ID",
  TRANSFER_MESSAGE: "Transfer Result",
  PREPARER: "Preparer",
  MARKET_PLACE: "Marketplace",
  LAST_STATUS: "Last Status",
};

var batchOperationsEn = {
  IMPORT_TRANSACTION: "Import Stock Movements",
  IMPORT_PRODUCT_SHELF_RELATION: "Import Product-Shelf Relation",
  IMPORT_SHELF: "Import Shelf",
  UPLOAD: "Upload",
  BROWSE: "Browse",
  SELECTED_FILE: "Selected File",
  PLEASE_WAIT_FOR_PROCESS: "Please Wait... Being processed...",
  DOWNLOAD_IMPORT_REPORT: "Click on file name to download import reports.",
  DOWNLOAD_PREVIEW_REPORT: "Please click on the file name to see the analysis of the uploaded file.",
  IMPORT_SHELF_INFO1: "In this section, sub locations can be defined to the system with excel.",
  CLICK: "Click",
  IMPORT_SHELF_INFO2: "In this section, the relations between sub locations and stocks can be defined to the system with excel.",
  IMPORT_SHELF_INFO3: "In this section, a process list is created using excel.",
  DOWNLOAD_SAMPLE_EXCEL_FILE: "Download Sample Excel File",
  UPLOAD_COMPLETED_DOWNLOAD_REPORT: "transaction created. Click on file name to download import reports.",
  SAMPLE_DATA_SET: "Sample Data Set",
  DATA_PATTERN: "Data Pattern",
};

var invoicesEn = {
  ORDERS: "Orders",
  CHANNEL_ORDERS: "Channel Orders",
  STATISTICS: "Statistics",
  CHOOSE_USER: "Select User",
  SHOW_STATISTIC: "View Statistic",
  TRANSFER_EXCEL_BUTTON_NAME: "Export orders with missing stocks to excel",
  TRANSFER_EXCEL: "Transfer to Excel",
  ORDER_STATUS_HISTORY: "Order Status History",
  NOT_TRANSFERRED_ERP: "Not Transferred To ERP",
  MANUAL_TRANSFER_ERP: "Manual Transfer to ERP",
  BALANCE_PRODUCT_ORDERS: "Orders with Missing Stock",
  SELECT_STATUSSES_ORDER: "Select Order Statuses",
  SEARCH_STATUS: "Search Status",
  REMOVE_STATUS: "Remove",
  SELECT_ALL: "Select All",
  TAKE_ALL: "Take All",
  WAS_SELECT_STATUS: "Status Selected",
  ALL: "All",
  TRANSFER_TO_ERP: "Transfer to ERP",
  ACCOUNTING: "Accounting",
  ACCOUNTING_TRANSFERS: "Accounting Transfers",
  SHIPMENT_TRANSFERS: "Shipment Transfers",
  TAKE_TO_ACCOUNTING: "Send to Accounting",
  ACTION: "Action",
  ORDER_LINE_COUNT: "Order Line Count",
  TRANSFER: "Transfer",
  ORDER_STATUS: "Order Status",
  ORDER_DATE: "Order Date",
  GENERAL_TOTAL: "Total",
  EMAIL_NATIVE: "E-mail",
  ORDER_TYPE: "Order Type",
  BALANCE_PRODUCT_NOTIFY: "Missing Stock Notification",
  CREATE_STOCK_ACTION: "Create Stock Movement",
  ORDER_TRANSFERED_ERP: "The order has been transferred to the ERP.",
  ORDER_NOT_TRANSFERED_ERP: "The order could not be transferred to the ERP.",
  CONNECTION_LOST: "Unable to connect to server",
  DOWNLOADING: "Downloading",
  INVOICES_INFO: "You can query and manage your orders transferred with the form above. Please expand your search criteria.",
};

var locationEn = {
  LOCATIONS: "Locations",
  CREATE_LOCATION: "Create Location",
  LOCATION_NAME: "Location Name",
  LOCATION_TYPE: "Location Type",
  SELECT_LOCATION_TYPE: "Location Type",
  SELECT_REMOVAL_TYPE: "Reduction Type",
  REMOVAL_TYPE: "Reduction Type",
  NOT_FOLLOWING: "Not followed.",
  CHOOSE_USER: "Select User",
  SHELF_CODE: "Sub Location Code",
  SHELF: "Sub Location",
  SHELF_NAME: "Sub Location Name",
  WAREHOUSE_NAME: "Location",
  EMPTY_SHELF: "Empty Location",
  USED_SHELF: "Used Sub Location",
  NO_PRODUCT_IN_SHELF: "Non-Product Defined Sub Location",
  SUB_LOCATION_STATUS: "Sub Location Status",
  SEARCH: "Search",
  SAVE: "Save",
  CANCEL: "Cancel",
  ORDER_CANCEL: "Cancel",
  SHELF_ORDER_NU: "Sub location Order Nu",
  SHELVES: "Sub Locations",
  SHELF_DETAIL: "Shelf Detail",
  CREATE_SHELF: "Create Sub Location",
  SHELF_OPERATION: "Shelf Operations",
  PRODUCT_INFO: "Stock Information",
  PRODUCT_CODE: "Stock Code",
  ENTER_CRITERIA_SEARCH_SHELF: "Enter criteria for search sub location",
  PRODUCT_VARIATION: "Stock variation",
  ENTER_CRITERIA_SEARCH_PRODUCT: "Enter stock search criteria",
  FILTER: "Filter",
  PRODUCT_SHELF_RELATION: "Stock-Sub Location Relation",
  TRANSPORTERS: "Transporters",
  TRANSPORTER_SECTIONS: "Section of Transporter",
  TRANSPORTER_NAME: "Transporter Name",
  SECTION_NAME: "Section Name",
  SELECT_TRANSPORTER: "Select Transporter",
  WAREHOUSE: "Warehouse",
  WAREHOUSES: "Warehouses",
  WAREHOUSE_UPDATED: "Warehouse Updated",
  SHELF_UPDATED: "Shelf Updated",
  SHELF_CREATED: "Shelf Created",
  CONFIRM: "Confirm",
  CONFIRM_ALL: "Confirm All",
  DELIVER_ALL: "Deliver All",
  NOT_CONFIRM: "Do Not Confirm",
  CONFIRM_MESSAGE: "Confirm Message",
  CONFIRM_MESSAGE_BODY: "Are you sure you want to delete this line??",
  SHELF_DELETED_MESSAGE: "Sub location deleted.",
  SHELF_PRODUCT_MATCH_MESSAGE: "Sub location and product matched.",
  SHELF_PRODUCT_RELATION_DELETE: "Sub location and product relation deleted.",
  SELECT_ONE_CRITERIA: "Please select at one criterion.",
  SECTION_DEFINED: "Section defined.",
  SECTION_DELETED: "Section deleted.",
  TRANSPORTER_DEFINED: "Transporter for order preparing defined.",
  TRANSPORTER_DELETED: "Transporter for order preparing deleted.",
  REPORT_NAME: "Report Name",
  SHELF_TYPE: "Sub Location Type",
  PACKAGING: "Packaging",
  LOCATION: "Location",
  UNDEFINED: "Undefined",
  BARCODE: "Barcode",
  PROCESS: "Processed",
  PROCESSED: "Processed",
  LAST_PROCESS: "Last Process",
  WIDTH: "Width",
  HEIGHT: "Height",
  DEPTH: "Depth",
  WEIGHT: "Weight",
  DOWNLOAD: "Download",
  PRODUCT_COUNT: "Product Count",
  VOLUME: "Volume (w X h X d)",
  WAREHOUSE_LAYOUT_GUIDE: "Location Layout Guide",
  CHANNEL: "Channel",
  CREATE_GUIDE_REQUEST: "Create Guide Request",
  WHATIS_LOCATION_GUIDE: "What is Location Layout Guidelines?",
  LOCATION_GUIDE_INFO: "It is a tool that will help you organize your location by analyzing your order data. To create a guide request, select a channel and create a guide request..",
  REQUEST_DATE: "Request Date",
  RESPONSE_DATE: "Response Date",
  TRANSPORTER_ORDER_PREP_MSG: "User selection is not mandatory",
  TRANSPORTER_INFO_MSG: "You cannot use this field because bulk order definition is made to the transporter.",
  PACKING_SHELF: "Fixed(Packing Rack)",
  PACKING_SHELF_INFO1: "Let the shelf name be the barcode",
  PRODUCT: "Stock",
  PRODUCT_DEFINE_METHOD: "Product Define Method",
  PRODUCT_DEFINE_TO_SHELVE: "Define Product to Shelf",
  BARCODE_NU: "Barcode No",
  GET: "Bring",
  PRODUCT_NAME: "Stock Name",
  VARIATION: "Variation",
  SERIAL_NUMBER: "Serial Number",
  NOTHING: "Nothing",
  LOCATION_MSG: "If there is a product-sub location relationship but there is no stock, those sub locations are listed",
  WAREHOUSE_ANALYSIS: "Warehouse Product Compliance Analysis",
  RESPONSE_ITEM_COUNT: "Response Item Count",
  RESPONSE_ENOUGH: "Compliance",
  REQUEST_QUANTITY: "Request Quantity",
  RESPONSE_QUANTITY: "Response Quantity",
  PRIORITY: "Priority",
  RESPONSE_COUNT: "Response Count",
  REQUIRED: "Required",
  NOT_REQUIRED: "Not Required",
  PRODUCT_SEARCH: "Search Product",
  APPROVE: "Approve",
  APPROVED: "Approved",
};

var loginEn = {
  COPYRIGHT_RESERVED: "All rights reserved.",
  ENTER: "Enter",
  PASSWORD: "Password",
  EMAIL: "E-mail",
  LOGIN: "Login"
};

var batchOperationsForTsoftEn = {
  IMPORT_PRODUCTS_AND_FILTERS: "Add Filters to Stock",
  IMPORT_PRODUCT_FILTER_FOR_TSOFT_INFO1:
    "From this section, the product filters will be defined in the T-Soft B2C channel you have selected with excel. To download the sample excel file",
  UPLOAD: "Upload",
  SELECT_FILE: "Select File",
  DOWNLOAD: "Download",
  UPLOAD_COMPLETED: "Upload Completed",
  DOWNLOAD_REPORT: "Download Report",
};

var actionEn = {
  CREATE_AN_OFFER: "Create An Offer",
  CREATE_STOCK_MOVEMENTS: "Create Stock Movements",
  STOCK_MOVEMENTS: "Stok Movements",
  STOCK_MOVEMENT_DETAIL: "Stock Movement Detail",
  CUSTOMER_CODE: "Customer Code",
  WAREHOUSE_FOR_ACTION: "From Location",
  WAREHOUSE_FOR_ACTION2: "To Location",
  ACTION_TYPE: "Movement Type",
  NEW_ACTION_TYPE: "New Movement Type",
  CHOOSE_ACTION_TYPE: "Select movement type",
  CHOOSE_REFUND_TYPE: "Select refund type",
  INVOICE_NU: "Invoice Nu",
  CHOOSE_DUE_DATE: "Select due date",
  DUE_DATE: "Due Date",
  DAY: "Day",
  CREATOR_USER: "Creator User",
  SELECT_CREATOR_USER: "Select creator user",
  ORDER_PREPARE_USER: "User Who Prepared Order",
  SELECT_ORDER_PREPARE_USER: "Select prepared user",
  TRANSACTION_NOTE: "Transaction Note",
  SHIPPING_TYPE: "Delivery Type",
  SHIPPING_TYPE1: "Cargo",
  SHIPPING_TYPE2: "By Hand",
  TAX_TYPE: "Tax Type",
  ACTION_INFO1: "It must be calculated over the VAT included amount of the movement list.",
  ACTION_INFO2: "In order to change the registration information, you can stop the stock entry and make changes..",
  CONTINUE: "Continue",
  SELECTED_PRODUCTS: "Selected Stocks",
  BASKET: "Basket",
  BASKET_AMOUNTS: "Basket Amount",
  VAT_INCLUDED: "VAT included",
  VAT:" VAT",
  VAT_NOT_INCLUDED: "VAT Excluding",
  STOCK_CODE: "Stock Code",
  DISCOUNT: "Discount",
  DISCOUNT_PERCENT: "Discount Rate",
  PRICE: "Price",
  NEW_PRICE: "New Price",
  QUANTITY: "Quantity",
  LOT_SERIAL: "Lot/Serial Number",
  CHOOSE_A_LOT_SERIAL: "Select Lot/Serial Number",
  LEAD_TIME_DESC: "Lead Time Description",
  SEARCH_RESULT: "Search Result",
  PRODUCT_FOLLOW: "Stock Following",
  SUB_PRODUCT_FEATURE: "Sub Product Feature",
  STOCK_QTY: "Stock Quantity",
  WAREHOUSES_QTY: "Location Distribution",
  WITH_DISCOUNT: "Discount",
  DISCOUNT_TYPE: "Discount Type",
  ADD: "Add",
  BULK_ADD: "Add Batch",
  ACTION_INFO3: "There are no records.",
  TRANSPORTER_OR_SECTION: "Transporter/Section",
  PREPARING_USER: "Prepared",
  CARGO_DATE: "Shipment Time",
  ACTION_LINE_COUNT: "Order Line Count",
  MOVEMENT_TYPE: "Movement Type",
  MOVEMENT: "Movement",
  LAST_MOVEMENT: "Last Movement",
  CHANNEL_TYPE: "Channel Name",
  CUSTOMER_INFO: "Customer Information",
  NOT_ASSIGNED_ORDER: "User Not Assigned",
  ORDERS_IN_PACKAGE_PROCESS: "In Package Process",
  PREPARED_ORDERS: "Completed Preparations",
  NOT_PREPARED_ORDERS: "Not Prepared",
  NOT_ASSIGNED_TRANSPORTER: "Not Assigned to Transporter",
  ORDERS_HAS_ONE_PRODUCTS: "Single Product",
  TRANSACTION_ID: "Transaction No",
  STATUS_FILTERS: "Status Filters",
  FILTER_OPTIONS: "Filter Options",
  SHIPPING_DATE: "Shipping Date",
  SELECT_WAREHOUSE: "Select Location",
  SELECT_PAYMENT_TYPE: "Select Payment Type",
  FIELD: "Field",
  VALUE: "Value",
  SELECT_FIELD: "Select Field",
  NOT_PREPARED_INVOICE: "Unprepared Invoices",
  ACTION_NOT_IN_VAT: "Tax Free Transactions",
  DELETED_ACTIONS: "Deleted Transactions",
  ARCHIVE: "Archive",
  ACTION_LINES_QTY_TRANSFER_EXCEL: "Transfer Order Stock Total",
  ACTIONS_TRANSFER_EXCEL: "Transfer Orders",
  TRANSFER_TO_PDF: "Transfer To PDF",

  REPREPARE_ACTION: "Undo Preparing Order",
  COPY_ACTION: "Copy Stock Movement",
  SHOW_ERP_INTEGRATION: "View Accounting Integration",
  EDIT_ACTION_INFO1: "You can edit it because it hasn't been more than 24 hours after creating a stock movement",
  EDIT_ACTION_INFO3: "You can change the responsible user that movement preparation from the list view",
  EDIT_ACTION_INFO4: "Please click the \"Update\" button so that the changes made on the basis of stock items are active in this movement and in the orders section..",
  PRINT: "Print",
  PRODUCTS: "Stock",
  INTERNATIONAL_PRICE: "International Price",
  LOCAL_PRICE: "Local Price",
  TOTAL_STOCK_QTY: "Total Stock Quantity"
};

var notificationEn = {
  NOTIFICATIONS: "Notifications",
  NOTIFICATION_INFO1: "You can set the levels at which the notification alert will be visible.",
  NOTIFICATION_INFO2: "Notifications outside the last 24 hours are automatically deleted.",
  NOTIFICATION_INFO3: "You haven't notifications yet.",
  GENERAL: "General",
  IN_COMPANY: "In-Company",
  PERSONALITY: "Personal",
  CLEAR_NOTIFICATIONS: "Clear Notifications",
  MESSAGE: "Message",
  WHO: "Who"
};

var ordersPrepareEn = {
  ORDER_PREPARING: "Order Preparation",
  ORDER_TRANSPORTER_RELATION: "Order-Transporter Relation",
  TRANSPORTER: "Transporter",
  SECTION: "Section",
  EMPTY_SECTION: "Empty Section",
  ORDERS_FOR_WAITING_PRODUCTS: "Product Pending Orders",
  ORDERS_SECTION_INFOMSG1: "Order not matched with any section",
  ORDERS_SECTION_INFOMSG2: "No order has been matched with the transporter.",
  ORDERS_SECTION_INFOMSG3: "The packaging shelf is not defined. Define from",
  ORDERS_SECTION_INFOMSG4: "All sub locations in the packaging are used. You can define a new shelf from the",
  ORDERS_SECTION_INFOMSG5: "There are no completed orders",
  ORDERS_SECTION_INFOMSG6: "There are no products that need to be prepared for any order",
  ORDERS_SECTION_INFOMSG7: "Please read the stock code of the product you will place in the order box",
  ORDERS_PREPARING_FOR_TRANSPORTER: "Order Preparation by Transporter",
  CONTROL: "Control",
  PREPARED_ORDERS: "Prepared Orders",
  ORDERS_IN_PACKAGING_PROCESS: "Orders in Packaging",
  PACKAGING: "Packaging",
  WILL_PREPARE_PRODUCT_FOR_ORDERS: "Products to be Prepared for Orders",
  CLOSE_CONTINUE_READ: "Close and Continue Scanning",
  CONTINUE_READ: "Continue Scan",
  MOST_LESS: "Least",
  MOST_PLUS: "Most",
  PLUS_PREPARING: "Prepare",
  SELECT_PREPARING_METHOD: "Choose Preparation Method",
  CAMERA: "Camera",
  HAND_TERMINAL: "Hand Terminal",
  HAND: "Hand",
  SELECT_CAMERA: "Select Camera",
  START_NEXT_LIST: "Start Next List",
  START: "Start",
  LIST: "List",
  PREPARING: "Preparing",
  TOTAL_PRODUCT_COUNT: "Total Line Count of Order",
  TOTAL_PRODUCT_QUANTITY: "Total Product Quantity of Order",
  PRODUCT_ORDER_RELATION: "Product / Order Information",
  LOT_DETAIL_QTY: "Lot Detail / Quantity",
  LOT_DETAIL: "Lot Detail",
  READY: "Ready",
  REVERT: "Revert",
  UNCONFIRM: "Unconfirm",
  TRANSPORTER_SECTION: "Transporter Section",
  STOP: "Stop",
  STOP_CAMERA: "Stop Camera",
  PACKAGING_AREA_DETAIL: "Packaging Area Detail",
  DETAIL: "Detail",
  TRANSPORTER_DETAIL: "Transporter Detail",
  PRODUCT_LIST: "Stock List",
  SHIPPING_READY: "Ready to Ship",
  SHOW_ACTION_DETAIL: "View Transaction Detail",
  SELECT_ORDER: "Select Order",
  PRODUCT_BARCODE: "Stock Barcode",
};

var reportsEn = {
  REPORTS: "Reports",
  REPORTS_INFOMSG1: "There are no reports within your role",
  REPORTS_INFOMSG2: "Report Created on",
  FILE_TYPE: "File Type",
  CREATE_REPORT: "Create Report",
  UPDATE_STATUS: "Update Status",

};

var paginationEn = {
  TOTAL_RECORD_COUNT: "Total record",
  TOTAL_PAGE_COUNT: "Total page",
  PAGINATION_RECORD_COUNT: "Pagination",
  PERIOD: "Period",
  CALCULATING: "Calculating",
  UPDATED_DATE: "Updated Date"
};

var commonEn = {
  HELP_PAGE: "Help Page",
  HELP_WITH_VIDEO: "Video Help",
  INSTALL_WIZARD: "Install Wizard",
  SYSTEM_SETTINGS: "System Settings",
  CHANNEL_SETTINGS: "Channel Settings",
  PROVIDER: "Provider",
  CHANNEL_NAME: "Channel Name",
  CHANNEL_NAME_LABEL: "Your Marketplace Name in Provider",
  DEFAULT_CURRENCY: "Default Currency",
  TAX_STATUS: "Tax Status",
  HAS: "Available",
  NOT_HAS: "Not Available",
  PROTOCOL: "Protocol",
  WEB_SERVICE_URL: "Web Service URL (For example; www.xyz.com)",
  WEB_SERVICE_USERNAME: "Username For Web Service",
  WEB_SERVICE_PASSWORD: "Password For Web Service",
  SETTINGS_INFOMSG1: "You can define web service information of T-Soft b2c sites. You can update \"order statuses\" and \"payment types\" with the Update Settings button.",
  SETTINGS_INFOMSG2: "The Order Statuses of your selected channel setting are not loaded. Please click.",
  SETTINGS_INFOMSG3: "In the Integrations section, you can set which order statuses will be automatically selected after the channel selection. <br/>Also, you should set the order statuses that you want to be automatically drawn to the system as default.",
  SETTINGS_INFOMSG4: "To view the payment types, please select the channel from the top page.",
  SETTINGS_INFOMSG5: "In this section, you can determine which warehouse will be deducted according to the status of orders coming from B2C sites.",
  SETTINGS_INFOMSG6: "Transfer the Total Stock Amount.",
  SETTINGS_INFOMSG7: "If Yes is checked, \"Total Stock Amount\" will be sent to the selected channel.<br/> If the selected warehouse; In the section below, it is the definition of the fallback warehouse that will be activated in the manual transfer for the warehouse<br/> and the transaction type that has not been selected according to the status of the order..",
  SETTINGS_INFOMSG8: "You can define the warehouse matching of the stock quantities to be transferred to your B2C sites. If you wish, you can transfer the total stock on your site.",
  SETTINGS_INFOMSG9: "You can define the criteria of the orders to be transferred to the accounting program..",
  SETTINGS_INFOMSG10: "You can manually run scheduled tasks that run automatically.",
  SETTINGS_INFOMSG11: "You can define your e-mail addresses where you will receive notifications of products under critical stock quantity. ( You can define multiple mailing addresses using the <b>\",\"</b> separator. )",
  SETTINGS_INFOMSG12: "You can change the group of users who will prepare the order using the form above.",
  SETTINGS_INFOMSG13: "You can change the group of users who will check the prepared orders using the form above.",
  SETTINGS_INFOMSG14: "You can change the group of users who will check the prepared orders using the form above..",
  SETTINGS_INFOMSG15: "You can edit status changes in order preparation processes using the form above..",
  SETTINGS_INFOMSG16: " the transactions you will make in the channel will be valid for all stock movements created through Polypus.",

  UPDATE_PARAMETER: "Update Parameter",
  PROVIDER_ID: "Provider ID",
  DEFAULT: "Default",
  PAYMENT_TYPES: "Payment Types",
  SELECT_CHANNEL: "Select Channel",
  ADDED_DATE: "Date of Added",
  VALIDITY_DATE: "Date of Validity",
  AUTOMATIC_TRANSFER_SETTINGS: "Automatic Transfer Settings",
  DEFINE_DYNAMIC_STOCK_ACTION: "Dynamic Stock Movement Definition",
  SELECT_ALL: "Select All",
  REMOVE_ALL: "Select Remove",
  SUGGEST_DYNAMIC_ACTIONS: "Recommended Dynamic Moves",
  APPLIED_TRANSACTION_TYPE: "Type of Transaction to be Applied",
  WAREHOUSE_CHANNEL_RELATION: "Location and B2C Channel Matching",
  IS_SEND_TOTAL_STOCK: "Send Total Stock ?",
  YES: "Yes",
  NO: "No",
  ERP_INTEGRATION_SETTINGS: "Accounting Transfer Settings",
  MISSION_NAME: "Job Name",
  COMMAND: "Command",
  EXPRESSION: "Expression",
  COMMAND_ARGUMENTS: "Command Arguments",
  CRON_JOB: "Jobs",
  RUN: "Run",
  COMPANY_SETTINGS: "Company Settings",
  NOTIFY_EMAIL_SETTING: "Notification Email Setting",
  EMAIL_ADDRESS: "E-mail Address",
  COMPANY_UPLOAD_LOGO: "Company Logo Upload",
  ADD_BANKS: "Add Bank",
  BANKS: "Banks",
  IBAN: "IBAN",


  ORDER_PREPARE_ROLE: "Order Issuer Authority Group",
  CURRENT_SELECTED_ROLE: "Current Selected Group",
  SELECT_USER_ROLE: "Select user group",
  CONTROLLER_ROLE: "Order Control Authority Group",
  TRANSPORTER_ORGANIZATION: "Transporter Organization",
  PREPARATION_TYPE: "Preparation Type",
  BULK_PREPARATION: "Batch Preparation of Orders",
  DIVIDED_PREPARATION: "Segmentation of Orders for Preparation",
  MOST_ATTACH_ORDER_TO_TRANSPORTER: "Maximum Orders to be Assigned to Transporter",
  DEFAULT_VALUE: "Default Value",
  FREE_TRANSPORTER: "Free Transporter",
  RELATION_WITH_USER: "Relation With User",
  USER_RELATION_HEAD: "No Relation with User",
  ORDER_SYNC: "Order Synchronization",
  ACTION_STATUS: "Transaction Status",
  DASHBOARD: "Dashboard",
  ORDER_PREPARE: "Order Preparation",
  STOCK_MANAGEMENT: "Stock Management",
  STOCKTAKING: "Stock Taking",
  SHELF_CHANGE: "Shelf Changing",
  SHELF_CHANGE_NAME: "Shelf Change Name",


  PRODUCTION_STREAMS: "Production Stream",
  PRODUCTION_MOVEMENT: "Production Entry",
  CARGO_OPERATION: "Kargo ve Taşıma İşlemleri",
  CARGO_BARCODE: "Cargo Barcode",
  BULK_ACTIONS: "Batch Operations",
  INTEGRATIONS: "Integrations",
  LOCATION_MANAGEMENT: "Location and Shelf Management",
  RETURN_MANAGEMENT: "Return Management",
  CATEGORY_BRAND_MANAGEMENT: "Category and Brand Management",
  BRAND_MANAGEMENT: "Brand Management",
  BRAND_NAME: "Brand Name",
  BRAND_LINK: "Brand Link",
  TAG_MANAGEMENT: "Tag Management",
  TAG_NAME: "Tag Name",
  CATEGORY_SELECT: "Select Category",
  CATEGORY_TREE: "Category Tree",
  NEW_MAIN_CATEGORY_NAME: "New Main Category Name",
  USER_AND_ROLES: "Users and Roles",
  ROLES: "Roles",
  ROLE_LIST: "Role List",
  ROLE_EDITING: "Role Editing",
  PERSONAL_SETTINGS: "Personal Settings",
  CLOSE: "Close",
  MODULES: "Modules",
  LOGOUT: "Exit",
  GENERAL_INFORMATION: "General Information",
  IS_WHOLESALE_PRODUCT: "Is it a wholesale product ?",
  SUPPLIER_CODE: "Supplier Code",

  PRODUCT_SHORT_DESCRIPTION: "Product Short Description",
  ENTER_PRODUCT_TAG: "Enter product tag",
  SUPPLIER: "Supplier",
  STOCK_UNIT: "Stock Unit",
  PRICE_INFORMATION: "Price Information",
  SELLING_PRICE_WITH_EXAMPLE: "Sale Price (For example; 13.34, 1234.5)",
  SELLING_PRICE: "Sale Price",
  SELLING_PRICE_VAT_INCLUDED: "Sale Price With Vat",
  DISCOUNTED_SELLING_PRICE: "Discounted Selling Price",
  DISCOUNTED_SELLING_PRICE_VAT_INCLUDED: "Discounted Selling Price With VAT",
  BUYING_PRICE: "Buying Price",
  TAX_PERCENT: "Tax Rate",
  CARGO_INFORMATION: "Cargo information",
  CARGO_AND_ADD_INFORMATION: "Kargo ve Ek Bilgiler",
  SAME_ALL_CHANNEL: "Tüm Kanallar Aynı",
  SALES: "Sales",
  PURCHASE: "Purchase",
  CHANNEL_BASED: "Kanal Bazlı",
  SEO_SETTINGS: "Seo Settings",
  SEO_LINK: "Seo Link",
  SEO_HEAD: "Seo Head",
  SEO_DESCRIPTION: "Seo Description",
  PRODUCT_INFORMATION: "Stock Information",
  PRODUCT_DETAIL: "Stock Detail",
  ADDITIONAL_INFO1: "Additional 1",
  ADDITIONAL_INFO2: "Additional 2",
  ADDITIONAL_INFO3: "Additional 3",
  ADDITIONAL_INFO4: "Additional 4",
  ADDITIONAL_INFO5: "Additional 5",
  MIN_ORDER_QTY: "Min. Order Quantity (For Member)",
  MAX_ORDER_QTY: "Max. Order Quantity (For Member)",
  LIST_NU: "List No",
  OPTIONS: "Options",
  ACTIVE: "Active",
  NEW_PRODUCT: "New Product",
  STOCK_QTY: "Stock Quantity",
  CRITIC_STOCK_QTY: "Critical Stock Quantity",
  VIRTUAL_STOCK_QTY: "Virtual Stock Quantity",
  INVENTORY_INFORMATION: "Inventory Information",
  INVENTORY: "Inventory",
  FOLLOW_METHOD: "Following Method",
  NOT_FOLLOW: "Not Follow",
  UNIQUE_SERIAL_NU: "Unique Serial Number",
  LOT: "Lot",
  SUB_PRODUCT: "Variation",
  HAS_SUB_PRODUCTS: "Has Variations",
  ADD_SUB_PRODUCT: "Add Variation",
  PACKAGE_PRODUCT_CONTENT: "Bill of Material",
  ADD_PACKAGE_PRODUCT_CONTENT: "Add Bom",
  CREATE_STOCK: "Create Stock",
  PROPERTY1: "Property 1",
  PROPERTY2: "Property 2",
  RECIPE_INFO2: "If you add a bill of materials to the product, stock increase will be carried out from the 'Production Bill of Materials' section. The products involved in the bill of materials will be decreased, and this product will be increased according to the bill of materials.",
  STOCK_LIST: "Stock List",
  SEARCH_IN_STOCK: "Search in Stock",
  SEARCH_IN_TAG: "Search in Tag",
  SELECT_BRAND: "Select Brand",
  CRITICAL_STOCK: "Critical Stock",
  MINUS_STOCK: "Minus Stock",
  VIRTUAL_STOCK: "Virtual Stock",
  ACTIVE_PRODUCT: "Active Stock",
  PACKAGE_PRODUCT: "Package Product",
  EMPTY_STOCK: "Empty Stock",
  NEARLY_EMPTY_STOCK: "Nearly Empty Stock",
  ENOUGH_STOCK: "Enough Stock",
  SHOW_PRODUCT_VARIATION: "Show Product Variation",
  SHOW_PRODUCT_ACTION: "Show Product Action",
  UPDATE_PRODUCT_CLICK: "Click to Update Product",
  SHOW_MAIN_PRODUCT_BARCODE: "Click to See Main Product Barcode",
  DELETE_PRODUCT_CLICK: "Click to Delete Product",
  SYNC_PRODUCT_CLICK: "Click to Sync Product",
  STOCK_INFOMSG1: "Sends to Queue for Download as Excel",
  STOCK_INFOMSG2: "No lot/serial number is defined for any product",
  STOCK_INFOMSG3: "There is no stock information for variations for this stock",
  STOCK_INFOMSG4: "No movement is visible on this stock card.",
  STOCK_INFOMSG5: "There isn't stock movement for this stock.",
  STOCK_INFOMSG6: "There isn't lot/serial number for any product",
  SHOW_PRODUCT_LOT: "Show product lot",
  CREATE_LOT_SERIAL: "Create Lot/Serial",
  LOT_SERIAL_NUMBERS: "Lot/Serial Numbers",
  LOT_SERIAL_NUMBER: "Lot/Serial Number",
  ESTIMATED_DATE: "Expiration date",
  VARIATION_DISTRIBUTION: "Variation Distribution",
  STOCK_CARD: "Stock",
  SUB_PRODUCT_CODE: "Sub Product Code",
  PROPERTIES1: "Properties 1",
  PROPERTIES2: "Properties 2",
  PRODUCT_ACTIONS: "Product Stock Movements",
  MOVEMENT_DIRECTION: "Movement Direction",
  STATISTIC_REPORT: "Statistics Report",
  SELECT_VARIATION: "Please select variation",
  UNIT_PRICE: "Unit Price",
  PRODUCT_PROPERTIES: "Stock Features",
  REAL_STOCK_QUANTITY: "Actual Stock Quantity",
  PRODUCT_DELETED: "Stock Deleted",
  TAG_ORDER: "Label Sorting",
  NAME_SURNAME: "Name Surname",
  CREATE: "Create",
  ROLE: "Role",
  CREATE_ROLE_GROUP: "Create Role",
  ROLE_NAME: "Role Name",
  PERMISSIONS: "Permissions",
  SELECT_NATIVE: "Select",
  ORDER_LIST_ACCEPTING: "Order List Confirmation",
  PAYMENT_INFO: "Payment Info",
  ORDER_NATIVE: "Order",
  PRICE_WITH_VAT: "Price (VAT INCLUDED)",
  PRODUCT_TAG: "Product Tag",
  CUSTOMER_NOTE: "Customer Note",
  MISSING_REPORT: "Missing Report",
  MISSING_QTY: "Missing Amount",
  IS_IN_STOCK: "Is the product in stock?",
  COMPLETE: "Complete",
  MISSING_PRODUCT_LIST: "Missing Product List",
  MISSING_PRODUCTS: "Missing items",
  CREATE_ORDER: "Create Order",
  REVERT_ACTION_NATIVE: "Delete",
  INVOICE_INFOMSG1: "Missing product information has not been entered for the order.",
  INVOICE_INFOMSG2: "You can create an order with Stock Movement",
  ARRIVAL_PERSONAL: "Receiver",
  ORDER: "Order",
  ORDER_DETAIL: "Order Detail",
  CRITICAL_STOCK_LEVEL: "Critical Stock Level",
  WAREHOUSE_MANAGEMENT: "Location Management",
  WAITING: "Waiting",
  CARGO_READY: "Shipping Ready",
  IN_WAITING: "In Waiting",
  IN_READY: "In Ready",
  IN_COMPLETED: "In Completed",
  STATUS_WAIT: "Waiting",
  STATUS_OFFER_APPROVE: "Offer Approve",
  STATUS_OFFER_CREATE_ORDER: "Offer Order Create",
  STATUS_OFFER_DONE: "Offer Done",
  STATUS_OFFER_CANCEL: "Offer Cancel",

  MINUTE: "Minute",
  PLATFORM: "Platform",
  STOP_ADD_PRODUCT: "Stop Adding Products",
  START_ADD_PRODUCT: "Start Adding Products",
  FILTER_DETAIL: "Filter Detail",
  OK: "OK",
  ATTACH: "Attach",
  BULK_PRINT: "Bulk Print",
  SELECT_PREPARE_USER: "Select Prepare User",
  USERS: "Users",
  ENTER_INVENTORY_SEARCH_CRITERIA: "Type F3 for search",
  SHOW_CHANNEL_DETAIL: "Show Channel Detail",
  PROFILE_SETTINGS: "Profile Settings",
  TIMEZONE: "Timezone",
  SELECT_TIMEZONE: "Select Timezone",
  LANGUAGE: "Language",
  SELECT_LANGUAGE: "Select Language",
  GENERAL_SETTINGS: "General Settings",
  PREFERRED_CODE: "Preferred Code",
  PREFERRED_PRODUCT_CODE: "Preferred Product Code",
  RELOAD: "Reload",

  RETURN_OFFER_SCREEN_INFO1: 'İade Oluşturma',
  RETURN_OFFER_SCREEN_INFO2: 'Aramayı sipariş numarası, kargo numarası ve sipairiş id si ile yapabilirsiniz',

  SORTING: "Sorting",
  CHANGE_SORTING: "Change Sorting",
  PRODUCT_IMAGE_PATH: "Product Image URL",
  DEFAULT_PRODUCT_IMAGE_UPLOAD: "Default Image Path",
  ANALYTICS: "Analytics",
  WITH_VAT: "Including VAT",
  PRODUCT_PRICE_NO_DISCOUNT: "Non-Discounted Product Price",
  ANALYTICS_EMPTY_INFO: "No Data",
  ANALYTICS_EMPTY_INFO2: "No data has been generated yet related to analytics",
  NEW: "New",
  EXISTS: "Exists",
  MATCH: "Match",
  IMAGE: "Image",
  COUNTED: "Counted",
  RECEIVING_MESSAGE_INFO: "Expiration date and serial number details..",
  RECEIVING_MESSAGE_INFO2: "Proof in case of issues like product form damage",
  PRODUCT_EXPIRE_DATE: "Expiration Date",
  SELECT_PRODUCT: "Select Product",
  STOCK_MOVEMENT_SUMMARY: "Stock Movement Summary",
  PRODUCT_SALES_REPORT: "Product Sales Report",
  TURNOVER_REPORT: "Turnover Report",
  SUMMARY_LIST_TYPE: "Summary List Type",
  SUMMARY_LIST_TYPE_MARKETPLACE: "Marketplaces as Columns",
  SUMMARY_LIST_TYPE_DEFAULT: "Default",
  VENDOR_CODE: "Vendor Code",
  SELECT_VENDOR: "Vendor Information",
  VENDOR: "Vendor",
  EXCHANGE_RATE_INFO: "Exchange Rate Transactions",
  EXCHANGE_RATE_INFO2: "You can view and adjust the exchange values of currencies here. Currencies without a value will default to 1.",
  "Etiket Kategorisi zorunlu değildir. Etiket Kategori çözümü ihtiyacınız için lütfen başvurunuz.":"Label Category is not mandatory. Please apply for your Label Category solution needs."
};

var langEn = angular.merge(
  MenuEn,
  accountSettingsEn,
  cargoEn,
  customersEn,
  dashboardIndexEn,
  integrationPageEn,
  batchOperationsEn,
  invoicesEn,
  locationEn,
  loginEn,
  batchOperationsForTsoftEn,
  actionEn,
  notificationEn,
  ordersPrepareEn,
  reportsEn,
  commonEn,
  paginationEn
);

export {langEn};
