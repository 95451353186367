import "./invoices.scss";
import {getUserInfo} from "../../../utils/users";
import {InvoicesController} from "./invoices.component";
class searchProductController{
    constructor(
        API,
        ToastService,
        $timeout,
        i18nService,
        $auth,
        $state,
        DialogService,
        UserService,
        $templateCache,
        $scope,
        $stateParams,
        $translate,
        $location,
        $rootScope,
        $mdBottomSheet
    ) {
        "ngInject";
        this.API = API;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.$timeout = $timeout;
        this.$mdBottomSheet = $mdBottomSheet;

        this.i18nService = i18nService;
        this.state = $state;
        this.$auth = $auth;
        this.UserService = UserService;
        this.$templateCache = $templateCache;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.$location = $location;
        this.$rootScope = $rootScope;

        this.responsibleUsers = this.UserService.getResponsibleUsers();
        this.buyingProductQuantity = [];
        this.beepSound = new Audio('./files/success.wav');
    }

    $onInit(){
        if (this.searchingProduct !== '' && this.searchingProduct !== null){
            this.getBuyingProducts(this.searchingProduct);
        }
    }

    addToBuyingProduct(product){
        this.buyingProductsInArr.map(item => {
            if (item.productId === product.productId && item?.subProductId === product?.subProductId){
                this.ToastService.error('Bu Ürünü Daha Önce Eklemişsiniz , Eğer Daha Fazla İstiyorsanız Adetini Arttırabilirsiniz');
                this.$mdBottomSheet.hide()
                return;
            }
        })
        this.$mdBottomSheet.hide({
            product : product,
            quantity : this.buyingProductQuantity[product.barcode]
        });
    }
    showCamera(){
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/camera/bottomSheet.component.html",
            controller: "CameraController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                searchCallBack : this.searchProduct
            }
        })
            .then(response => {
                console.log(response);
             //   this.getBuyingProducts()
            })
    }

    scanCameraResume() {
        this.activeTab.cameraEvent?.resume()
        this.activeTab.cameraState = 'resume'
    }

    scanCameraStop(){
        this.activeTab.cameraEvent?.stop();
        this.activeTab.cameraState = 'stop'
    }
    scanCameraStart() {
        let parent = this;
        this.activeTab.cameraState = 'resume'
        this.activeTab.cameraEvent = new parent.barcodeScanner(this.activeTab.cameraTemplateId);
        // telefon için {width: 200, height: 150} ideal
        // pc için {width: 250, height: 250}
        //ekrana göre ayar yapmak lazım
        const config = {fps: 10, qrbox: {width: 200, height: 150}, experimentalFeatures: {useBarCodeDetectorIfSupported: true}}
        this.activeTab.cameraEvent.start(
            parent.activeTab.scannerCameraId,
            config,
            parent.scanCompleteCallback,
        ).catch((err) => {
        });
        this.beepSound.play().then().catch()
    }

    getBuyingProducts(searchProduct){
        this.API.all('stock/getById')
          .customPOST({searchValue: searchProduct})
          .then((response) => {
              this.takeBuyingProduct = null;
              if (response.response.status === 200) {
                  console.log(response.data.product,"<<<<<");
                  this.takeBuyingProduct = response.data.product;
              }
          })
    }
}
        searchProductController.$inject = ["API", "ToastService", "$timeout", "i18nService", "$auth", "$state", "DialogService", "UserService", "$templateCache", "$scope", "$stateParams", "$translate","$location","$rootScope","$mdBottomSheet"];
        const searchProductComponent = {
            templateUrl: "./views/angular/app/components/invoices/partials/search-buying-products.component.html",
            controller: searchProductController,
            controllerAs: "vm",
            bindings: {},
        };

        export {searchProductController,searchProductComponent}
