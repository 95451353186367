import { openIntroductionVideo } from "../../../utils/introduction-videos";
import { getUserSettings } from "../../../utils/users";

class IntegrationController {
  constructor(API, $auth, ToastService, $state, $queueFactory, $timeout, $location) {
    "ngInject";

    this.API = API;
    this.$auth = $auth;
    this.ToastService = ToastService;
    this.$state = $state;
    this.$queueFactory = $queueFactory;
    this.$timeout = $timeout;
    this.$location = $location;
    this.inputOrderStatus = [];
    this.importButtonDisabled = false;
    this.video = undefined;
    this.times = [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ];
    this.userSettings = getUserSettings();
  }

  orderLogPaginator(response) {
    this.ordersLogs = response.data;
    this.current_page = response.current_page;
    this.last_page = response.last_page;
    this.next_index = response.nextIndex;
    this.prev_index = response.prevIndex;
    this.per_page = response.per_page;
    this.total = response.total;
  }

  productLogPaginator(response) {
    this.productsLogs = response.data;
    this.product_current_page = response.current_page;
    this.product_last_page = response.last_page;
    this.product_next_index = response.nextIndex;
    this.product_prev_index = response.prevIndex;
    this.product_per_page = response.per_page;
    this.product_total = response.total;
  }

  $onInit() {
    var parent = this;
    this.API.all("integration/order-log")
      .doGET()
      .then((response) => {
        this.orderLogPaginator(response.data.orderLogPagination);
      });
    this.API.all("integration/get-product-log")
      .doGET()
      .then((response) => {
        this.productLogPaginator(response.data.productLogPagination);
      });
    this.API.all("settings/get-channels")
      .post()
      .then((response) => {
        this.channels = response.data.channels;
      });
    this.API.all("currency/get")
      .post()
      .then((response) => {
        this.currencies = response.data.currencies;
      });
  }

  onSelectTab(obj){
    this.$location.search(obj);
    this.video = undefined;
    if (obj.tab == 0) {
      this.video = 'integration';
    }
  }

  getPaginationAction(
    current_page = this.current_page,
    filterArr,
    per_page = this.per_page
  ) {
    let reqData = {
      per_page: per_page,
      page: current_page,
    };
    this.per_page = per_page;

    if (this.filterArr != undefined) {
      var reqDataWithFilter = angular.extend(this.filterArr, reqData);
    } else {
      var reqDataWithFilter = reqData;
    }

    this.API.all("integration")
      .customGET("order-log", reqDataWithFilter)
      .then((response) => {
        this.orderLogPaginator(response.data.orderLogPagination);
      });
  }

  getProductLogPaginationAction(
    current_page = this.product_current_page,
    filterArr,
    per_page = this.product_per_page
  ) {
    let reqData = {
      per_page: per_page,
      page: current_page,
    };
    this.product_per_page = per_page;

    if (this.product_filterArr != undefined) {
      var reqDataWithFilter = angular.extend(this.product_filterArr, reqData);
    } else {
      var reqDataWithFilter = reqData;
    }

    this.API.all("integration")
      .customGET("get-product-log", reqDataWithFilter)
      .then((response) => {
        this.productLogPaginator(response.data.productLogPagination);
      });
  }

  loadStatus(query = "") {
    var self_ = this;

    this.API.all("order/status")
      .post({
        query: query,
        channel_id: this.oiChannelId,
      })
      .then((response) => {
        self_.status = response.data.status;
      });

    return this.status;
  }

  orderImport() {
    var data = {
      channelId: this.oiChannelId,
      orderStartDate: this.orderStartDate,
      orderEndDate: this.orderEndDate,
      status: this.inputOrderStatus,
    };
    var parent = this;

    this.importButtonDisabled = true;

    parent.API.all("integration/import-orders")
      .post(data)
      .then(function (response) {
          parent.ToastService.success(
            response.data.message
          );
        }
      )
      .then((response) => {
        parent.filterOrderImportLog();
      })
      .then(function () {
        parent.importButtonDisabled = false;
      })
  }

  stockImport() {
    var data = {
      channelId: this.channelId,
      stockStartDate: this.stockStartDate,
      operator: this.operator,
      datePropertiesField: this.datePropertiesField,
    };

    var _taskId = 1,
      _queue = this.$queueFactory(3),
      parent = this;

    _queue.enqueue(function (vqObj) {
      parent.API.all("integration/import-stock")
        .post(data)
        .then((response) => {
          parent.ToastService.success(
            "B2C hizmetinizden stok aktarımı tamamlandı."
          );
          if (response.error) {
            parent.$auth.logout();
            parent.$state.go("app.login");
            parent.ToastService.success(response.message);
          }
        })
        .then((response) => {
         parent.filterProductImportLog();
        });
    });

    _queue.clear();
  }

  getStatusByChannelId(channelId, statusObj) {
    var self_ = this;

    this.API.all("order/status")
      .post({
        channel_id: channelId,
      })
      .then((response) => {
        if (statusObj == "inputOrderStatusForFilter") {
          self_.inputOrderStatusForFilter = response.data.status;
        } else {
          self_.inputOrderStatus = response.data.status;
        }
      });

    return this.inputOrderStatus;
  }

  killProcess(processId) {
    this.API.all("integration/delete-log")
      .post({
        processId: processId,
      })
      .then((response) => {
        this.$onInit();
      });
  }

  killProductProcess(processId) {
    this.API.all("integration/delete-product-log")
      .post({
        processId: processId,
      })
      .then((response) => {
        this.$onInit();
      });
  }

  clearFilterForm() {
    this.inputOrderStatusForFilter = null;
    this.oiChannelIdForFilter = null;
    this.orderUpdateStartDateForFilter = null;
    this.orderUpdateEndDateForFilter = null;
    this.orderUpdateStartTimeForFilter = null;
    this.orderUpdateEndTimeForFilter = null;
    this.filterArr = {};
    this.getPaginationAction(1, null, this.per_page);
  }

  clearOIForm() {
    this.inputOrderStatus = null;
    this.oiChannelId = null;
    this.orderStartDate = null;
    this.orderEndDate = null;
    this.importButtonDisabled = false;
  }

  clearStockFilterForm() {
    this.stockStartDate = null;
    this.channelId = null;
    this.operator = null;
    this.datePropertiesField = null;
  }

  clearStockSearchFilterForm() {
    this.stockStartDate = null;
    this.channelId = null;
    this.operator = null;
    this.datePropertiesField = null;
    this.getProductLogPaginationAction(1, null, this.product_per_page);
  }

  filterOrderImportLog() {
    let reqData = {
      channelId: this.oiChannelId,
      startDate: this.orderUpdateStartDateForFilter,
      endDate: this.orderUpdateEndDateForFilter,
      startTime: this.orderUpdateStartTimeForFilter,
      endTime: this.orderUpdateEndTimeForFilter,
      page: 1,
      per_page: this.per_page,
    };

    this.filterArr = reqData;

    this.API.all("integration")
      .customGET("order-log", reqData)
      .then((response) => {
        this.orderLogPaginator(response.data.orderLogPagination);
      });
  }

  filterProductImportLog() {
    let reqData = {
      channelId: this.channelId,
      stockStartDate: this.stockStartDate,
      operator: this.operator,
      datePropertiesField: this.datePropertiesField,
      page: 1,
      per_page: this.per_page,
    };

    this.product_filterArr = reqData;

    this.API.all("integration")
      .customGET("get-product-log", reqData)
      .then((response) => {
        this.productLogPaginator(response.data.productLogPagination);
      });
  }

  openIntroductionVideoModal(video) {
    openIntroductionVideo(video);
  }

  updateCurrency() {
    var parent = this;
    this.API.all("integration")
    .doGET("save-currency")
    .then((response) => {
      parent.ToastService.success(response.data.message);
    });

  }

  getOrdersOnVSRm() {
    this.vsrmOrderButtonDisabled = true;
    this.API.all('integration/vsrm').post().then((response) => {
      this.vsrmOrderButtonDisabled=false;
      this.ToastService.show(response.data.orderCount+' Adet sipariş aktarıldı.');
    });
  }

}
IntegrationController.$inject = ["API", "$auth", "ToastService", "$state", "$queueFactory", "$timeout", "$location"];
export const IntegrationComponent = {
  templateUrl: "./views/angular/app/components/integration/integration.component.html",
  controller: IntegrationController,
  controllerAs: "vm",
  bindings: {},
};
