import "./offer.scss";
import {getUserInfo, getToken} from "../../../utils/users";

class PurchaseRequestController {
    constructor($auth, $window, $state, $location, $rootScope, $stateParams, API, $mdBottomSheet, $scope, DialogService, ToastService) {
        'ngInject';

        this.$auth = $auth;
        this.$window = $window;
        this.$state = $state;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.API = API;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.DialogService = DialogService;
        this.ToastService = ToastService;
        this.dropdownCategoriesTranslationsText = {
            buttonDefaultText: "Kategori seçiniz...",
            checkAll: "Tümünü Seç",
            uncheckAll: "Seçimleri Kaldır",
            searchPlaceholder: "Kategori Ara",
            dynamicButtonTextSuffix: " kategori seçildi.",
        };
        this.dropdownCategoriesSettings = {enableSearch: true, scrollableHeight: '400px', scrollable: true};

        this.categories = null;
        this.categoryTags = null;
        this.filter = {
            barcode: null,
            categoryTag: null,
            brand: null,
            tagId: null,
            status: []
        }
        this.page = 1;
        this.limit = 20;
        this.total = 0
        this.purchaseRequests = [];
        this.selectedProduct = [];
        this.detailScreen = false
        this.productDetail = [];
        this.product_id = null;
    }

    $onInit() {
        this.getRequest(1)
        /*
                this.API.all("category/getChildCategories")
                    .post()
                    .then((response) => {
                        this.categories = response.data.categories;
                    });
                */

        this.API.all('/products/tags').post().then((response) => {
            this.categoryTags = response.data.categoryTags;
        });
        let parent = this;
        this.$scope.$on('selectProductEvent', function (event, selectProduct) {
            if (parent.selectedTabIndex == parent.wrapperTabIndex) {
                parent.requestPurchaseProductOne(selectProduct.id);
            }
        });
    }

    getRequest(page, limit = 20) {
        let filter = {
            page: page,
            limit: limit,
        }

        if (this.filter.barcode) {
            filter.barcode = this.filter.barcode
        }
        if (this.filter.categoryTag?.resourcetype?.id) {
            filter.tagId = this.filter.categoryTag?.resourcetype?.id
        }

        this.filter.brand?.forEach(b => {
            if (b.id) {
                filter.brandId.push(b.id);
            }
        })

        if (filter.brandId?.length > 0) {
            filter.brandId = filter.brandId.join('|')
        }

        if (this.filter.status?.length > 0) {
            filter.status = this.filter.status.join('|')
        }

        this.API.all('purchase-request')
            .customGET(null, filter)
            .then((response) => {
                if (response.response.status === 204) {
                    this.purchaseRequests = null;
                    return;
                }
                const {total, limit, totalPage, list} = response.data;
                this.limit = limit;
                this.total = total;
                this.purchaseRequests = list;
                this.totalPage = totalPage;
                this.checkAllSelect();
            })
            .catch(function () {
            });
    }

    getRequestDetail(productId, onlyOpen = true) {
        this.product_id = productId;
        this.API.all('purchase-request/' + productId)
            .customGET(null, {
                onlyOpen: onlyOpen ? 1 : 0
            })
            .then((response) => {
                if (response.response.status === 204) {
                    this.detailScreen = false;
                    this.getRequest(1)
                    return;
                }
                this.detailScreen = true
                this.productDetail = response.data.list;
            })
            .catch(function () {
            });
    }

    loadBrands(query = "") {
        var self_ = this;

        this.API.all("brand/get")
            .post({
                query: query,
            })
            .then((response) => {
                self_.brands = response.data.brands;
            });

        return this.brands;
    }

    clearForm() {
        this.filter = {
            barcode: null,
            categoryTag: null,
            brand: null,
            tagId: null,
            status: [],
        }
        this.page = 1;
        this.limit = 20;
        this.getRequest(1)
    }

    showBottomSheet(isShowCamera) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                isShowCamera: isShowCamera
            }
        })
    }

    showProductBottomSheet(productId) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                id: productId
            }
        })
    }

    bulkAddItem() {
        let d = this.DialogService;
        var options = {
            //  controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.wait = false
                scope.header = false
                scope.rowSeparator = 'enter'
                scope.colSeparator = 'tab'
                scope.spitData = [];
                scope.data = '';
                scope.DialogService = d;

                scope.addItem = (barcode) => {
                    scope.spitData = [];
                    barcode.split('\n').map((row, index) => {
                        if (scope.header && index === 0) {
                            return true;
                        }
                        let col = row.replace('\r', '').split('\t')

                        scope.spitData.push({
                            barcode: col[0],
                            quantity: col[1] ? col[1] : 1,
                            unitPrice: col[2] ? col[2].replace(',', '.') : null,
                            service: 'product'
                        })

                    })
                }

                scope.send = () => {
                    scope.addItem(scope.data)
                    scope.DialogService.$mdDialog.hide(scope.spitData)
                }

                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
            }
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/bulk-add-item.html',
            options
        );
        customerDialogPromise.then(resp => {
            if (resp.length > 0) {
                this.requestPurchaseProduct(resp)
            }
        })
            .catch(err => {

            })
    }

    requestPurchaseProduct(report) {
        this.API.all('purchase-request')
            .customPOST({
                items: report,
                bulk: true
            })
            .then((response) => {
                this.bulkAddItemResult(response.data.list)
            })
            .catch(function () {
            });
    }

    requestPurchaseProductOne(productId) {
        this.API.all('purchase-request')
            .customPOST({
                productId: productId,
                quantity: 1,
                service: 'product',
                serviceId: productId,
            })
            .then((response) => {
                this.ToastService.success(response.data.list.messages)
            })
            .catch(function () {
            });
    }

    bulkAddItemResult(report) {
        let d = this.DialogService;
        var options = {
            //  controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.DialogService = d
                scope.report = report
                scope.tryErrorRows = false

                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
            }
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/bulk-add-item-result.html',
            options
        );

    }


    selectProduct(product, select) {
        let exists = this.selectedProduct.filter(s => s.productId === product.productId);

        if (select && exists.length > 0) {
            this.ToastService.showHtml('warn', product.productName + ' Daha önce eklenmiş')
            return true;
        }

        product.select = select
        if (select) {
            this.selectedProduct.push(product);
        } else {
            this.selectedProduct = this.selectedProduct.filter(a => a.productId !== product.productId);
        }
        this.checkAllSelect()
    }

    checkAllSelect() {
        let productLength = this.purchaseRequests.length;
        let selectedLength = this.selectedProduct.length;

        //sonraki kontrol maliyetli olabilir öncesinde belirleyebilirsek hiç girmesin
        if (selectedLength === 0 || selectedLength < productLength) {
            this.isAllProductSelect = false;
            return true
        }

        let matchItem = 0;
        this.purchaseRequests.forEach(a => {
            this.selectedProduct.forEach(s => {
                if (s.productId === a.productId) {
                    a.select = true;
                    ++matchItem;
                }
            })
        })
        this.isAllProductSelect = matchItem === productLength

    }

    addProductsSelectedList() {
        this.purchaseRequests.filter(a => !a.select).forEach(a => {
            this.selectProduct(a, true)
        })
    }

    clearProductsSelectedList() {
        this.purchaseRequests.filter(a => a.select).forEach(a => {
            this.selectProduct(a, false)
        })
    }

    createOfferModal() {
        let d = this.DialogService;
        let parent = this;
        var options = {
            //  controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.DialogService = d
                scope.products = parent.selectedProduct
                scope.products.forEach(p => p.offerQuantity = p.quantity)
                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
                scope.remove = (productId) => {
                    scope.products = scope.products.filter(p => p.productId !== productId)
                }

                scope.clearAll = () => {
                    scope.products = []
                    scope.done()
                }
                scope.done = () => {
                    scope.DialogService.$mdDialog.hide(scope.products)
                }
            }
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/selected-purchase-product-table.html',
            options
        ).then(response => {
            if (response.length === 0) {
                parent.selectedProduct = []
                return;
            }
            parent.createOffer(response)
        });

    }

    createOffer(offerItem) {
        let parent = this;
        let product = offerItem.map(p => {
            return {
                productId: p.productId,
                quantity: p.offerQuantity,
            }
        })

        this.API.all('purchase-request/offer')
            .customPUT({
                product: product
            })
            .then((response) => {
                let d = this.DialogService;
                var options = {
                    //  controllerAs      : 'vm',
                    //  scope             : this.$scope,
                    locals: {},
                    onShowing: function (scope, element, options, controller) {
                        scope.DialogService = d
                        scope.report = response.data.list
                        scope.tryErrorRows = false

                        scope.cancel = () => {
                            scope.DialogService.$mdDialog.hide()
                        }
                    }
                };

                var customerDialogPromise = this.DialogService.fromTemplateV2(
                    './views/angular/app/components/offer/partials/dialog/bulk-add-item-result.html',
                    options
                ).then(response => {

                    parent.selectedProduct = []
                    parent.getRequest(1)
                });
            })
            .catch(function () {
            });
    }

    selectAllOptionsByModel(model) {
        if (model === 'status') {
            this.filter.status = ['open', 'reject', 'done', 'failed']
        }
    }

    deselectAllOptionsByModel(model) {
        this.filter[model] = [];
    }

    assignOffer(offerId) {
        this.API.all('purchase-request/' + this.productDetail.productId + '/assign')
            .patch(null, {
                offerId: offerId,
            })
            .then((response) => {
                if (response.response.status === 201) {
                    this.ToastService.success('Atama Başarılı oldu')
                    this.getRequestDetail(this.productDetail.productId)
                }
            })
            .catch(function () {
            });
    }

    setStatus(productId,status,requestId=null){
        this.DialogService.confirm(
            "Onay Mesajı",
            "Bu işlemi onayladığınızda geri alamazsınız. Emin misiniz ?",
        ).then(resp =>{
            let  url = 'purchase-request/' + productId + '/'+status;
            if (requestId){
                url += '/'+requestId
            }
            this.API.all(url)
                .customPUT()
                .then((response) => {
                    if (response.response.status === 201) {
                        this.ToastService.success('İşelem Başarıyla gerçekleştirildi')
                        this.getRequestDetail(this.productDetail.productId)
                    }
                })
                .catch(function () {
                });
        })
    }


    setQuantity(requestId,quantity){
        this.API.all('purchase-request/'+requestId+'/accept-quantity')
            .patch(null, {
                quantity: quantity,
            })
            .then((response) => {
                if (response.response.status === 201) {
                    this.ToastService.success('İşelem Başarıyla gerçekleştirildi')
                    this.getRequestDetail(this.productDetail.productId)
                }
            })
            .catch(function () {
            });
    }
}


PurchaseRequestController.$inject = ["$auth", "$window", "$state", "$location", "$rootScope", "$stateParams", 'API', '$mdBottomSheet', '$scope', 'DialogService', 'ToastService'];

const PurchaseRequestComponent = {
    templateUrl: './views/angular/app/components/offer/purchase-request.component.html',
    controller: PurchaseRequestController,
    controllerAs: 'vm',
    bindings: {
        selectedTabIndex: '<',
        wrapperTabIndex: '@',
    }
};


export {PurchaseRequestComponent, PurchaseRequestController}
