import {getToken,setToken, getUserSettings,me} from "../../../utils/users";

class AccountSettingsController{
    constructor($rootScope, API,$auth, ToastService,$state, $timeout, $window, UserService, $translate){
        'ngInject';

        this.$rootScope = $rootScope;
        this.API = API;
        this.$auth = $auth;
        this.ToastService = ToastService;
        this.$state = $state;
        this.$timeout = $timeout;
        this.window= $window;
        this.selectedTabIndex = 0;
        this.userSettings = null;
        this.UserService = UserService;
        this.$translate = $translate;
        this.getUserInfo = me();

        this.timezones;

        this.language = 'tr';
        this.languages;

        this.scanPriority = 'barcode';
        this.scanPriorities = [
            {"id":"barcode", "name":"Barcode Scanner"},
            {"id":"camera", "name":"Camera"},
        ];

        this.currencyDecimal = '2';
        this.currencyDecimals = [
            {"id":"1", "name":"1"},
            {"id":"2", "name":"2"},
            {"id":"3", "name":"3"},
            {"id":"4", "name":"4"},
            {"id":"5", "name":"5"},
        ];

        $rootScope.$watch('userInfo.timeZoneUTC', function(newVal, oldVal) {
            //console.log($rootScope,"<<<<");
            //this.timezone = $rootScope.userInfo.timeZoneUTC;
        });
    }

    $onInit(){
        var parent = this;

        this.API.all('user/me').doGET()
          .then(function (response) {
              parent.userSettings = response.data.user;
              parent.timezones = response.data.timezones;
              parent.timezone = response.data.user.settings.timezone;
              parent.languages = response.data.languages;
              parent.language = response.data.user.settings.language;
          });

        this.API.all('user/get-printer-settings')
          .post(null, {})
          .then((response) => {
              if (response.errors === false) {
                  if (response.data.printerSettings !== null) {
                      parent.create_printer_name = response.data.printerSettings.printer_logical_name;
                      parent.create_ip_address = response.data.printerSettings.ip_address;
                      parent.create_printer_conn_protocol = response.data.printerSettings.conn_protocol;
                      parent.create_printer_port = response.data.printerSettings.port;
                  }
              }
          });

        this.label_data = {id:0, functions:{}};
        this.label_func = {};
        this.incLabelNum = 0;

        this.selectedTabIndex = _.isEmpty(this.$state.params.selectedTabIndex) ? 0 : this.$state.params.selectedTabIndex;
    }

    updateSettings() {
        let reqData = {
            settings: {
                language: this.language,
                timezone: this.timezone,
                scanPriority: this.scanPriority,
                currencyDecimal: this.currencyDecimal,
            }
        };

        this.API.all('user/settings')
          .post(reqData)
          .then((response) => {
              if (response.errors === false) {
                  this.ToastService.success('Ayarlar değiştirildi')
                  this.UserService.getUserObject();
                  this.$translate.use(this.language);
              }
          });
    }

    updatePassword() {
        var reqData = {
            currentPassword: this.create_current_password,
            newPassword: this.create_new_password,
            repeatNewPassword: this.create_repeat_new_password
        };

        this.API.all('user/update-password')
          .post(reqData)
          .then((response) => {
            if(response.errors==false) {
                this.ToastService.success('Parolanız değiştirildi.')
            }
        });

    }

    upsertPrinterSettings() {
        var reqData = {
            printerName: this.create_printer_name,
            ipAddress: this.create_ip_address,
            printerConnProtocol: this.create_printer_conn_protocol,
            printerPort: this.create_printer_port
        };

        this.API.all('user/upsert-printer-settings')
          .post(reqData)
          .then((response) => {
              if (response.errors == false) {
                  this.ToastService.success('Yazıcı ayarlarınız tanımlandı.');
              }
          });
    }

    refreshToken() {
        this.API.all("auth/token")
            .doGET()
            .then((response) => {
                if (response.data.token) setToken(response.data.token)
                else this.ToastService.showHtml('warn','Token Yenilenemedi')
            });
    }

    printBarcode() {//barcode_data TS0407529666
        var barCodeData ="TS0407529666";// barcode_data.value;
        var barCodeSymbol = 1;
        var barCodeHeight = 100;
        var print_HRI = 1;

        var barCodeHri = 1;
        var icerik = "ALICI: EKREM BULUT SELÇUKLU MAH. 14. SOKAK INCI SITESI KAT5 D.NO21 MERKEZ / SİVAS TEL: +905455289035";
        var barWidth =8;
        var nBarWidth = 4;
        var issueID = 1;

        this.setLabelId(issueID);

        this.checkLabelStatus();
        this.clearBuffer();


        this.setWidth(576);

        this.setCharacterset(0, 12);
        this.drawDeviceFont(icerik, 0,0, "2", 1, 1, 0, 0, 0, 0);
        this.drawDeviceFont("______________________________________________________________", 0, 110, "0", 1, 1, 0, 0, 0, 0);
        this.drawDeviceFont("YURTİÇİ KARGO", 100,150, "2", 2, 2, 0, 0, 1, 0);
        /*drawTrueTypeFont(icerik, 0, 5, "0", 1, 1, 0, 0, 1, 0);*/
        this.draw1DBarcode(barCodeData, 10, 225, barCodeSymbol, nBarWidth, barWidth, barCodeHeight, 0, 1);
        this.printBuffer();

        var strSubmit = this.getLabelData();

        issueID++;
        this.requestPrint('serhan1', strSubmit);
    }

    /*printBarcode() {

        var barCodeData = 'Sample12345!@#$';
        var barCodeSymbol = 1;
        var barCodeHeight = 100;
        var barWidth = 4;//parseInt(b_width.value);
        var nBarWidth = 2; //parseInt(barWidth / 2);
        var barCodeHri = 1; //print_HRI.selectedIndex;
        var issueID = 1;
        var _inch = 3;
        var print_HRI = 1;

        this.setLabelId(issueID);

        this.checkLabelStatus();
        this.clearBuffer();
        if(_inch == 2) {
            // 2inch sample
            this.setWidth(432);
        } else if(_inch == 3) {
            // 3inch sample
            this.setWidth(576);
        } else if(_inch == 4) {
            // 4inch sample
            this.setWidth(832);
        } else {
            // error
            return;
        }
        this.setCharacterset(0,12);
        this.drawDeviceFont("doğan ılık süt öşür",0,5,"0",3,3,0,0,0,0);

        this.draw1DBarcode(barCodeData, 0, 150, barCodeSymbol, nBarWidth, barWidth, barCodeHeight, 0, 1);
        this.printBuffer();

        var strSubmit = this.getLabelData();



        issueID++;
        this.requestPrint('serhan1', strSubmit);
    }*/

    setLabelId(setId) {
        this.label_data.id = setId;
    }
    requestPrint(strPrinterName, strSubmit) {
        var serverURL = this.getPrinterFullAddres() + "/WebPrintSDK/";
        console.log(serverURL,"<<<<serverURL<<<<<")

        var requestURL = serverURL + strPrinterName;
        var xmlHttpReq = false;
        if (window.XMLHttpRequest) {
            xmlHttpReq = new XMLHttpRequest();
        }

        xmlHttpReq.open("POST", requestURL, true);
        xmlHttpReq.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded"
        );
        xmlHttpReq.send(strSubmit);
        var parent = this;
        xmlHttpReq.onreadystatechange = function () {
            if (xmlHttpReq.readyState == 4 && xmlHttpReq.status == 200) {
                var res = JSON.parse(xmlHttpReq.responseText);
                var ret = res.Result;

                if (ret.search("ready") >= 0 || ret.search("progress") >= 0) {
                    if (res.Result == "success") {
                        parent.printInfoMsgForPrinter(
                            parent.searchOrderNumberForCargoBarcode +
                            " referans nu'lu sipariş " +
                            strPrinterName +
                            " yazıcısından çıkarıldı."
                        );
                        parent.searchOrderNumberForCargoBarcode = null;
                    } else if (res.Result == "error") {
                        parent.printInfoMsgForPrinter(
                            "El terminaline bağlanılamadı.",
                            true
                        );
                    }
                    parent.checkResult(
                        "POST",
                        strPrinterName,
                        res.RequestID,
                        res.ResponseID,
                        alert
                    );
                } else if (ret.search("duplicated") >= 0) {
                    parent.printInfoMsgForPrinter("El terminaline bağlanılamadı.", true);
                }
            } else if (xmlHttpReq.readyState == 4 && xmlHttpReq.status == 404) {
                parent.printInfoMsgForPrinter(
                    strPrinterName + " yazıcısı tanımlı değildir.",
                    true
                );
            } else if (xmlHttpReq.readyState == 4) {
                parent.printInfoMsgForPrinter("El terminaline bağlanılamadı.", true);
            }
        };
    }

    checkLabelStatus() {
        this._a = { checkLabelStatus: [] };
        this.label_func["func" + this.incLabelNum] = this._a;
        this.incLabelNum++;
    }

    clearBuffer() {
        this._a = { clearBuffer: [] };
        this.label_func["func" + this.incLabelNum] = this._a;
        this.incLabelNum++;
    }

    setWidth(width) {
        this._a = { setWidth: [width] };
        this.label_func["func" + this.incLabelNum] = this._a;
        this.incLabelNum++;
    }

    setCharacterset(ics, charset) {
        this._a = { setCharacterset: [ics, charset] };
        this.label_func["func" + this.incLabelNum] = this._a;
        this.incLabelNum++;
    }

    drawDeviceFont(
        text,
        x,
        y,
        fontType,
        widthEnlarge,
        heightEnlarge,
        rotation,
        invert,
        bold,
        alignment
    ) {
        var _a = {
            drawDeviceFont: [
                text,
                x,
                y,
                fontType,
                widthEnlarge,
                heightEnlarge,
                rotation,
                invert,
                bold,
                alignment,
            ],
        };
        this.label_func["func" + this.incLabelNum] = _a;
        this.incLabelNum++;
    }

    draw1DBarcode(data, x, y, symbol, narrowbar, widebar, height, rotation, hri) {
        var _a = {
            draw1DBarcode: [
                data,
                x,
                y,
                symbol,
                narrowbar,
                widebar,
                height,
                rotation,
                hri,
            ],
        };
        this.label_func["func" + this.incLabelNum] = _a;
        this.incLabelNum++;
    }

    printBuffer() {
        switch (arguments.length) {
            case 0:
                var _a = { printBuffer: [] };
                this.label_func["func" + this.incLabelNum] = _a;
                this.incLabelNum++;
                break;
            case 1:
                var _a = { printBuffer: [arguments[0]] };
                this.label_func["func" + this.incLabelNum] = _a;
                this.incLabelNum++;
                break;
        }
    }

    getLabelData() {
        this.label_data.functions = this.label_func;
        this.label_func = {};
        this.incLabelNum = 0;

        return JSON.stringify(this.label_data);
    }

    getPrinterFullAddres() {
        var fullUri = "";

        if (this.printer_conn_protocol == "1") {
            fullUri = "http://";
        } else {
            fullUri = "ws://";
        }

        fullUri += this.printer_ip_address + ":" + this.printer_port;

        return fullUri;
    }

    checkResult(method, strPrinterName, requestId, responseId, _callback) {
        var serverURL = this.getPrinterFullAddres() + "/WebPrintSDK/";
        var requestURL = serverURL + strPrinterName + "/checkStatus";
        var inquiryData = this.makeResultInquiryData(requestId, responseId, 30);

        var xmlHttpCheck = false;
        if (window.XMLHttpRequest) {
            xmlHttpCheck = new XMLHttpRequest();
        }

        xmlHttpCheck.open(method, requestURL, true);
        xmlHttpCheck.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded"
        );
        xmlHttpCheck.send(inquiryData);
        var parent = this;
        xmlHttpCheck.onreadystatechange = function () {
            if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 200) {
                var res = JSON.parse(xmlHttpCheck.responseText);
                var ret = res.Result;

                if (ret.search("ready") >= 0 || ret.search("progress") >= 0) {
                    parent.checkResult(
                        method,
                        strPrinterName,
                        requestId,
                        responseId,
                        _callback
                    );
                } else {
                    if (res.Result == "success") {
                        parent.printInfoMsgForPrinter(
                            parent.searchOrderNumberForCargoBarcode +
                            " referans nu'lu sipariş " +
                            strPrinterName +
                            " yazıcısından çıkarıldı."
                        );
                        parent.searchOrderNumberForCargoBarcode = null;
                    } else if (res.Result == "error") {
                        parent.printInfoMsgForPrinter(
                            "El terminaline bağlanılamadı.",
                            true
                        );
                    }
                }
            } else if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 404) {
                parent.printInfoMsgForPrinter(
                    strPrinterName + " yazıcısı tanımlı değildir."
                );
            } else if (xmlHttpCheck.readyState == 4) {
                parent.printInfoMsgForPrinter("El terminaline bağlanılamadı.");
            }
        };
    }

    printInfoMsgForPrinter(message, error = false) {
        this.infoMessageForQuickPrinter = true;
        this.message = message;

        if (error == true) {
            this.notifyClass = "alert alert-danger";
        } else {
            this.notifyClass = "alert alert-success";
        }
    }

    makeResultInquiryData(requestId, responseId, timeout) {
        return (
            '{"RequestID":' +
            requestId +
            ',"ResponseID":"' +
            responseId +
            '","Timeout":' +
            timeout +
            "}"
        );
    }


}
AccountSettingsController.$inject = ["$rootScope", "API","$auth", "ToastService","$state", "$timeout", "$window","UserService","$translate"];
export const AccountSettingsComponent = {
    templateUrl: './views/angular/app/components/account-settings/account-settings.component.html',
    controller: AccountSettingsController,
    controllerAs: 'vm',
    bindings: {}
};
