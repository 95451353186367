import {ToastService} from "./toast.service";

class PstorageService {
  constructor($window, $state) {
    'ngInject';

    this.$window = $window;
    this.$state = $state;
  }

  $onInit() {
    this.downloadableNotification = []
    this.notifications = [];
    this.notificationsUnviewed = 0;
    this.notificationsSettings = {
      view: {
        public: true,
        company: false,
        personal: true
      }
    };

    this.loadNotifications();
  }

  getNotificationsSettings() {
    return this.notificationsSettings;
  }

  loadNotifications() {
    let notifications = angular.fromJson(localStorage.getItem('notifications'));
    if (notifications !== null) {
      this.notifications = notifications;
    } else {
      this.$window.localStorage.setItem("notifications", angular.toJson(this.notifications));
    }

    let downloadableNotification = angular.fromJson(localStorage.getItem('downloadableNotification'));
    if (downloadableNotification !== null) {
      this.downloadableNotification = downloadableNotification;
    } else {
      this.$window.localStorage.setItem("downloadableNotification", angular.toJson(this.downloadableNotification));
    }

    let notificationsUnviewed = angular.fromJson(localStorage.getItem('notificationsUnviewed'));
    if (notificationsUnviewed !== null) {
      this.notificationsUnviewed = notificationsUnviewed;
    } else {
      this.$window.localStorage.setItem("notificationsUnviewed", this.notificationsUnviewed);
    }

    this.setStateNotifications();


    let notificationsSettings = angular.fromJson(localStorage.getItem('notificationsSettings'));
    if (notificationsSettings !== null) {
      this.notificationsSettings = notificationsSettings;
    } else {
      this.$window.localStorage.setItem("notificationsSettings", angular.toJson(this.notificationsSettings));
    }

    this.$state.notificationsSettings = this.notificationsSettings;
  }

  setStateNotifications() {
    this.$state.notifications = this.notifications;
    this.$state.notificationsUnviewed = this.notificationsUnviewed;
    this.$state.downloadableNotification = this.downloadableNotification;
  }

  getNotifications() {
    this.notifications = angular.fromJson(localStorage.getItem("notifications"));
    this.notificationsUnviewed = angular.fromJson(localStorage.getItem("notificationsUnviewed"));

    this.setStateNotifications();
  }

  addNotification(notification) {
    this.notificationsUnviewed++;
    if (!notification.download) {
      if (this.notifications && this.notifications.length > 0) {
        this.notifications.unshift(notification);
      } else {
        this.notifications.push(notification);
      }
      this.clearNotifications('old');
      this.updateNotifications();
    } else {

      if (this.downloadableNotification && this.downloadableNotification.length > 0) {
        this.downloadableNotification.unshift(notification);
      } else {
        this.downloadableNotification.push(notification);
      }

      this.updateDownloadableNotifications();

    }

  }

  updateNotifications() {
    if (this.$window.localStorage) {
      this.$window.localStorage.setItem("notifications", angular.toJson(this.notifications));
      this.$window.localStorage.setItem("notificationsUnviewed", this.notificationsUnviewed);
      this.$state.notifications = this.notifications
      this.$state.notificationsUnviewed = this.notificationsUnviewed
    }
  }
  updateDownloadableNotifications() {
    if (this.$window.localStorage) {
      this.$window.localStorage.setItem("downloadableNotification", angular.toJson(this.downloadableNotification));
      this.$state.downloadableNotification = this.downloadableNotification
    }
  }

  viewedNotifications() {
    this.notificationsUnviewed = 0;
    this.updateNotifications();
  }

  clearNotifications(type='all') {
    if (type === 'all') {
      this.notifications = [];
      this.notificationsUnviewed = 0;
    } else {
      var now = new Date();
      var yesterday = new Date(now.getTime() - 24*60*1000);
      this.notifications = this.notifications.filter(notification => notification.time > yesterday.getTime());
    }
    this.updateNotifications();
  }

  updateNotificationsSettings(notificationsSettings) {
    this.notificationsSettings = notificationsSettings;
    if (this.$window.localStorage) {
      this.$window.localStorage.setItem("notificationsSettings", angular.toJson(this.notificationsSettings));
    }
    this.$state.notificationsSettings = this.notificationsSettings;
  }
}

PstorageService.$inject = ["$window", "$state"];
export {PstorageService};
