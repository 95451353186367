import { openIntroductionVideo } from "../../../utils/introduction-videos";
import {OrderListsController} from "../order-lists/order-lists.component";
import {getUserInfo, me} from "../../../utils/users";

class CustomersController {
  constructor(API, ToastService, DialogService, $auth, $state, $location,$rootScope,$scope,$controller) {
    "ngInject";

    this.API = API;
    this.ToastService = ToastService;
    this.DialogService = DialogService;
    this.$auth = $auth;
    this.$state = $state;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$controller = $controller;
    this.dialog = false
    //this.term = null;
    this.emptySearchResult = false;
    this.customers = [];
    this.balance = false
    this.userInfo = getUserInfo();
    this.userMe = me();
    this.userSettings = this.userInfo.settings;
    this.defaultChannelProvider = null
    this.showAddress = [];
    this.selectedDeliveryAddress =null;
    this.selectedInvoiceAddress =null;
    this.selectedChannelProvider =null;
  }

  $onInit() {
    this.per_page = 10;
    this.filterArr = {};
    this.editCustomers = {};
    this.disabled = [];
    this.pageNumber = 1;
    this.nameSearchInput = null;
    this.emailSearchInput = null;
    this.statusSearchInput = null;
    this.edit_delivery_address_id = null;
    this.edit_invoice_address_id = null;

    if (this.selectedChannelProvider){
      this.defaultChannelProvider = this.userMe.defaultChannelProviders.filter(p=>p.provider===this.selectedChannelProvider)[0]
    }

    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
    this.getLastTabIndex = function () {
      document.documentElement.scrollTop = 0;
      return document.querySelectorAll("#tabs .md-tab").length;
    };

    this.API.all("customer/getCode")
      .doGET()
      .then((response) => {
        this.create_ts_customer_code = response.data.customer_code;
      });
    this.getCities();

    // this.getCustomers();
  }

  onSelectTab(obj){
    this.$location.search(obj);
    this.video = undefined;
    if (obj.tab == 0) {
      this.video = 'customersList';
    }
    if (obj.tab == 1) {
      this.video = 'customersList';
    }
  }


  getCities() {
    this.API.all("customer/getcity")
      .doGET()
      .then((response) => {
        this.cities = response.data.cities;
      });
  }

  clearCreateForm() {
    this.create_ts_customer_code = null;
    this.create_ts_customer_username = null;
    this.create_ts_customer_name = null;
    this.create_ts_customer_phone = null;
    this.create_ts_invoice_tax_dep = null;
    this.create_ts_invoice_tax_no = null;
    this.create_ts_invoice_name = null;
    this.create_ts_invoice_mobile = null;
    this.create_ts_invoice_address = null;
    this.create_ts_invoice_town = null;
    this.create_ts_invoice_neighbourhood = null;
    this.create_ts_invoice_company = null;
    this.create_delivery_city_id = null;
    this.create_invoice_city_id = null;
    this.create_ts_invoice_zip_code = null;
    this.create_ts_delivery_name = null;
    this.create_ts_delivery_mobile = null;
    this.create_ts_delivery_address = null;
    this.create_ts_delivery_town = null;
    this.create_ts_delivery_neighbourhood = null;
    this.create_ts_delivery_city_name = null;
    this.create_ts_delivery_zip_code = null;
    this.create_invoice_type = null;
    this.create_identity_number = null;
  }

  clearSearchForm() {
    this.term = null;
    this.search_ts_customer_id = null;
    this.search_ts_customer_username = null;
    this.search_ts_customer_name = null;
    this.search_ts_customer_full = null;

 //  this.getCustomers(1, []);
  }

  createCustomer() {
    if (this.sameAddress) {
      var reqData = {
        ts_customer_code: this.create_ts_customer_code,
        ts_customer_username: this.create_ts_customer_username,
        ts_customer_name: this.create_ts_customer_name,
        ts_customer_phone: this.create_ts_customer_phone,
        ts_invoice_tax_dep: this.create_ts_invoice_tax_dep,
        ts_invoice_tax_no: this.create_ts_invoice_tax_no,
        ts_invoice_name: this.create_ts_invoice_name,
        ts_invoice_mobile: this.create_ts_invoice_mobile,
        ts_invoice_address: this.create_ts_invoice_address,
        ts_invoice_town: this.create_ts_invoice_town,
        ts_invoice_neighbourhood: this.create_ts_invoice_neighbourhood,
        ts_invoice_company: this.create_ts_invoice_company,
        delivery_city_id: this.create_invoice_city_id,
        invoice_city_id: this.create_invoice_city_id,
        ts_invoice_zip_code: this.create_ts_invoice_zip_code,
        ts_delivery_name: this.create_ts_invoice_name,
        ts_delivery_mobile: this.create_ts_invoice_mobile,
        ts_delivery_address: this.create_ts_invoice_address,
        ts_delivery_town: this.create_ts_invoice_town,
        ts_delivery_neighbourhood: this.create_ts_invoice_neighbourhood,
        ts_delivery_city_name: this.create_ts_delivery_city_name,
        ts_delivery_zip_code: this.create_ts_invoice_zip_code,
        invoice_type: this.create_invoice_type,
        identity_number: this.create_identity_number,
      };
    } else {
      var reqData = {
        ts_customer_code: this.create_ts_customer_code,
        ts_customer_username: this.create_ts_customer_username,
        ts_customer_name: this.create_ts_customer_name,
        ts_customer_phone: this.create_ts_customer_phone,
        ts_invoice_tax_dep: this.create_ts_invoice_tax_dep,
        ts_invoice_tax_no: this.create_ts_invoice_tax_no,
        ts_invoice_name: this.create_ts_invoice_name,
        ts_invoice_mobile: this.create_ts_invoice_mobile,
        ts_invoice_address: this.create_ts_invoice_address,
        ts_invoice_town: this.create_ts_invoice_town,
        ts_invoice_neighbourhood: this.create_ts_invoice_neighbourhood,
        ts_invoice_company: this.create_ts_invoice_company,
        delivery_city_id: this.create_delivery_city_id,
        invoice_city_id: this.create_invoice_city_id,
        ts_invoice_zip_code: this.create_ts_invoice_zip_code,
        ts_delivery_name: this.create_ts_delivery_name,
        ts_delivery_mobile: this.create_ts_delivery_mobile,
        ts_delivery_address: this.create_ts_delivery_address,
        ts_delivery_town: this.create_ts_delivery_town,
        ts_delivery_neighbourhood: this.create_ts_delivery_neighbourhood,
        ts_delivery_city_name: this.create_ts_delivery_city_name,
        ts_delivery_zip_code: this.create_ts_delivery_zip_code,
        invoice_type: this.create_invoice_type,
        identity_number: this.create_identity_number,
      };
    }

    this.API.all("customer/save")
      .post(reqData)
      .then((response) => {
        if (response.error) {
          this.ToastService.error("Cari hesap oluştururken bir hata oluştu.");
        } else {
          this.ToastService.success("Cari hesap oluşturuldu.");
          this.clearCreateForm();
        }
      });
  }

  /**
   * @deprecated elasticsearc sorgusu yapıldı
   * @param pageNumber
   * @param filterArr
   * @param per_page
   */
  getCustomers(
    pageNumber = this.pageNumber,
    filterArr = this.filterArr,
    per_page = this.per_page
  ) {
    var parent = this;

    this.per_page = per_page;
    this.pageNumber = pageNumber;

    let customGet = {
      per_page: per_page,
      page: this.pageNumber,
    };

    var reqObject = angular.extend(customGet, filterArr);

    reqObject.per_page = this.per_page;

    this.API.all("customer")
      .customGET("list", reqObject)
      .then((response) => {
        parent.customers = response.data.customers;
        parent.total = response.data.customers.total;
        parent.current_page = response.data.customers.current_page;
        parent.per_page = response.data.customers.per_page;
        parent.last_page = response.data.customers.last_page;
        parent.prev_page_url = response.data.customers.prev_page_url;
        parent.next_page_url = response.data.customers.next_page_url;

        parent.pagesArray = response.data.customerPagesArray;

        parent.prev_index = response.data.prevIndex;
        parent.next_index = response.data.nextIndex;
      });
  }

  getCustomersV2() {
    this.emptySearchResult = false;
    this.API.all("search")
        .customGET(null,{
          type:'customer',
          barcode:this.term,
          accountingProvider:this.defaultChannelProvider?.accountingName,
        })
        .then((response) => {
          this.total = 20;
          this.current_page = 1;
          this.per_page = 20;
          this.last_page = 1;


          this.pagesArray = [];

          this.prev_index = 0;
          this.next_index = 1;

          this.matchType = response.data.matchType
          this.customers = response.data?.list

          if (!this.customers){
            this.customers = []
            this.emptySearchResult = true;
          }
        });
  }

  /**
   * @deprecated Filter isminden sebep kullınılıuyor gibi gösteriyor aslında kullanılmıyor
   */
  filter() {
    let filter = {
      per_page: this.per_page,
      pageNumber: 1,
      ts_customer_id: this.search_ts_customer_id,
      ts_customer_username: this.search_ts_customer_username,
      ts_customer_name: this.search_ts_customer_name,
      ts_customer_full: this.search_ts_customer_full,
    };

    var parent = this;

    this.filterArr = filter;
    this.API.all("customer/filter")
      .post(filter)
      .then((response) => {
        parent.customers = response.data.customers;
        parent.total = response.data.customers.total;
        parent.current_page = response.data.customers.current_page;
        parent.per_page = response.data.customers.per_page;
        parent.last_page = response.data.customers.last_page;
        parent.prev_page_url = response.data.customers.prev_page_url;
        parent.next_page_url = response.data.customers.next_page_url;

        parent.pagesArray = response.data.customerPagesArray;

        parent.prev_index = response.data.prevIndex;
        parent.next_index = response.data.nextIndex;
      });
  }

  editScreen(customer_id) {
    let reqObject = {
      customer_id: customer_id,
    };

    var parent = this;
    this.disabled[customer_id] = true;

    this.API.all("customer/getById")
      .post(reqObject)
      .then((response) => {
        parent.editCustomersObject = [
          {
            title: response.data.customers.ts_customer_name,
            editCustomerObject: response.data.customers,
            editDeliveryAddressObject: response.data.deliveryAddress,
            editInvoiceAddressObject: response.data.invoiceAddress,
          },
        ];
        parent.disabled[customer_id] = false;
        this.selectedTabIndex = this.getLastTabIndex();
      });
  }

  removeTab() {
    this.editCustomersObject = {};
  }

  delete(customer_id) {
    if (customer_id < 20){
      this.ToastService.error("Bu müşteriyi silemezsiniz veya güncelleyemezsiniz.");
      return;
    }
    this.DialogService.confirm(
      "Onay Mesajı",
      "Bu müşteriyi silmek istediğinize emin misiniz?"
    ).then(
      () => {
        let reqObject = {
          per_page: this.per_page,
          page: 1,
          customer_id: customer_id,
        };

        var parent = this;

        this.API.all("customer/delete")
          .post(reqObject)
          .then((response) => {
            parent.customers = response.data.customers;
            parent.total = response.data.customers.total;
            parent.current_page = response.data.customers.current_page;
            parent.per_page = response.data.customers.per_page;
            parent.last_page = response.data.customers.last_page;
            parent.prev_page_url = response.data.customers.prev_page_url;
            parent.next_page_url = response.data.customers.next_page_url;

            parent.pagesArray = response.data.customerPagesArray;

            parent.prev_index = response.data.prevIndex;
            parent.next_index = response.data.nextIndex;

            this.ToastService.success("Cari hesap silindi.");
          });
      },
      () => {}
    );
  }

  editCustomer() {
    if (this.sameAddressEdit) {
      var reqData = {
        customer_id: this.edit_customer_id,
        delivery_address_id: this.edit_delivery_address_id,
        invoice_address_id: this.edit_invoice_address_id,
        ts_customer_code: this.edit_ts_customer_code,
        ts_customer_username: this.edit_ts_customer_username,
        ts_customer_name: this.edit_ts_customer_name,
        ts_customer_phone: this.edit_ts_customer_phone,
        ts_invoice_tax_dep: this.edit_ts_invoice_tax_dep,
        ts_invoice_tax_no: this.edit_ts_invoice_tax_no,
        ts_invoice_name: this.edit_ts_invoice_name,
        ts_invoice_mobile: this.edit_ts_invoice_mobile,
        ts_invoice_address: this.edit_ts_invoice_address,
        ts_invoice_town: this.edit_ts_invoice_town,
        ts_invoice_neighbourhood: this.edit_ts_invoice_neighbourhood,
        ts_invoice_company: this.edit_ts_invoice_company,
        delivery_city_id: this.edit_invoice_city_id,
        invoice_city_id: this.edit_invoice_city_id,
        ts_invoice_zip_code: this.edit_ts_invoice_zip_code,
        ts_delivery_name: this.edit_ts_invoice_name,
        ts_delivery_mobile: this.edit_ts_invoice_mobile,
        ts_delivery_address: this.edit_ts_invoice_address,
        ts_delivery_town: this.edit_ts_invoice_town,
        ts_delivery_neighbourhood: this.edit_ts_invoice_neighbourhood,
        ts_delivery_city_name: this.edit_ts_delivery_city_name,
        ts_delivery_zip_code: this.edit_ts_invoice_zip_code,
        invoice_type: this.edit_invoice_type,
        identity_number: this.edit_identity_number,
      };
    } else {
      var reqData = {
        customer_id: this.edit_customer_id,
        delivery_address_id: this.edit_delivery_address_id,
        invoice_address_id: this.edit_invoice_address_id,
        ts_customer_code: this.edit_ts_customer_code,
        ts_customer_username: this.edit_ts_customer_username,
        ts_customer_name: this.edit_ts_customer_name,
        ts_customer_phone: this.edit_ts_customer_phone,
        ts_invoice_tax_dep: this.edit_ts_invoice_tax_dep,
        ts_invoice_tax_no: this.edit_ts_invoice_tax_no,
        ts_invoice_name: this.edit_ts_invoice_name,
        ts_invoice_mobile: this.edit_ts_invoice_mobile,
        ts_invoice_address: this.edit_ts_invoice_address,
        ts_invoice_town: this.edit_ts_invoice_town,
        ts_invoice_neighbourhood: this.edit_ts_invoice_neighbourhood,
        ts_invoice_company: this.edit_ts_invoice_company,
        delivery_city_id: this.edit_delivery_city_id,
        invoice_city_id: this.edit_invoice_city_id,
        ts_invoice_zip_code: this.edit_ts_invoice_zip_code,
        ts_delivery_name: this.edit_ts_delivery_name,
        ts_delivery_mobile: this.edit_ts_delivery_mobile,
        ts_delivery_address: this.edit_ts_delivery_address,
        ts_delivery_town: this.edit_ts_delivery_town,
        ts_delivery_neighbourhood: this.edit_ts_delivery_neighbourhood,
        ts_delivery_city_name: this.edit_ts_delivery_city_name,
        ts_delivery_zip_code: this.edit_ts_delivery_zip_code,
        invoice_type: this.edit_invoice_type,
        identity_number: this.edit_identity_number,
      };
    }

    this.API.all("customer/update")
      .post(reqData)
      .then((response) => {
        if (response.error) {
          this.ToastService.error("Cari hesap güncellenirken bir hata oluştu.");
        } else {
          this.ToastService.success("Cari hesap güncellendi.");
        }
      });
  }

  openIntroductionVideoModal(video) {
    openIntroductionVideo(video);
  }


  showActions(customerCode){
    var options = {
      controller        : 'OrderListsController',
      controllerAs      : 'vm',
    //  scope             : this.$scope,
      locals            : {
        searchValue     : customerCode,
        searchFieldId   : 1,
        per_page        : 5,
      },
      bindToController  : true,
      multiple          : true,
      customerCode      : customerCode,
      onShowing         : function (scope, element, options, controller){
        controller.getOrderListV2(1)
      },
    };

    var customerDialogPromise = this.DialogService.fromTemplateV2(
        './views/angular/app/components/order-lists/partials/action-list.component.html',
        options
    );
    customerDialogPromise.catch(err=>{
      console.log(err)
    })
  //  this.$scope.$emit('GetList',{searchValue:'T18',searchFieldId:1})

  }

  customerSelect(customerCode, customerName, customerId, deliveryAddress, invoiceAddress) {

    this.DialogService.$mdDialog.hide({
      customerCode: customerCode,
      customerName: customerName,
      customerId: customerId,
      deliveryAddress: deliveryAddress,
      invoiceAddress: invoiceAddress
    })
  }



  bulkAddCustomers()
  {
    this.DialogService.alert("Toplu Ekleme", "Toplu müşteri eklemek için lütfen entegrasyon imkanlarını deneyiniz");
  }

  newCustomer(){
    this.$state.go('app.customers', { tab:0 });
    this.DialogService.$mdDialog.hide();
  }

  getAddress(customerId){
    this.API.all(`customer/${customerId}/address`)
        .customGET()
        .then((response) => {
          if (!this.showAddress[customerId]){
            this.showAddress[customerId] = {}
          }
          this.showAddress[customerId]['show'] = true;
          this.showAddress[customerId]['invoiceAddress'] = response.data.list.invoiceAddress
          this.showAddress[customerId]['deliveryAddress'] = response.data.list.deliveryAddress

        });
  }
}
CustomersController.$inject = ["API", "ToastService", "DialogService", "$auth", "$state", "$location", "$rootScope","$scope","$controller"];
const CustomersComponent = {
  templateUrl: "./views/angular/app/components/customers/customers.component.html",
  controller: CustomersController,
  controllerAs: "vm",
  bindings: {},
};

export {CustomersComponent,CustomersController}
