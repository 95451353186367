import "./issue.scss";
import angular from "angular";
class IssuesListController {
  constructor(
    API,
    ToastService,
    uiGridConstants,
    $timeout,
    i18nService,
    $auth,
    $state,
    DialogService,
    UserService,
    $stateParams,
    $mdMenu,
    $rootScope,
    $filter,
    $q,
    $location,
    $scope,
    $window,
    $mdBottomSheet,
    TabActionService
  ) {
    "ngInject";
    this.$state = $state;
    this.UserService = UserService;
    this.API = API;
    this.$auth = $auth;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.TabActionService = TabActionService;
    this.$scope = $scope;
    this.window = $window;
    this.$mdBottomSheet = $mdBottomSheet;
    this.$location = $location;
    this.ToastService = ToastService;
    this.DialogService = DialogService;

    this.prepareListPage = 1;
    this.prepareListLimit = 20
    this.prepareListLimitEndDate = new Date()
    this.prepareListLimitStartDate = new Date((new Date()).setDate((new Date()).getDate() - 5))
    this.prepareListName = null;
    this.marketPlaceList = null;
    this.createdByList = null;
    this.preparedByList = null;
    this.lastStatusList = null;

    this.issuesDetailPage = false;
    this.selectedTabIndex = 0;
    this.listPriorityColor  = {
      1:'new-action-list-normal',
      2:'new-action-list-high',
      3:'new-action-list-very-high',
      4:'new-action-list-urgent',
    }
    this.onlyAnomalyStatus =null;
    this.ownerUsers = this.UserService.getAllUsers();
  }


  $onInit() {
    this.getFilterInventory().then(()=>{
      this.getActionPrepareList(this.prepareListPage);
    });
  }


  showProductBottomSheet(productId) {
    this.$mdBottomSheet.show({
      templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
      controller: "BottomViewController",
      controllerAs: "vm",
      bindToController: true,
      locals: {
        id: productId
      }
    })
  }

  getActionPrepareList(page) {
    this.API.all("action-process/assignList")
      .doGET(null, {
        startDate         : !this.prepareListName ? this.prepareListLimitStartDate : null,
        endDate           : !this.prepareListName ? this.prepareListLimitEndDate : null,
        page              : page,
        limit             : this.prepareListLimit,
        name              : this.prepareListName,
        onlyAnomalyStatus : this.onlyAnomalyStatus,
        marketPlaceList   : this.marketPlaceList && this.marketPlaceList.join('|'),
        createdByList     : this.createdByList && this.createdByList.join('|'),
        preparedByList    : this.preparedByList && this.preparedByList.join('|'),
        lastStatusList    : this.lastStatusList && this.lastStatusList.join('|'),
        listType          : this.listTypes && this.listTypes.join('|'),
        listStatus        : this.listStatuses && this.listStatuses.join('|'),
      })
      .then((response) => {
        if (response.response.status ===204){
          this.prepareList = null;
          this.limit = 0
          this.total = 0
          return;
        }
        this.prepareList = response.data.list
        this.page = this.prepareList.page;

        this.limit = this.prepareList.limit
        this.total = this.prepareList.total
        this.pageList = [];
        let max = Math.ceil(this.prepareList.total / this.prepareList.limit)
        max = max ===0 ? 1 : max;
        for (let p=1; p <=max;++p){
          this.pageList.push(p)
        }
      });
  }
  selectAllOptionsByModel(model) {
    if (model === 'marketPlaceList') {
        this.marketPlaceList = this.actionFilterInventory.marketPlaceList.data.map(d=>d)
    }
    if (model === 'createdByList') {
        this.createdByList = this.actionFilterInventory.createdByList.data.map(d=>d.createdBy)
    }
    if (model === 'preparedByList') {
        this.preparedByList = this.actionFilterInventory.preparedByList.data.map(d=>d.prepareUserId)
    }
    if (model === 'lastStatusList') {
        this.lastStatusList = this.actionFilterInventory.lastStatusList.data.map(d=>d.status)
    }
    if (model === 'listTypes') {
        this.listTypes = this.actionFilterInventory.listType.data.map(d=>d.type)
    }
    if (model === 'listStatuses') {
        this.listStatuses = this.actionFilterInventory.listStatus.data.map(d=>d.status)
    }
  }
  deselectAllOptionsByModel(model) {
    this[model] = [];
  }
  getFilterInventory() {
    return  this.API.all("action-process/inventory")
        .customGET(null, {filter: 1})
        .then((response) => {
          this.actionFilterInventory = response.data.list

          this.listStatuses = this.actionFilterInventory.listStatus.data.filter(d=>d.status !== 9).map(d=>d.status)
        });
  }

  goIssueDetail(issue) {
    this.$rootScope.$broadcast('issueChildEvent', {listId:issue.listId,tab: 1,process:'goDetail',screen:'issueDetail'})

  }
  goIssueCreate() {
    this.$rootScope.$broadcast('issueChildEvent', {tab: 2,process:'goDetail',screen:'createIssue'})
  }

  clearFilter() {
    this.startDate          = this.prepareListLimitStartDate;
    this.endDate            = this.prepareListLimitEndDate
    this.prepareListPage    = 1;
    this.limit              = this.prepareListLimit;
    this.name               = this.prepareListName;
    this.marketPlaceList    = [];
    this.createdByList      = [];
    this.preparedByList     = [];
    this.lastStatusList     = []
    this.listType           = [];
    this.listStatus         = this.actionFilterInventory.listStatus.data.filter(d => d.status !== 9).map(d => d.status);
    this.onlyAnomalyStatus  = null;
  }
}

IssuesListController.$inject = ["API", "ToastService", "uiGridConstants", "$timeout", "i18nService", "$auth", "$state", "DialogService", "UserService", "$stateParams", "$mdMenu", "$rootScope", "$filter", "$q", "$location","$scope","$window","$mdBottomSheet","TabActionService"];
const IssuesListComponent = {
  templateUrl: "./views/angular/app/components/issues/partials/issues-list.html",
  controller: IssuesListController,
  controllerAs: "vm",
  bindings: {},
};
export {IssuesListController, IssuesListComponent}
