import "./issue.scss";
import {TabActionService} from "../../../services/tabAction.service";

class IssuesDetailController {
    constructor(API,$state,$mdBottomSheet,ToastService,$location,$rootScope,TabActionService,$scope,DialogService,UserService) {
        'ngInject';

        this.API = API;
        this.$state = $state;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$location = $location;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.ToastService = ToastService;
        this.TabActionService = TabActionService;
        this.DialogService = DialogService;
        this.UserService = UserService;

        this.listId             = null;
        this.list               = null;
        this.listPriorityColor  = {
            1:'new-action-list-normal',
            2:'new-action-list-high',
            3:'new-action-list-very-high',
            4:'new-action-list-urgent',
        }
        this.ownerUsers = this.UserService.getAllUsers();

    }
    $onInit() {
        let parent = this;
        parent.$scope.$on('issueEvent', function (event, data) {
            if ('issueDetail' === data.screen){
                parent.issueScreen = data.screen
                parent.listId = data?.listId
                if (parent.listId){
                    parent.getList()

                }
            }
        })

    }

    getList() {
        this.API.all('action-process/assignList/' + this.listId)
            .doGET(null,{
                list:1
            })
            .then((response) => {
                this.list = response.data.list
                this.list.items.map(item=>{
                    item.statistic = this.list.statistic?.actions.filter(s => s.actionId === item.actionId)[0]

                })

            });
    }

    deleteActionList(actionId){
        this.API.all("action-process/assignList/"+this.listId)
            .customDELETE(null,{
                actionId:actionId
            })
            .then((response) => {
                let result = response.data.list;
                if (response.response.status === 207){

                    let html = '';
                    result?.messages.map(message=>{
                        html += '<li>'+message+'</li>'
                    })
                    this.ToastService.showHtml('success','<ul style="list-style-type: none;">'+html+'</ul>');

                    if (!result.prepareList){
                        this.getList()
                        return;
                    }
                    this.TabActionService.hide();
                    this.TabActionService.onSelectTab({tab:2});
                }
            })
    }

    showCreateListModal(){

        const tabDetail = {
            picking:{tab:2,screen:'createIssue'},
            shelfUp:{tab:3,screen:'createReturnIssue'},
            faulty:{tab:4,screen:'createFaultyIssue'},
        }
        let selectedTab = tabDetail[this.list.listType]
        this.$rootScope.$broadcast('issueChildEvent', {...selectedTab, listId: this.listId, process: 'addListItem'})


    }

    goProduct(productId){

    }


    showOrderDetail(id) {
        let options = {
            controller: 'InvoicesController',
            controllerAs: 'vm',
            locals: {
                orderId: id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getDetailOrderId(controller.orderId)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/invoices/partials/invoices-detail.component.html',
            options
        )
    }
    showActionDetail(action_id) {
        this.selectedDetailActionId = action_id;
        let options = {
            controller: 'ActionDetailController',
            controllerAs: 'vm',
            locals: {
                id: action_id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getDetail(controller.id)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/order-lists/partials/action-detail.component.html',
            options
        )
    }

    downloadProcessDocument(document, processId) {
        this.API.all(`action-process/document/${processId}`)
            .customGET(null,{
                document:document
            })
            .then((response) => {
                const printWindow = window.open('', '_blank');
                printWindow.document.write(response.data.document);
                printWindow.document.close();
                printWindow.onload = function() {
                    printWindow.print();
                    printWindow.close();
                };
            })
    }

    changePreparedUser(listId) {
        this.API.all(`action-process/assignPrepare/${listId}/assign`)
          .customPUT({
              preparedUserId: this.preparedUserId
          })
          .then((response) => {
              if (response.response.status === 200) {
                  this.ToastService.showHtml('success', response.data.message)
              }
          });
    }

}

IssuesDetailController.$inject = ['API','$state','$mdBottomSheet','ToastService', "$location","$rootScope","TabActionService","$scope","DialogService","UserService"];

const IssuesDetailComponent = {
    templateUrl: "./views/angular/app/components/issues/partials/issues-detail.html",
    controller: IssuesDetailController,
    controllerAs: "vm",
    bindings: {
        listId :'<',
    },
};

export {IssuesDetailController, IssuesDetailComponent}
