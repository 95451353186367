var MenuTr = {
  SETTINGS: "Ayarlar",
  HELP: "Yardım",
  QUICK_HELP: "Hızlı Yardım",
  SUPPORT: "Destek",
  DARK_MODE: "Gece Modu",
  MY_SHORTCUT: "Kısayollarım"
};

var accountSettingsTr = {
  PERSONEL_INFORMATION: "Kişisel Bilgiler",
  TURKISH: "Türkçe",
  ENGLISH: "English",
  CURRENT_PASSWORD: "Mevcut Şifreniz",
  NEW_PASSWORD: "Yeni Şifre",
  REPEAT_NEW_PASSWORD: "Yeni Şifre Tekrar",
  UPDATE: "Güncelle",
  CHANGE_PASSWORD: "Parola Değiştir",
  LOCALIZATION_SETTINGS: "Bölgesel Ayarlar",
  SCAN_PRIORITY: "Tarama Önceliği",
  PRINTER_SETTINGS: "Yazıcı Ayarları",
  PRINTER_NAME: "Yazıcı Adı",
  PRINTER_CONNECTION_PROTOCOL: "Yazıcı Bağlantı Protokolü",
  PRINTER_IP_ADDRESS: "Yazıcı IP Adresi",
  PRINTER_PORT_ADDRESS: "Yazıcı Bağlantı Portu",
  PRINT_TEST_PAGE: "Sınama sayfası yazdır",
  FIRM_LOGO: "Bu bölümden firma logonuzu güncelleyebilirsiniz.",
  CONTROLLER: "Kontrol Eden",
  QUALITY_CONTROL: "Kalite Kontrol",
  PACKER: "Paketlemeci",
  SHOW: "Göster",
  HIDE: "Gizle",
  CONFIRM_OFFER: "Teklifi Onayla",
  COMPLETE_OFFER: "Teklifi Tamamla",
  CLOSE_OFFER: "Teklifi Kapat",
  CANCEL_ORDER: "Siparişi İptal Et",
  CANCEL_OFFER: "Teklifi İptal Et",
  CONFIRM_QUICK: "Teklifi Hızlı Kapat",
  CREATE_PERMISSION_GROUP: "Yetki Grubu Oluştur"
};

var cargoTr = {
  SEARCH_ORDER: "Sipariş Arama",
  UPDATE_ORDER_STATUS: "Sipariş Durumunu Güncelle",
  ORDER_COUNT: "Sipariş Sayısı",
  CARGO_INFO1:
    "Sipariş durumlarını seçilen kanal üzerinde güncellemek için en az sipariş seçmelisiniz",
  ORDER_NU: "Sipariş No",
  PACKAGE_CODE: "Paket No",
  PREPARE_IN_CARGO:'Kargodaki Siparişler',
  OFFER_NU: "Teklif Numarası",
  USERNAME: "Kullanıcı Adı",
  CUSTOMER_NAME: "Müşteri Adı",
  ADD_NEW_CUSTOMER: "Yeni Cari Ekle",
  CARGO_PROCESSES: "Kargo Süreçleri",
  ACTIONS: "İşlemler",
  ACTION: "İşlem",
  REMOVE_THE_TABLE: "Masadan Kaldır",
  ERP_NUMBER: "ERP Numarası",
  SHIP_IT: "Kargoya Gönder",
  CREATED_DATE: "Oluşturulma Tarihi",
  AMOUNT: "Tutar",
  CREATOR: "Oluşturan",
  PRINT_CARGO_BARCODE: "Çoklu Barkodu",
  CREATE_CARGO_PDF: "PDF OLUŞTUR",
  SELECT: "Seçiniz",
  PRINT_CARGO_REPORT: "Kargo Tutanağı",
  SELECT_DATE_GIVE_CARGO_FIRM: "Kargoya verildiği tarih",
  CARGO_STATUS: "Kargo Durumu",
  CARGO_MESSAGE: "Kargo Mesajı",
  CARGO_INFO: "Kargo Mesajı Bulunmamaktadır",
  ORDER_ID: "Sipariş Numarası",
  SELECT_CARGO_FIRM: "Kargo firması Seçiniz",
  CARGO_FIRM: "Kargo Firması",
  CARGO_PRICE: "Kargo Ücreti",
  SERVICE_PRICE: "Hizmet Ücreti",
  DELIVER:'Teslim Et',
  DELIVER_TO_SELECTED:'Seçilenleri Teslim Et',
  NO_ORDER_IN_PACKAGE_PROCESS_TITLE: "Paketlemede Sipariş Bulunmamaktadır",
  NO_ORDER_IN_PACKAGE_PROCESS_BODY: "Stok Hareketi Oluştur Bölümünden Yeni Bir Sipariş Oluşturabilirsiniz",
  QUALITY_CONTROL_INFO_TITLE: "Kalite Kontrolü",
  QUALITY_CONTROL_INFO_BODY: "Lütfen Kontrol Etmek İstediğiniz Siparişin Barkodunu Giriniz",
  INVOICES_INFO_TITLE: "Sipariş Arama Bölümü",
  INVOICES_INFO_BODY: "Sipariş NO'su İle İşleminize Devam Edebilirsiniz",
  CHANGE_SHELF_INFO_TITLE: "Raf Değiştir Bölümü",
  CHANGE_SHELF_INFO_BODY: "Hangi Depoda İşlem Yapacağınızı Seçerek Devam Edebilirsiniz",
  PRINT_UNIQUE_CARGO_BARCODE: "Tekli Kargo Barkodu",
  PRODUCT_ERP_CODE: "ERP Kodu",
  START_ADD_PRODUCT_WITH_CAMERA:'Kamera İle Ürün Ekle',
  SEARCH_PRODUCT_WITH_CAMERA:'Kamera İle Ürün Ara',
  SEARCH_PRODUCT_WITH_BARCODE:'Barkod İle Ürün Ara',
  NOT_EXIST_CONTROL_OF_STEP:'Henüz Elleçleme aşamasında iş emri yok'
};

var customersTr = {
  CUSTOMER : 'Cari',
  CREATE_CUSTOMER: "Cari Oluştur",
  CUSTOMER_CODE: "Cari Kod",
  CUSTOMER_NAME_OR_FIRM_LABEL: "Ad Soyad / Firma Ünvanı",
  EMAIL: "E-posta Adresi",
  PHONE_NUMBER: "Telefon Numarası",
  INVOICE_ADDRESS: "Fatura Adresi",
  ADDRESS_TYPE: "Adres Tipi",
  PERSONAL: "Bireysel",
  CORPORATE: "Kurumsal",
  IDENTITY_NU: "T.C. Kimlik No",
  TAX_NU: "Vergi Numarası",
  NO_MISSING_PRODUCTS_TITLE:'Eksik Ürün Bulunamadı',
  NO_MISSING_PRODUCTS_BODY:'Şuanda Eksik Bir Ürün Bulunmamaktadır ,Burayı Kapatıp Ürün Eklemeye Başla Kısmından Ürün ekleyebilirsiniz',
  TAX_DEP: "Vergi Dairesi",
  TRADE_REGISTRATION_ID: "Ticari Sicil No",
  COMPANY_LABEL: "Şirket Ünvanı",
  NAME_SURNAME: "Ad Soyad",
  GSM_NU: "GSM Telefon Numarası",
  ADDRESS: "Adres",
  CITY: "Şehir",
  TOWN: "İlçe",
  NEIGHBOURHOOD: "Mahalle",
  ZIP_CODE: "Posta Kodu",
  SAME_ADDRESS_INFO: "Teslimat adresi fatura adresi ile aynı",
  SAVE: "Kaydet",
  SAVE_RECOMMENDED_LOCATIONS: "Önerilen Depoları Ekle",
  DELIVERY_ADDRESS: "Teslimat Adresi",
  CUSTOMERS: "Müşteriler",
  CLEAR: "Temizle",
  CLEAR_UPPERCASE: "TEMİZLE",
  SEARCH: "Ara",
  CUSTOMER_NAME: "Cari Adı",
  PRINT:'Yazdır',
  ACTIONS: "İşlemler",
  CONTACT: "İletişim",
  FOOTER_MESSAGE: "Alt Bilgi Mesajı",
  SHOW_MISSING_PRODUCTS:'Eksik Ürünleri Göster',
  EDIT: "Düzenle",
  DELETE: "Sil",

  CUSTOMER_INFO1: 'Cari hesap arama, ekleme ve düzenlem için kullanılır',
  CUSTOMER_INFO2: 'Cari kod, kullanıcı adı, cari isim alanlarından arama yapar',

  CUSTOMER_EMPTY_SEARCH_MSG : ' Yapılan aramaya uygun cari bilgisi bulunamadı',


  NO_RECORD_INFO: "Görüntülenecek kayıt yoktur",
  RETURN_TRANSACTION : "İptal Oluştur",
  CANCEL_TRANSACTION : "İade Oluştur",
  MISSING_QUANTITY_REPORT : "Eksik Adet Bildir",
  SAME_QUANTITY : "Aynı Adet",
  OFFER_INFO_TITLE: "Teklif Oluşturma",
  OFFER_INFO_BODY: "Yukarıda Gireceğiniz Bilgilerle Teklifinizi Oluşturabilirsiniz",
};

var dashboardIndexTr = {
  TOTAL_ORDERS: "Toplam Siparişler",
  TOTAL_ORDER_QTY: "Toplam Sipariş Adedi",
  PREPARATION_STEP: "Hazırlama Aşaması",
  CONTROL_STEP: "Kontrol Aşaması",
  CARGO_PROCESSES: "Kargo Süreçleri",
  ORDER_ITEM_QUANTITY: "Sipariş Kalem Adeti",
  ITEM_QUANTITY: "Kalem Adet",
  GENERAL_VIEW: "Genel Görünüm",
  STOCK_TURNOVER_RATE: "Stok Devir Hızı",
  TURNOVER_RATE: "Devir Hızı",
  MOST_ACTIVE_PRODUCTS: "En Çok Hareket Gören Ürünler",
  DATA_NOT_READY_YET: "Bu güne ait veri henüz hazır değil",
  NOT_ENOUGH_DATA_FOR_REPORT: "Rapor için yeterli veriniz yok",
  THE_MOST_MOVING_PRODUCTS: "En Çok Hareket Gören Ürünler",
  REPORT_CREATE_TIME: "Rapor Oluşturma Zamanı",
  REPORT_BEING_PREPARED: "Raporunuz hazırlanıyor. Bittiğinde burada görüntülenecek",
  CLICK_GENERATE_REPORT_NOW: "Raporu hemen oluşturmak için tıklayın",
  HIGHEST: "En Yüksek",
  LOWEST: "En Düşük",
  DAILY: "Günlük",
  DAILY_REVENUE: "Günlük Toplam Ciro",
  MONTH: "Ay",
  WEEK: "Hafta",
  DAY: "Gün",
  YEAR: "Yıl",
  NOW_YEAR: "Bu Yıl",
  TODAY: "Bugün",
  LAST_WEEK: "Son 1 Hafta",
  THIS_WEEK: "Bu Hafta",
  LAST_MONTH: "Son 1 Ay",
  THIS_MONTH: "Bu AY",
  ALL_TIME: "Tüm Zamanlar",
  BRANCH_REVENUE: "Şube Ciroları",
  DATE: "Tarih",
  BRANCH: "Şube",
  PAYMENT_TYPE: "Ödeme Tipi",
  PREPARED_PRODUCT_COUNT: "Hazırlanan Ürün Adedi",
  PREPARED_PRODUCT_QTY: "Hazırlanan Ürün Sayısı",
  SERVICE: "Servis",
  STATUS: "Durum",
  ELASTIC_SEARCH_ENGINE: "Elastic Arama Motoru",
  ACTIVE: "Aktif",
  COMPLATE: "Tamamlandı",
  PASSIVE: "Pasif",
  RECORD_COUNT: " kayıt sayısı",
  RETAIL_SALE: "Perakende Satış",
  WHOLESALES: "Toptan Satış",
  SELECT_MONTH: "Ay Seçiniz",
  JAN: "Ocak",
  FEB: "Şubat",
  MARCH: "Mart",
  APR: "Nisan",
  MAY: "Mayıs",
  JUN: "Haziran",
  JULY: "Temmuz",
  AUGUST: "Ağustos",
  SEPTEMBER: "Eylül",
  OCTOBER: "Ekim",
  NOVEMBER: "Kasım",
  DECEMBER: "Aralık",
  TOTAL_AMOUNT: "Toplam Tutar",
  SEARCH_ENGINE: "Arama Motoru",
};

var integrationPageTr = {
  SIPARISLER: "Siparişler",
  INTEGRATION_FILTER_1: "Görevin Çalıştığı Başlangıç Tarihi",
  SAAT_SECINIZ: "Saat Seçiniz",
  INTEGRATION_FILTER_2: "Görevin Çalıştığı Bitiş Tarihi",
  SELECT_CHANNEL: "Kanal Seçiniz",
  ORDER_UPDATED_START_DATE_ENTER: "Güncellenme Başlangıç Tarihi",
  ORDER_UPDATED_END_DATE_ENTER: "Güncellenme Bitiş Tarihi",
  SELECT_ORDER_STATUS: "Sipariş durumu seçiniz",
  SALES_CHANNEL: "Satış Kanalı",
  USER: "Kullanıcı",
  ORDER_START_DATE: "Başlangıç Tarihi",
  ORDER_END_DATE: "Bitiş Tarihi",
  TRANSFERRED_ORDER_COUNT: "Aktarılan Sipariş Sayısı",
  ORDER_STATUSSES: "Sipariş Durumları",
  WORKING_TIME: "Çalıştığı Saat",
  WORKING_ELAPSED_TIME: "Çalışma Süresi",
  KILL_PROCESS: "İşlemi Sonlandır",
  STOCK: "Stok",
  SELECT_DATE: "Tarih Seçiniz",
  SELECT_OPERATOR: "Operatör",
  PREV_SELECT_DATE: "seçili tarihten önceki",
  PREV: "Önceki",
  AFTER: "Sonraki",
  MINIMUM_AMOUNT: "Minimum Tutar",
  MAXIMUM_AMOUNT: "Maximum Tutar",
  NEXT_SELECT_DATE: "seçili tarihten sonraki",
  SELECT_CURRENT_DATE: "seçilen tarih",
  SELECT_DATE_FIELD_PROPERTIES: "Tarih Alanı Özelliği",
  BY_CREATED_DATE: "Oluşturulma Tarihine Göre",
  BY_UPDATE_DATE: "Güncelleme Tarihine Göre",
  BY_STOCK_UPDATE_DATE: "Stok Güncelleme Tarihine Göre",
  BY_PRICE_UPDATE_DATE: "Fiyat Güncelleme Tarihine Göre",
  STOCK_START_DATE: "Başlangıç Tarihi",
  STOCK_END_DATE: "Bitiş Tarihi",
  OPERATOR: "Operatör",
  DATE_PROPERTIES_FIELD: "Tarih Türü",
  SESSION_ID: "Oturum Kimliği",
  TRANSFERRED_PRODUCT_COUNT: "Aktarılan Ürün Sayısı",
  UPDATE_CURRENCY: "Kur Bilgilerini Güncelle",
  CURRENCY: "Para Birimi",
  EXCHANGE_RATE: "Döviz İşlemleri",
  CURRENCY_DECIMAL: "Para Birimi Ondalık Hane",
  INTEGRATION_INFORMATION: "Entegrasyon Bilgileri",
  PROVIDER_PRODUCT_ID: "Entegrasyon ID",
  TRANSFER_MESSAGE: "Transfer Sonucu",
  PREPARER: "Hazırlayan",
  MARKET_PLACE: "Pazar Yeri",
  LAST_STATUS: "Son Durum",
};

var batchOperationsTr = {
  IMPORT_TRANSACTION: "Stok Hareketi İçeri Aktar",
  IMPORT_PRODUCT_SHELF_RELATION: "Ürün-Raf İlişkisi İçeri Aktar",
  IMPORT_SHELF: "Raf İçeri Aktar",
  UPLOAD: "Yükle",
  BROWSE: "Gözat",
  SELECTED_FILE: "Seçilen Dosya",
  PLEASE_WAIT_FOR_PROCESS: "Lütfen bekleyiniz... Dosyanız işleniyor...",
  DOWNLOAD_IMPORT_REPORT:
    "İçe aktarım raporlarını indirmek için dosya ismine tıklayınız.",
  DOWNLOAD_PREVIEW_REPORT:
    "Yüklenen dosyanın analizini görmek için lütfen dosya ismine tıklayınız.",
  IMPORT_SHELF_INFO1:
    "Bu bölümde excel aracılığı ile raf kodları sisteme tanımlanabilmektedir.",
  CLICK: "tıklayınız",
  IMPORT_SHELF_INFO2:
    "Bu bölümde excel aracılığı ile raf kodları ve ürünler arasındaki bağlantı sisteme tanımlanabilmektedir.",
  IMPORT_SHELF_INFO3:
    "Bu bölümde excel aracılığı ile işlem listesi oluşturulmaktadır.",
  DOWNLOAD_SAMPLE_EXCEL_FILE:
    "Örnek excel dosyasını indirmek için",
  UPLOAD_COMPLETED_DOWNLOAD_REPORT:
    "numaralı işlem oluşturulmuştur. İçe aktarım raporlarını indirmek için dosya ismine tıklayınız.",
  SAMPLE_DATA_SET: "Örnek Veri Seti",
  DATA_PATTERN: "Veri Deseni",
};

var invoicesTr = {
  ORDERS: "Siparişler",
  CHANNEL_ORDERS: "Kanal Siparişleri",
  STATISTICS: "İstatistikler",
  CHOOSE_USER: "Kullanıcı Seçiniz",
  SHOW_STATISTIC: "İstatistik Göster",
  TRANSFER_EXCEL_BUTTON_NAME: "Eksik Ürünlüler",
  TRANSFER_EXCEL: "Excel",
  CSV: "CSV",
  ORDER_STATUS_HISTORY: "Sipariş Durum Geçmişi",
  NOT_TRANSFERRED_ERP: "Muhasebeye Aktarılmayanlar",
  MANUAL_TRANSFER_ERP: "Muhasebeye Elle Aktar",
  BALANCE_PRODUCT_ORDERS: "Eksik Ürünlü Siparişler",

  SELECT_STATUSSES_ORDER: "Sipariş Durumları",
  SEARCH_STATUS: "Durum Ara",
  REMOVE_STATUS: "Seçimleri Kaldır",
  SELECT_ALL: "Hepsini Seç",
  TAKE_ALL: "Hepsini Al",
  WAS_SELECT_STATUS: "durum seçildi",
  ALL: "Hepsi",
  TRANSFER_TO_ERP: "ERP'ye Aktar",
  ACCOUNTING: "Muhasebe",
  ACCOUNTING_TRANSFERS: "ERP Aktarımı",
  SHIPMENT_TRANSFERS: "Kargo Aktarımları",
  TAKE_TO_ACCOUNTING: "Muhasebeye Gönder",
  ACTION: "İşlem",
  ORDER_LINE_COUNT: "Sipariş Kalem Adedi",
  TRANSFER: "Aktarım",
  ORDER_STATUS: "Sipariş Durumu",
  ORDER_DATE: "Sipariş Tarihi",
  GENERAL_TOTAL: "Genel Toplam",
  EMAIL_NATIVE: "E-posta",
  ORDER_TYPE: "Sipariş Tipi",
  BALANCE_PRODUCT_NOTIFY: "Eksik Ürün Bildirimi",
  CREATE_STOCK_ACTION: "Stok Hareketi Yarat",
  ORDER_TRANSFERED_ERP: "Sipariş muhasebe programına aktarıldı.",
  ORDER_NOT_TRANSFERED_ERP: "Sipariş muhasebe programına aktarılamadı.",
  CONNECTION_LOST: "Server ile bağlantı kurulamıyor",
  DOWNLOADING: "İndiriliyor",
  INVOICES_INFO:
    "Yukarıdaki form aracılığıyla aktarılan siparişlerinizi sorgulayabilir, yönetebilirsiniz. Lütfen arama kriterinizi genişletiniz.",
};

var locationTr = {
  LOCATIONS: "Depolar",
  CREATE_LOCATION: "Depo Oluştur",
  LOCATION_NAME: "Depo Adı",
  LOCATION_TYPE: "Depo Tipi",
  SELECT_LOCATION_TYPE: "Depo Türü",
  SELECT_REMOVAL_TYPE: "Eksiltme Tipi",
  REMOVAL_TYPE: "Eksiltme Tipi",
  NOT_FOLLOWING: "Takip edilmiyor.",
  CHOOSE_USER: "Kullanıcı Seçiniz",
  SHELF_CODE: "Raf Kodu",
  SHELF: "Raf",
  SHELF_NAME: "Raf Adı",
  WAREHOUSE_NAME: "Depo Adı",
  EMPTY_SHELF: "Boş Raf",
  USED_SHELF: "Kullanılan Raf",
  NO_PRODUCT_IN_SHELF: "Ürün Tanımlı Olmayan Raf",
  SUB_LOCATION_STATUS: "Raf Durumu",
  SEARCH: "Ara",
  SAVE: "Kaydet",
  CANCEL: "Vazgeç",
  ORDER_CANCEL: "İptal",
  SHELF_ORDER_NU: "Raf Sıra No",
  SHELVES: "Raflar",
  SHELF_DETAIL: "Raf Detayı",
  CREATE_SHELF: "Raf Oluştur",
  SHELF_OPERATION: "Raf İşlemleri",
  PRODUCT_INFO: "Ürün Bilgileri",
  PRODUCT_CODE: "Ürün Kodu",
  ENTER_CRITERIA_SEARCH_SHELF: "Raf arama kriteri giriniz",
  PRODUCT_VARIATION: "Ürün varyasyonu",
  ENTER_CRITERIA_SEARCH_PRODUCT: "Ürün arama kriteri giriniz",
  FILTER: "Filtrele",
  PRODUCT_SHELF_RELATION: "Ürün-Raf İlişkilendirme",
  TRANSPORTERS: "Taşıyıcılar",
  TRANSPORTER_SECTIONS: "Taşıyıcı Bölümleri",
  TRANSPORTER_NAME: "Taşıyıcı Adı",
  SECTION_NAME: "Bölüm Adı",
  SELECT_TRANSPORTER: "Taşıyıcı Seçiniz",
  WAREHOUSE: "Depo",
  WAREHOUSES: "Depolar",
  WAREHOUSE_UPDATED: "Depo güncellendi.",
  SHELF_UPDATED: "Raf kodu güncellendi.",
  SHELF_CREATED: "Raf oluşturuldu.",
  CONFIRM: "Onayla",
  CONFIRM_ALL: "Hepsini Onayla",
  DELIVER_ALL:'Hepsini Teslim Et',
  NOT_CONFIRM: "Onaylama",
  CONFIRM_MESSAGE: "Onay Mesajı",
  CONFIRM_MESSAGE_BODY: "Bu satırı silmek istediğinize emin misiniz?",
  SHELF_DELETED_MESSAGE: "Raf silindi.",
  SHELF_PRODUCT_MATCH_MESSAGE: "Raf ve ürün eşleştirildi.",
  SHELF_PRODUCT_RELATION_DELETE: "Raf ve ürün eşleştirilmesi silindi.",
  SELECT_ONE_CRITERIA: "En az bir kriter seçiniz.",
  SECTION_DEFINED: "Bölüm tanımlandı",
  SECTION_DELETED: "Bölüm silindi.",
  TRANSPORTER_DEFINED: "Sipariş hazırlama aracı tanımlandı.",
  TRANSPORTER_DELETED: "Sipariş hazırlama aracı silindi.",
  REPORT_NAME: "Rapor Adı",
  SHELF_TYPE: "Raf Tipi",
  PACKAGING: "Paketleme",
  LOCATION: "Depo",
  UNDEFINED: "Tanımsız",
  BARCODE: "Barkod",
  PROCESS: "İşleme",
  PROCESSED: "İşleme alındı",
  LAST_PROCESS: "Son İşlem",
  WIDTH: "Genişlik",
  HEIGHT: "Yükseklik",
  DEPTH: "Derinlik",
  WEIGHT: "Ağırlık",
  DOWNLOAD: "İndir",
  PRODUCT_COUNT: "Ürün Adeti",
  VOLUME: "Hacim (w X h X d)",
  WAREHOUSE_LAYOUT_GUIDE: "Depo Yerleşim Kılavuzu",
  CHANNEL: "Kanal",
  CREATE_GUIDE_REQUEST: "Kılavuz Talebi Oluştur",
  WHATIS_LOCATION_GUIDE: "Depo Yerleşim Kılavuzu Nedir?",
  LOCATION_GUIDE_INFO: "Sipariş verilerinizi analiz ederek deponuzun organize etmenizde size yardımcı olacak bir araçtır.Kılavuz talebi oluşturmak için, kanal seçiniz ve klavuz talebi oluşturunuz.",
  REQUEST_DATE: "Talep Tarihi",
  RESPONSE_DATE: "Sonuçlanma Tarihi",
  TRANSPORTER_ORDER_PREP_MSG: "Kullanıcı seçimi zorunlu değildir",
  TRANSPORTER_INFO_MSG: "Taşıyıcıya toplu sipariş tanımlaması yapıldığı için bu alanı kullanamazsınız",
  PACKING_SHELF: "Sabit(Paketleme Rafı)",
  PACKING_SHELF_INFO1: "Raf ismi barkod olsun",
  PRODUCT: "Ürün",
  PRODUCT_DEFINE_METHOD: "Ürün Tanımlama Yöntemi",
  PRODUCT_DEFINE_TO_SHELVE: "Rafa Ürün Tanımla",
  BARCODE_NU: "Barkod No",
  GET: "Getir",
  PRODUCT_NAME: "Ürün Adı",
  VARIATION: "Varyasyon",
  SERIAL_NUMBER: "Seri Numara",
  NOTHING: "Hiçbiri",
  LOCATION_MSG: "Ürün-Raf ilişkisi olsa da stok yoksa o raflar listelenmektedir",
  WAREHOUSE_ANALYSIS: "Depo Ürün Uygunluk Analizi",
  RESPONSE_ITEM_COUNT: "Karşılama Kalem Adedi",
  RESPONSE_ENOUGH: "Uygunluk",
  REQUEST_QUANTITY: "Talep Adet",
  RESPONSE_QUANTITY: "Karşılama Adet",
  PRIORITY: "Öncelik",
  RESPONSE_COUNT: "Karşılama Adedi",
  REQUIRED:'Zorunlu',
  NOT_REQUIRED:'Zorunlu Değil',
  PRODUCT_SEARCH: 'Ürün Ara',
  APPROVE: 'Onay',
  APPROVED: 'Onaylanan',
  TOTAL_COUNTED: 'Toplam Sayılan'
};

var loginTr = {
  COPYRIGHT_RESERVED: "Tüm hakları saklıdır.",
  ENTER: "Giriş",
  PASSWORD: "Şifre",
  EMAIL: "E-posta",
  LOGIN: "Oturum Aç"
};

var batchOperationsForTsoftTr = {
  IMPORT_PRODUCTS_AND_FILTERS: "Ürünlere Filtre Ekle",
  IMPORT_PRODUCT_FILTER_FOR_TSOFT_INFO1:
    "Bu bölümden excel aracılığı ile ürün filtreleri seçtiğiniz T-Soft B2C kanalında tanımlanacaktır. ",
  IMPORT_EXCEL: "Excel Toplu İşlemler",
  STEP_FILE_IMPORT: "Dosya Yükle",
  STEP_SERVICE_SELECT: "Servisi Seç",
  STEP_PREPARE_TEMPLATE: "Veriyi Hazırla",
  STEP_UPLOAD_DATA: "Veriyi Yükle",
  STEP_SERVICE_FILE_HEADER: "Dosyada Başlık Var",
  STEP_SELECT_TEMPLATE: "Şablon Seç",
  STEP_NEW_TEMPLATE: "Yeni şablon",
  COLUMN: "KOLON",
  TEMPLATE_NAME: "Şablon İsmi",
  SAVE_TEMPLATE: "Şablonu Kaydet",
  ESTIMATED_DELIVERY_DATE: "Teslimat Tarihi",
  UPDATE_TEMPLATE: "Şablonu Güncelle",
  PROGRESS_SUMMERY: "Yükleme Özeti",
  STEP_SELECT_EXTRA_FIELD: "Ekstra Alanlar",
  CHOOSE_CARGO_FIRM: "Kargo Firması Seçiniz",
  STEP_SELECT_EXTRA_FIELD_INFO: "Ekstra alanlar yapılan seçimlere göre ihtiyaç olan diğer alanları gösterir",
  NOT_YET_SAVED: "Kaydedilmedi",
  MULTIPLE: "Çoklu",
  BACK: "Geri",
  SEND: "Gönder",
  NEXT: "İleri",
  MY_UPLOADS: "Yüklemelerim",
  SHOW_ALL: "Hepsini Göster",
  SHOW_WAITING_ACTIONS: "Sadecce İşlem Bekleyenleri Göster",
  FILE_NAME: "Dosya İsmi",
  TITLE: "Başlık",
  ERROR_MESSAGE: "Hata Detayı",
  ROW_MATCH: "Sistem Karşılığı",
};

var actionTr = {
  ISSUE: "İş Emri",
  ISSUE_STATUS: "İş Emri Statüsü",
  ONLY_ANOMALY_ISSUE_STATUS: "Sadece farklı statüdeki listeler",
  ISSUE_NO: "İş Emri No",
  ISSUE_CREATE: "İş Emri Oluştur",
  SHELF_EXIT_ISSUE_CREATE: "Raf Çıkış İş Emri Oluştur",
  SHELF_ENTRY_ISSUE_CREATE: "Raf Giriş İş Emri Oluştur",
  FAULTY_ISSUE_CREATE: "Arızalı İş Emri Oluştur",
  ISSUE_DETAIL: "Emir Detayı",
  ISSUES: "İş Emirleri",
  ORDER_PREPARE_LIST: "İş Emri",
  ORDER_PREPARE_LIST_CREATE: "İş Emri Oluştur",
  ORDER_PREPARE_LIST_NAME: "İş Emri",
  LIST_TYPE: "İş Emri Tipi",
  LIST_CONTENT: "İş Emri İçeriği",
  MARKETPLACE: "Pazaryerleri",
  ACTON_TYPE: "Sipariş Tipi",
  OFFER_LIST: "Teklifler",
  SALES_OFFER: "Satış Teklifleri",
  RECEIVING: "Mal Kabul",
  OFFER: "Teklif",
  CART: "Sepet",
  OFFER_ID: "Teklif Numarası",
  OFFER_TYPE: "Teklif Tipi",
  OFFER_STATUS: "Teklif Durumları",
  OFFER_EXPIRE_STATUS: "Teklif Zaman Aşımı",
  EXPIRED: "Süresi Dolmuş",
  DONE: "Tamamlandı",
  REJECT: "Reddedilen",
  CREATED_BY: "Oluşturan",
  CREATE_AN_OFFER: "Teklif Oluştur",
  CREATE_STOCK_MOVEMENTS: "Stok Hareketi Oluştur",
  MOVEMENTS: "Hareketler",
  STOCK_MOVEMENT: "Stok Hareketi",
  STOCK_MOVEMENTS: "Stok Hareketleri",
  STOCK_MOVEMENT_DETAIL: "Stok Hareketi Detayı",
  CUSTOMER_CODE: "Cari Kodu",
  WAREHOUSE_FOR_ACTION: "İşlem Yapılacak Depo",
  WAREHOUSE_FOR_ACTION2: "2.Depo",
  STOCKTAKINGS_NOT_FOUND_TITLE: "Stok Sayımı Bulunmamaktadır",
  STOCKTAKINGS_NOT_FOUND_BODY: "'Yeni' Butonuna Tıklayarak Yukarıda Açılan Formdan Yeni Bir Stok Sayımı Oluşturabilirsiniz",
  ACTION_TYPE: "İşlem Tipi",
  NEW_ACTION_TYPE: "Yeni İşlem Tipi",
  CHOOSE_ACTION_TYPE: "İşlem tipi seçiniz",
  CHOOSE_REFUND_TYPE: "İade nedenini seçiniz",
  INVOICE_NU: "Fatura No",
  INVOICE_DATE: "Fatura Tarihi",
  CHOOSE_DUE_DATE: "Vade günü seçiniz",
  DUE_DATE: "Vade Günü",
  DAY: "Gün",
  CREATOR_USER: "Oluşturan Kullanıcı",
  SELECT_CREATOR_USER: "Oluşturan Kullanıcı Seçiniz",
  ORDER_PREPARE_USER: "Hazırlayan Kullanıcı",
  SELECT_ORDER_PREPARE_USER: "Hazırlayan Kullanıcı Seçiniz",
  TRANSACTION_NOTE: "İşlem Notu",
  SHIPPING_TYPE: "Teslimat Türü",
  SHIPPING_TYPE1: "Kargo",
  SHIPPING_TYPE2: "Elden Teslim",
  TAX_TYPE: "Vergi Türü",
  ACTION_INFO1: "İşlem listesinin kdv dahil tutarı üzerinden hesaplanmalıdır.",
  ACTION_INFO2: "Kayıt bilgilerini değiştirebilmek için ürün girişini durdurup değişiklik yapabilirsiniz.",
  CONTINUE: "Devam Et",
  SELECTED_PRODUCTS: "Seçilen Ürünler",
  SELECTED_PRODUCT: "Seçilen Ürün",
  BASKET: "Sepet",
  BASKET_AMOUNTS: "Sepet Tutarı",
  VAT_INCLUDED: "KDV Dahil",
  VAT: "KDV",
  VAT_NOT_INCLUDED: "KDV Hariç",
  NOTE: "Not",
  STOCK_CODE: "Ürün Kodu",
  DISCOUNT: "İskonto",
  DISCOUNT_PERCENT: "İskonto Oranı",
  PRICE: "Fiyat",
  NEW_PRICE: "Yeni Fiyat",
  QUANTITY: "Adet",
  ORDER_QUANTITY: "Sipariş Adeti",
  QUANTITY_DIFF: "Adet Değişimi",
  LAST_BUYING: "Son Satın Alma",

  FROM_LAST: "Sondan",
  LOT_SERIAL: "Lot/Seri Nu",
  CHOOSE_A_LOT_SERIAL: "Lot/Seri No Seçiniz",
  LEAD_TIME_DESC: "Temin Süresi Açıklama",
  SEARCH_RESULT: "Arama Sonuçları",
  PRODUCT_FOLLOW: "Stok İzleme",
  SUB_PRODUCT_FEATURE: "Alt Ürün Özelliği",
  STOCK_QTY: "Stok Miktarı",
  WAREHOUSES_QTY: "Depo Dağılımı",
  WITH_DISCOUNT: "İskontolu",
  DISCOUNT_TYPE: "İskonto Tipi",
  ADD: "Ekle",
  BULK_ADD: "Toplu Ekle",
  ACTION_INFO3: "Görüntülenecek kayıt yoktur",
  TRANSPORTER_OR_SECTION: "Taşıyıcı/Bölüm",
  PREPARING_USER: "Hazırlayan",
  CARGO_DATE: "Sevkiyat Zamanı",
  COMPLETE_TIME:'Tamamlanma Süresi',
  DOCUMENT:'Döküman',
  ACTION_LINE_COUNT: "Kalem Sayısı",
  MOVEMENT_TYPE: "Hareket Tipi",
  MOVEMENT: "Hareket",
  LAST_MOVEMENT: "Son Hareket",
  LAST: "Son",
  VISITED_SHELF_QUANTITY:'Uğranan Raf Sayısı',
  CHANNEL_TYPE: "Kanal Adı",
  CUSTOMER_INFO: "Cari Bilgileri",
  NOT_ASSIGNED_ORDER: "Kullanıcı Atanmamışlar",
  ORDERS_IN_PACKAGE_PROCESS: "Paketleme Sürecindekiler",
  PREPARED_ORDERS: "Hazırlığı Tamamlananlar",
  NOT_PREPARED_ORDERS: "Hazırlanmayanlar",
  NOT_ASSIGNED_TRANSPORTER: "Taşıyıcıya Atanmayanlar",
  ORDERS_HAS_ONE_PRODUCTS: "Tek Ürünlüler",
  UNDER_DECLARED_ORDERS: "Eksik Bildirimliler",
  TRANSACTION_ID: "İşlem No",
  STATUS_FILTERS: "Hareket Durum Filtreleri",
  FILTER_OPTIONS: "Filtreleme Seçenekleri",
  SHIPPING_DATE: "Sevkiyat Tarihi",
  SELECT_WAREHOUSE: "Depo seçiniz",
  USER_WAREHOUSE: "Kullanıcı Depoları",
  MAIN_WAREHOUSE: "Varsayılan Depo",
  SELECT_PAYMENT_TYPE: "Ödeme tipi seçiniz",
  FIELD: "Alan",
  SHIPMENT_CODE : 'Kargo Kodu',
  VALUE: "Değer",
  SELECT_FIELD: "Alan seçiniz",
  NOT_PREPARED_INVOICE: "Faturası Kesilmeyenler",
  ACTION_NOT_IN_VAT: "Vergisiz İşlemler",
  DELETED_ACTIONS: "Silinen İşlemler",
  ARCHIVE: "Arşiv",
  ACTION_LINES_QTY_TRANSFER_EXCEL: "Hareket Ürün Toplamını Aktar",
  ACTIONS_TRANSFER_EXCEL: "Hareketleri Aktar",
  TRANSFER_TO_PDF: "Pdf'e Aktar",
  TRANSFER_PDF_OFFER_MODE: "Teklif Olarak Pdf Hazırla",
  REPREPARE_ACTION: "Siparişi Hazırlamayı Geri Al",
  COPY_ACTION: "Stok Hareketini Kopyala",
  SHOW_ERP_INTEGRATION: "ERP Entegrasyonunu Görüntüle",
  EDIT_ACTION_INFO1: "Stok hareketi oluşturduktan sonra henüz 24 saati geçmediği için düzenleme yapabilirsiniz",
  EDIT_ACTION_INFO3: "Hazırlayan kullanıcıyı liste görünümünden değiştirebilrisiniz",
  EDIT_ACTION_INFO4: "Ürün kalem bazında yapılan değişikliklerin bu işlemde ve siparişler bölümünde aktif olması için lütfen \"Güncelle\" butonuna\n tıklayınız.",
  PRINT: "Yazdır",
  PRODUCTS: "Ürünler",
  INTERNATIONAL_PRICE: "Döviz Fiyatı",
  LOCAL_PRICE: "TL Fiyat",
  TOTAL_STOCK_QTY: "Toplam Stok A.",
  TOTAL: "Toplam",
  SUB_TOTAL: "Ara Toplam",
  SHOWING: "Gösterilen",
  GO_ACTION: "İşleme Git",
  PAGE: "Sayfa",
  PREPARE_LIST_INFO1: "Seçilen siparişler için yeni liste oluştur",
  PREPARE_LIST_INFO2:"Hazırlanmayı Bekleyen Liste",
  PREPARE_LIST_INFO3:"Şuan hazırlanmayı bekleyen bir iş emri yok",

  PREPARE_LIST_INFO4:"Benim Listelerim",
  PREPARE_LIST_INFO5:"Henüz aktif liste yok",

  SHIPMENT_LIST_INFO1:"Kargo Süreçleri",
  SHIPMENT_LIST_INFO2:"Siparişlerinizin kargo entegrasyonu oluşumu ve işlem esnasında olası hatalrın işlemler takip edilir",

  SHIPMENT_LIST_INFO3:"Gönderim Sayım Listesi",
  SHIPMENT_LIST_INFO4:"Kargosu gönderilecek siparişlerin gönderim için son sayımı listesidir.",

  SHIPMENT_LIST_INFO5:"Kargoyu Teslim Et",
  SHIPMENT_LIST_INFO6:"Sayımı tamamlanan sipirişlerin kargo firmasına teslim listesi",


  CART_INFO1:"SEPET",
  CART_INFO2:"Sepette henüz ürün yok",


  MISSING_ITEM_INFO1:"Eksik Bildirilen Ürünler",
  MISSING_ITEM_INFO2:"Henüz Eksik Bildirim Bulunmamaktadır",


  ACCOUNTING_LIST_INFO1:"Muahasebe Aktarımları",
  ACCOUNTING_LIST_INFO2:"Siparişlerinizin fatura oluşumu ve işlrm esnasında olası hatalrın işlemler takip edilir",
  ACCOUNTING_LIST_INFO3:"Sipariş Henüz Fatura aşamasına gelmedi",

  SHIPMENT_STATUS:"Kargo Durumu",
  SHIPMENT_INFO1:"Teslim Edilecek Siparişler",
  SHIPMENT_INFO2:"Henüz teslim edilmeyi bekleyen sipariş bulunmuyor",

  ISSUE_LIST_INFO1: 'İş Emirleri Listesi',
  ISSUE_LIST_INFO2: 'Seçilen kriterlerde iş emri bulunamadı. Filtre alanlarını değiştirerek arama yapabilirsiniz',

  ISSUE_CREATE_ACTION_LIST_INFO1: 'İş Emirlerine Eklenecek Siparişler',
  ISSUE_CREATE_ACTION_LIST_INFO2: 'İş emrine eklenecek siparişleri listesi. Kayıt alanlarını değiştirerek arama yapabilirsiniz',

  TRY_AGAIN : 'Tekrar Dene',


  CREATE_LIST: "İş Emri Oluştur",
  UPDATE_LIST: "İş Emri Güncellle",
  SINGULAR: "Tekil",
  ADD_ORDER: "Sipariş Ekle",
  CHANGE_USER: "Kullanıcı Değiştir",
  GO_PRODUCT: "Ürüne Git",
  PENDING_ISSUES: 'Bekleyen İş Emirleri',
  OWN_ISSUES: 'Benim İş Emirlerim',
  STOCK_MOVEMENT_FOR_NOT_CREATED_ISSUE: 'İş Emri Oluşturulmamış Ya Da Tamamlanmamış Stok Hareketleri',

  SEARCH_RESULT_MATCH: 'Arama Sonucu "Tam Eşleşme" ile bulundu',
  SEARCH_RESULT_SIMILAR:  'Arama Sonucu "Benzer Eşleşmeler" ile bulundu',

  SELECT_IMAGE_UPLOAD_FORMAT: "Resim Yükleme Biçimi Seçiniz",
};

var notificationTr = {
  NOTIFICATIONS: "Bildirimler",
  NOTIFICATION_INFO1: "Bildirim uyarısının hangi seviyelerde görünür olacağını ayarlayabilirsiniz.",
  NOTIFICATION_INFO2: "Son 24 saat dışındaki bildirimler otomatik olarak silinir.",
  NOTIFICATION_INFO3: "Henüz bildiriminiz yok.",
  NOTIFICATION_SETTINGS: "Bildirim Ayarları",
  GENERAL: "Genel",
  IN_COMPANY: "Şirket İçi",
  PERSONALITY: "Kişisel",
  CLEAR_NOTIFICATIONS: "Bildirimleri Temizle",
  MESSAGE: "Mesaj",
  WHO: "Kime",
  NOTIFICATION_DOWNLOAD_INFO1: "İndirme talebinde bulunduğunuz servislerin indirme bağlantıları.",
  NOTIFICATION_DOWNLOAD_INFO2: "",
  DOWNLOAD_NOTIFICATION: "İndirme Bağlantıları",
};

var ordersPrepareTr = {
  ORDER_PREPARING: "Sipariş Hazırlama",
  ORDER_TRANSPORTER_RELATION: "Sipariş-Taşıyıcı İlişkisi",
  TRANSPORTER: "Taşıyıcı",
  SECTION: "Bölüm",
  EMPTY_SECTION: "Boş Bölüm",
  ORDERS_FOR_WAITING_PRODUCTS: "Ürün Bekleyen Siparişler",
  ORDERS_SECTION_INFOMSG1: "Herhangi bir bölümle sipariş eşleştirilmemiştir",
  ORDERS_SECTION_INFOMSG2: "aracı ile herhangi bir sipariş eşleştirilmemiştir.",
  ORDERS_SECTION_INFOMSG5: "Hazırlığı tamamlanmış herhangi bir sipariş bulunmamaktadır",
  ORDERS_SECTION_INFOMSG6: "Herhangi bir sipariş için hazırlanması gereken ürün bulunmamaktadır",
  ORDERS_SECTION_INFOMSG7: "Lütfen sipariş kutusuna yerleştireceğiniz ürünün stok kodunu okutunuz",
  ORDERS_SECTION_INFOMSG4: "Paketlemedeki bütün raflar kullanılmaktadır <a href=\"/#!/location-management?tab=5\"><b>Linkten</b></a> yeni raf tanımlama yapabilirsiniz.",
  ORDERS_SECTION_INFOMSG3: "Paketleme rafı tanımlanmamıştır. <a href=\"/#!/location-management?tab=5\"><b>Linkten</b></a> tanımlama\n apabilirsiniz.",
  ORDERS_PREPARING_FOR_TRANSPORTER: "Taşıyıcıya Göre Sipariş Hazırlama",
  ORDERS_PREPARING_FOR_LIST: "Listeye Göre Sipariş Hazırlama",
  ORDERS_RECEIVING: "Mal Kabul",
  ORDERS_PICKING: "Sipariş Toplama",
  HANDLING: "Elleçleme",
  CONTROL: "Kontrol",
  DONE_PROCESS : 'Teslimat',
  SHIPPING_ITEMS : 'Gönderim Onayı',
  PREPARED_ORDERS: "Hazırlananlar",
  ORDERS_IN_PACKAGING_PROCESS: "Paketlemedeki Siparişler",
  PACKAGING: "Paketleme",
  WILL_PREPARE_PRODUCT_FOR_ORDERS: "Siparişler İçin Hazırlanacak Ürünler",
  CLOSE_CONTINUE_READ: "Kapat ve Okumaya Devam Et",
  CONTINUE_READ: "Okumaya Devam Et",
  RETURN_SHELF: "Rafa Geri Dön",
  RETURN: "İade",
  RETURN_RECEIVING: "İade Mal Kabul",
  PARTIAL_RETURN: "Parçalı İade",
  SAME_SHELF: "Aynı Raf",
  NEXT_SHELF: "Sonraki Raf",
  MOST_LESS: "En Az",
  MOST_PLUS: "En Fazla",
  PLUS_PREPARING: "TOPLA",
  SELECT_PREPARING_METHOD: "Toplama Yöntemini Seçiniz",
  CAMERA: "Kamera",
  HAND_TERMINAL: "El Terminali",
  HAND: "Elle",
  SELECT_CAMERA: "Kamera Seçiniz",
  START_NEXT_ISSUE: "Sıradaki İş Emrine Başla",
  START: "Başla",
  LIST: "Liste",
  LIST_NO: "Liste No",
  RECEIVING_ISSUES: "Mal Kabul İş Emirleri",
  SHELF_UP: "Rafa Yerleştir",
  SHELF_ENTRY: "Raf Giriş",
  SHELF_UNDEFINED: "Raf Tanımsız!",
  PLACE_PRODUCTS_WITHOUT_BARCODE: "Barkodsuz Yerleştir",
  PLACE: "Yerleştir",
  FAULTY: "Arızalı",
  FAULTY_PRODUCT: "Arızalı Ürün",
  REJECTED_QUANTITY: "Kabul Edilmeyen Adet",
  PICKING_PREVIOUS_ISSUES: "Sipariş Hazırlama İş Emirleri",
  PREPARING: "Hazırlayan",
  TOTAL_PRODUCT_COUNT: "Toplam Ürün Sayısı",
  TOTAL_PRODUCT_QUANTITY: "Toplam Ürün Adedi",
  TOTAL_PRODUCT_SORT:'Toplam Ürün Çeşidi',
  PRODUCT_ORDER_RELATION: "Ürün / Sipariş Bilgisi",
  LOT_DETAIL_QTY: "Lot Detayı / Miktar",
  LOT_DETAIL: "Lot Detayı",
  READY: "Hazır",
  REVERT: "Geri Al",
  UNCONFIRM: "Onayı Geri Al",
  TRANSPORTER_SECTION: "Taşıyıcı Bölümü",
  STOP: "Durdur",
  STOP_CAMERA: "Kamerayı Durdur",
  PACKAGING_AREA_DETAIL: "Paketleme Alanı Detayı",
  DETAIL: "Detay",
  TRANSPORTER_DETAIL: "Taşıyıcı İçeriği",
  PRODUCT_LIST: "Ürün Listesi",
  SHIPPING_READY: "Sevkiyata Hazır",
  SHOW_ACTION_DETAIL: "İşlem Detayı Görüntüle",
  SELECT_ORDER: "Sipariş seçiniz",
  PRODUCT_BARCODE: "Ürün Barkodu",
  ACCEPT: "Onayla",
  ACCEPT_ALL: "Hepsini Onayla",

};

var reportsTr = {
  REPORTS: "Raporlar",
  REPORTS_INFOMSG1: "Yetkiniz dahilinde, gösterilebilecek hiçbir rapor bulunamadı",
  REPORTS_INFOMSG2: "Tarihinde Oluşturulan Raporu",
  FILE_TYPE: "Dosya Tipi",
  CREATE_REPORT: "Raporu Oluştur",
  UPDATE_STATUS: "Durumu Güncelle",

};

var paginationTr = {
  TOTAL_RECORD_COUNT: "Toplam kayıt",
  TOTAL_PAGE_COUNT: "Toplam sayfa",
  PAGINATION_RECORD_COUNT: "Sayfalama",
  PERIOD: "Periyod",
  CALCULATING: "Hesaplanıyor",
  UPDATED_DATE: "Güncellenme Tarihi"
};

var commonTr = {
  HELP_PAGE: "Sayfa Yardımı",
  HELP_WITH_VIDEO: "Videolu Yardım",
  INSTALL_WIZARD: "Kurulum Sihirbazı",
  SYSTEM_SETTINGS: "Sistem Ayarları",
  CHANNEL_SETTINGS: "Kanal Ayarları",
  PROVIDER: "Sağlayıcı",
  CHANNEL_NAME: "Kanal Adı",
  CHANNEL_NAME_LABEL: "Sağlayıcıdaki Pazaryeri Adınız",
  DEFAULT_CURRENCY: "Varsayılan Kur",
  TAX_STATUS: "Vergi Durumu",
  HAS: "Var",
  NOT_HAS: "Yok",
  PROTOCOL: "Protokol",
  WEB_SERVICE_URL: "Web Servis URL (Örneğin; www.xyz.com)",
  API_URL: "API URL (Örneğin; https://www.xyz.com)",
  API_KEY: "API Key",
  API_SECRET: "API Secret",
  WEB_SERVICE_USERNAME: "Web Servis Kullanıcı Adı",
  WEB_SERVICE_PASSWORD: "Web Servis Kullanıcı Şifre",
  SETTINGS_INFOMSG1: "T-Soft b2c sitelerinizin web servis bilgilerini tanımlayabilirsiniz.<br>\n" +
    "                                Ayar Güncelle butonuyla \"sipariş durumları\" ve \"ödeme tipleri\" güncellemesi\n" +
    "                                yapabilirsiniz.",
  SETTINGS_INFOMSG2: "Seçili kanal ayarınızın Sipariş Durumları yüklenmemiştir. Lütfen tıklayınız.",
  SETTINGS_INFOMSG3: "Entegrasyonlar bölümde kanal seçiminden\n" +
    "                                        sonra\n" +
    "                                        otomatik olarak hangi sipariş durumlarının seçileceğini\n" +
    "                                        ayarlayabilirsiniz. <br/>Ayrıca sisteme otomatik olarak\n" +
    "                                        çekilmesini istediğiniz sipariş durumlarını varsayılan\n" +
    "                                        olarak ayarlamalısınız.",
  SETTINGS_INFOMSG4: "Ödeme tiplerini görüntülemek için lütfen üst bölümden kanal seçimini yapınız.",
  SETTINGS_INFOMSG5: "Bu bölümde B2C sitelerinizden gelen siparişlerin durumuna göre\n" +
    "                                    hangi depo üzerinden eksiltileceğini belirleyebilirsiniz.",
  SETTINGS_INFOMSG6: "Toplam Stok Miktarı Yansıtılsın.",
  SETTINGS_INFOMSG7: "Evet işaretlenmişse \"Toplam Stok Miktarı\" seçilen\n" +
    "                                            kanala gönderilecektir.<br/>\n" +
    "                                            Seçilen depo ise; Aşağıda bulunan bölümde, siparişin durumuna\n" +
    "                                            göre seçilmemiş depo<br/>\n" +
    "                                            ve hareket tipi için manuel aktarımda devreye girecek olan\n" +
    "                                            fallback depo tanımıdır.",
  SETTINGS_INFOMSG8: "B2C sitelerinize aktarılacak stok miktarlarının depo eşleştirmesini\n" +
    "                                    tanımlayabilirsiniz.\n" +
    "                                    Dilerseniz toplam stok adetini sitenize yansıtabilir.",
  SETTINGS_INFOMSG9: "Muhasebe programına aktarılacak siparişlerin kriterlerini tanımlayabilirsiniz.",
  SETTINGS_INFOMSG10: "Otomatik olarak çalışan zamanlanmış görevleri manuel olarak çalıştırabilirsiniz.",
  SETTINGS_INFOMSG11: "Kritik stok adet altındaki ürünlerin bildirimlerini\n" +
    "                                            alacağınız e-posta adreslerinizi tanımlayabilirsiniz. ( Birden\n" +
    "                                            fazla posta adresini <b>\",\"</b> ayıracı kullanarak\n" +
    "                                            tanımlayabilirsiniz. )",
  SETTINGS_INFOMSG12: "Yukarıdaki formu kullanarak siparişi hazırlayacak kullanıcıların grubunu\n" +
    "                                            değiştirebilirsiniz.",
  SETTINGS_INFOMSG13: "Yukarıdaki formu kullanarak hazırlanan siparişleri kontrol edecek kullanıcıların grubunu değiştirebilirsiniz.",
  SETTINGS_INFOMSG14: "Yukarıdaki formu kullanarak hazırlanan siparişleri kontrol edecek kullanıcıların grubunu değiştirebilirsiniz.",
  SETTINGS_INFOMSG15: "Yukarıdaki formu kullanarak sipariş hazırlama süreçlerinde durum değişikliklerini düzenleyebilirsiniz.",
  SETTINGS_INFOMSG16: "kanalında yapacağınız işlemler Polypus üzerinden oluşturulan bütün stok hareketlerinde geçerli olacaktır.",
  SETTINGS_INFOMSG17: "Stok kartında hangi alan sizin için ayırtedici ise onu seçebilirsiniz.",
  UPDATE_PARAMETER: "Parametre Güncelle",
  PROVIDER_ID: "Sağlayıcı ID",
  DEFAULT: "Varsayılan",
  PAYMENT_TYPES: "Ödeme Tipleri",
  SELECT_CHANNEL: "Kanal Seçiniz",
  ADDED_DATE: "Eklenme Tarihi",
  VALIDITY_DATE: "Geçerlilik Tarihi",
  AUTOMATIC_TRANSFER_SETTINGS: "Otomatik Aktarım Ayarları",
  DEFINE_DYNAMIC_STOCK_ACTION: "Dinamik Stok Hareketi Tanımlama",
  SELECT_ALL: "Tümünü Seç",
  REMOVE_ALL: "Tümünü Kaldır",
  SUGGEST_DYNAMIC_ACTIONS: "Önerilen Dinamik Hareketler",
  APPLIED_TRANSACTION_TYPE: "Uygulanacak İşlem Tipi",
  WAREHOUSE_CHANNEL_RELATION: "Kanal Bazlı Varsayılan Depo",
  IS_SEND_TOTAL_STOCK: "Toplam Stok Gönderilsin mi ?",
  YES: "Evet",
  NO: "Hayır",
  ERP_INTEGRATION_SETTINGS: "ERP Aktarım Ayarları",
  MISSION_NAME: "Görev Adı",
  COMMAND: "Komut",
  EXPRESSION: "Expression",
  COMMAND_ARGUMENTS: "Komut Argümanları",
  CRON_JOB: "Zamanlanmış Görevler",
  RUN: "Çalıştır",
  COMPANY_SETTINGS: "Firma Ayarları",
  NOTIFY_EMAIL_SETTING: "Bildirim E-posta Ayarı",
  EMAIL_ADDRESS: "E-posta Adresi",
  COMPANY_UPLOAD_LOGO: "Firma Logo Yükleme",
  ADD_BANKS: "Banka Ekle",
  BANKS: "Banka",
  IBAN: "IBAN",
  BRANCH_BANKS: "Firma Banka Bilgileri",
  NEW_BRANCH_TITLE: "Firma Ekle",
  BRANCH_NAME: "Firma Cari Ünvanı",
  BRANCH_NAME_2: "Ünvan",
  DEFAULT_BRANCH: "Varsayılan firma olarak ayarla",
  BRANCH_ADDRESS: "Firma Adresi",
  BRANCH_LOGO: "Logo",
  BRANCH_SIGNATURE: "İmza / Kaşe",
  BRANCH_WATERMARK: "Filigran",
  SELECTED_BRANCH: "Firma Seçin",
  BRANCH_INFO_TITLE: "Firma Bilgileri",
  BRANCH_INFO_TITLE_INFO: "Şirketinize bağlı firmaları buradan kayıt edebilirsiniz. Proforma gibi dökümanları özelleştirmek için kullanılır.",
  BRANCH_FORM_DETAIL: "Form Özellikleri",
  BRANCH_FORM_DETAIL_INFO: "Proforma ve sipariş formu gibi formlarla ilgili özellikliker belirlenir",
  ORDER_PREPARE_ROLE: "Sipariş Hazırlayan Yetki Grubu",
  CURRENT_SELECTED_ROLE: "Mevcut Seçili Grup",
  SELECT_USER_ROLE: "Kullanıcı grubu seçiniz",
  CONTROLLER_ROLE: "Sipariş Kontrolcü Yetki Grubu",
  TRANSPORTER_ORGANIZATION: "Taşıyıcı Organizasyonu",
  PREPARATION_TYPE: "Hazırlama Tipi",
  BULK_PREPARATION: "Siparişleri Toplu Hazırlama",
  DIVIDED_PREPARATION: "Siparişleri Bölümlere Ayırarak Toplama",
  MOST_ATTACH_ORDER_TO_TRANSPORTER: "Taşıyıcıya Atanacak En Fazla Sipariş",
  DEFAULT_VALUE: "Varsayılan Değer",
  FREE_TRANSPORTER: "Serbest Taşıyıcı",
  RELATION_WITH_USER: "Kullanıcıyla İlişkilendir",
  USER_RELATION_HEAD: "Kullanıcı İlişkilendirme",
  RELATION: "İlişki",
  ORDER_SYNC: "Sipariş Senkronizasyonu",
  ACTION_STATUS: "İşlem Durumu",
  DASHBOARD: "Genel Görünüm",
  ORDER_PREPARE: "Sipariş Hazırlama",
  STOCK_MANAGEMENT: "Stok Yönetimi",
  SHELF_CHANGE: "Raf Değiştir",
  SHELF_CHANGE_NAME: "Raf Değiştirme Adı",
  CHANGE_SHELF_CODE: "Gidecek Raf Kodu",
  TAKING_SHELF_CODE: "Alınacak Raf Kodu",
  // SHELF_CHANGE_ALL: "Tamamı",
  SHELF_CHANGE_ALL: "Tümü",
  SHELF_CHANGE_ACCEPT: "Rafı Değiştir",
  LOCATION_STOCKTAKING: "Depo Sayım",
  STOCKTAKING: "Stok Sayım",
  STOCKTAKING_ITEM_ACCEPT: "Onayla",
  STOCKTAKING_MAKE: "Sayım Yap",
  STOCKTAKING_CREATE: "Stok Sayımı Oluştur",
  STOCKTAKING_LIST_NAME: "Stok Sayım Adı",
  STOCKTAKING_LIST: "Stok Sayım Listesi",
  STOCKTAKING_LISTS: "Stok Sayım Listeleri",
  STOCKTAKING_DESCRIPTION: "Detay",
  BASKET_SUMMARY: "Hesap Özeti",
  STOCKTAKING_COMPLETE: "Sayımı Tamamla",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_1: "Potansiyel Hareketler",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_1_1: "Raf",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_2: "Ürünün Bulunduğu Raflar",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_3: "Giriş",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_4: "Çıkış",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_5: "Raf Adı",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_6: "Adet",
  STOCKTAKING_COMPLETED: "Tamamlandı",
  PRODUCTION: "Üretim",
  PRODUCTION_STREAMS: "Üretim Akışı",
  PRODUCTION_MOVEMENT: "Üretim Girişi",
  PRODUCTION_MOVEMENTS: "Üretim Hareketleri",
  CARGO_OPERATION: "Kargo ve Taşıma İşlemleri",
  CARGO_BARCODE: "Kargo Barkodu",
  BULK_ACTIONS: "Toplu İşlemler",
  INTEGRATIONS: "Entegrasyonlar",
  LOCATION_MANAGEMENT: "Depo ve Raf Yönetimi",
  RETURN_MANAGEMENT: "İade Yönetimi",
  SHELF_MANAGEMENT: "Raf Yönetimi",
  CATEGORY_BRAND_MANAGEMENT: "Kategori ve Marka Yönetimi",
  STOCK_ORGANIZATION: "Stok Organizasyonu",
  BRAND_MANAGEMENT: "Marka Yönetimi",
  TAG_MANAGEMENT: "Etiket Yönetimi",
  BRAND: "Marka",
  TAG: "Etiket",
  TAG_NAME: "Etiket",
  TAG_CATEGORY: "Etiket Kategorisi",
  MODEL: "Model",
  BRAND_NAME: "Marka Adı",
  BRAND_LINK: "Marka Link",
  CATEGORY_SELECT: "Kategori Seçiniz...",
  CATEGORY_TREE: "Kategori Ağacı",
  NEW_MAIN_CATEGORY: "Yeni Ana Kategori",
  NEW_MAIN_CATEGORY_NAME: "Yeni Ana Kategori Adı",
  CATEGORY_NAME: "Kategori Adı",
  NEW_SUBCATEGORY_NAME: "Yeni Alt Kategori Adı",
  USER_AND_ROLES: "Kullanıcılar ve Yetkiler",
  ROLES: "Roller",
  PERSONAL_SETTINGS: "Kişisel Ayarlar",
  CLOSE: "Kapat",
  MODULES: "Modüller",
  LOGOUT: "Çıkış",
  GENERAL_INFORMATION: "Genel Bilgiler",
  IS_WHOLESALE_PRODUCT: "Toptan ürünü mü ?",
  QUICK_PRODUCT:"Hızlı Giriş",
  QUICK_PRODUCT_ADD:"Hızlı Ürün Girişi",
  ADD_PRODUCT:'Ürün Ekle',
  SUPPLIER_CODE: "Tedarikçi Kodu",
  DESCRIPTION: "Açıklama",
  PRODUCT_SHORT_DESCRIPTION: "Ürün Kısa Açıklama",
  ENTER_PRODUCT_TAG: "Ürün etiketi giriniz",
  SUPPLIER: "Tedarikçi",
  STOCK_UNIT: "Stok Birimi",
  PRICE_INFORMATION: "Fiyat Bilgileri",
  SELLING_PRICE_WITH_EXAMPLE: "(Örn; 13.34, 1234.5)",
  SELLING_PRICE: "Satış Fiyatı",
  NUMBER_OF_SOLD: "Satılan Ürün Sayısı",
  REMAINING_NUMBER_OF_PRODUCTS: "Kalan Ürün Sayısı",
  SELLING_PRICE_VAT_INCLUDED: "KDV'li Satış Fiyatı",
  DISCOUNTED_SELLING_PRICE: "İndirimli Satış Fiyatı",
  DISCOUNTED_SELLING_PRICE_VAT_INCLUDED: "KDV'li İndirimli Satış Fiyatı",
  BUYING_PRICE: "Alış Fiyatı",
  TAX_PERCENT: "Kdv Oranı",
  CARGO_INFORMATION: "Kargo Bilgileri",
  CARGO_AND_ADD_INFORMATION: "Kargo ve Ek Bilgiler",
  SAME_ALL_CHANNEL: "Tüm Kanallar Aynı",
  SALES: "Satış",
  PURCHASE: "Satın Alma",
  PURCHASE_RECEIVING: "Satın Alma Mal Kabul",
  PURCHASE_OFFER: "Satın Alma Teklifleri",
  CHANNEL_BASED: "Kanal Bazlı",
  SEO_SETTINGS: "Seo Ayarları",
  SEO_LINK: "Seo Link",
  SEO_HEAD: "Seo Başlık",
  SEO_DESCRIPTION: "Seo Açıklama",
  PRODUCT_INFORMATION: "Ürün Bilgileri",
  PRODUCT_DETAIL: "Ürün Detay",
  ADDITIONAL_INFO1: "Ek Bilgi 1",
  ADDITIONAL_INFO2: "Ek Bilgi 2",
  ADDITIONAL_INFO3: "Ek Bilgi 3",
  ADDITIONAL_INFO4: "Ek Bilgi 4",
  ADDITIONAL_INFO5: "Ek Bilgi 5",
  MIN_ORDER_QTY: "Min. Sipariş Adeti ( Üye İçin )",
  MAX_ORDER_QTY: "Max. Sipariş Adeti ( Üye İçin )",
  LIST_NU: "Liste Numarası",
  OPTIONS: "Seçenekler",
  ACTIVE: "Aktif",
  NEW_PRODUCT: "Yeni Ürün",
  ENTRY_STOCK_QTY: "Giriş Stok A.",
  CRITIC_STOCK_QTY: "Kritik Stok A.",
  VIRTUAL_STOCK_QTY: "Sanal Stok A.",
  INVENTORY_INFORMATION: "Envanter Bilgisi",
  INVENTORY: "Envanter",
  FOLLOW_METHOD: "İzleme Yöntemi",
  NOT_FOLLOW: "İzleme yapılmıyor",
  UNIQUE_SERIAL_NU: "Tekil Seri Numarası",
  LOT: "Lot",
  SUB_PRODUCT: "Varyasyon",
  HAS_SUB_PRODUCTS: "Varyasyonlu",
  ADD_SUB_PRODUCT: "Varyasyon Ekle",
  RECIPE: "Ürün Reçetesi",
  UPDATE_RECIPE: "Reçete Güncelle",
  RECIPE_RECEIVING: "Ürün Reçetesi Mal Kabul",
  PRODUCTION_RECEIVING: "Üretim Mal Kabul",
  'Serbest Giriş Mal Kabul': "Serbest Giriş Mal Kabul",
  PACKAGE_PRODUCT_CONTENT: "Bundle",
  ADD_PACKAGE_PRODUCT_CONTENT: "Ürün Ekle",
  CREATE_STOCK: "Stok Oluştur",
  PROPERTY1: "1.Özellik",
  PROPERTY2: "2.Özellik",
  RECIPE_INFO: "Reçeteli Ürünler",
  RECIPE_INFO2: "Ürüne reçete eklerseniz stok arttırımı 'Üretim Reçetesi' bölümünden gerçekleştirilir. Reçeteye konu ürünler azaltılır, bu ürün reçeteye göre artırılır.",
  RECIPE_INFO3: "Bu ürün için bundle veya lot girişi yapıldığı için reçete uygulanamaz",
  VARIATION_PRODUCT: "Varyasyonlu Ürün",
  VARIATION_PRODUCT_LOT_MESSAGE: "Ürün varyasyonu bilgisi girildiği için, envarter bilgisini alt ürünlerde seçmelisiniz",
  VARIATION_PRODUCT_PACKAGE_MESSAGE: "Ürün varyasyonu bilgisi girildiği için, paket ütün tanımlaması yapılamaz",
  PACKAGE_PRODUCT_VARIATION_MESSAGE: "Ürünü paket ürün olarak tanımladığınız için ürün varyasyonu ekleyemezsiniz",
  PACKAGE_NOT_UPDATED_MESSAGE: "Bu ürün daha önce kayıt edildiği için paket düzenlemesi yapılamaz",
  LOT_PRODUCT_VARIATION_MESSAGE: "Ürüne izleme yöntemi tanımladığınız için ürün varyasyonu ekleyemezsiniz",
  STOCK_LIST: "Stok Listesi",
  SEARCH_IN_STOCK: "Stok kartında ara",
  SEARCH_IN_TAG: "Etikette ara",
  SELECT_BRAND: "Marka seçiniz",
  CRITICAL_STOCK: "Kritik Stok",
  MINUS_STOCK: "Eksi Stok",
  VIRTUAL_STOCK: "Sanal Stok",
  ACTIVE_PRODUCT: "Aktif Ürün",
  PROCUREMENT: "Tedarik",
  PACKAGE_PRODUCT: "Paket Ürün",
  EMPTY_STOCK: "Biten",
  NEARLY_EMPTY_STOCK: "Bitmeye Yakın",
  ENOUGH_STOCK: "Yeterli",
  SHOW_PRODUCT_CARD: "Stok Kartında Göster",
  SHOW_PRODUCT_VARIATION: "Ürünün varyasyonlarını gösterir",
  SHOW_PRODUCT_ACTION: "Ürünün stok hareketlerini gösterir",
  UPDATE_PRODUCT_CLICK: "Ürün güncelleme",
  CLONE_STOCK: "Stok Kartı Kopyalama",
  SHOW_MAIN_PRODUCT_BARCODE: "Ana ürün barkodu",
  PRINT_PRODUCT_BARCODE: "Ürün barkodu yazdır",
  PRINT_ACTION_BARCODE: "Hareket barkodu yazdır",
  DELETE_PRODUCT_CLICK: "Ürünü silmek için tıklayınız",
  PASSIVE_PRODUCT_CLICK: "Ürünü pasif etmek için tıklayınız",
  SYNC_PRODUCT_CLICK: "Ürün hedeflerini güncelleme",
  STOCK_INFOMSG1: "Excel olarak indirmek için kuyruğa gönderir",
  STOCK_INFOMSG2: "Herhangi bir ürün için lot/seri nu tanımlanmamıştır",
  STOCK_INFOMSG3: "Bu ürün için varyasyonlara ait stok bilgisi bulunmamaktadır",
  STOCK_INFOMSG4: "Bu stok kartında hiç hareket gözükmemektedir",
  STOCK_INFOMSG5: "Stok hareketi gözükmemektedir",
  STOCK_INFOMSG6: "Herhangi bir ürün için lot/seri nu gözükmemektedir",
  STOCK_INFOMSG7: "Bu üründe lot/seri takibi yapılmamaktadır.",
  STOCK_INFOMSG8: "Bu ürüne ait ürün reçetesi bulunmamaktadır.",
  STOCK_INFOMSG9: "İş emri oluşturulmamış stok hareketi bulunmamaktadır.",
  SHOW_PRODUCT_LOT: "Ürünün Lot/Seri Numaralarını Göster",
  CREATE_LOT_SERIAL: "Lot/Seri Oluştur",
  LOT_SERIAL_NUMBERS: "Lot/Seri Numaraları",
  LOT_SERIAL_NUMBER: "Lot/Seri Numarası",
  ESTIMATED_DATE: "S.K. Tarihi",
  VARIATION_DISTRIBUTION: "Varyasyon Dağılımı",
  STOCK_CARD: "Stok Kartı",
  SUB_PRODUCT_CODE: "Alt Ürün Kodu",
  PROPERTIES1: "Özellik 1",
  PROPERTIES2: "Özellik 2",
  PRODUCT_ACTIONS: "Ürün Stok Hareketleri",
  MOVEMENT_DIRECTION: "Hareket Yönü",
  STATISTIC_REPORT: "İstatistik Raporu",
  SELECT_VARIATION: "Varyasyon seçiniz",
  UNIT_PRICE: "Birim Fiyatı",
  PRODUCT_PROPERTIES: "Ürün Özellikleri",
  REAL_STOCK_QUANTITY: "Gerçek Stok Adedi",
  PRODUCT_DELETED: "Ürün Silinmiş",
  TAG_ORDER: "Etiket Sıralama",
  CATEGORY_TAG_CODE: "Kategorik Etiket Kodu",
  NAME_SURNAME: "Ad Soyad",
  CREATE: "Oluştur",
  ROLE: "Yetki",
  ROLE_LIST: "Rol Listesi",
  CREATE_ROLE_GROUP: "Yeni Rol Oluştur",
  ROLE_NAME: "Rol Adı",
  PERMISSIONS: "İzinler",
  SELECT_NATIVE: "Seç",
  ORDER_LIST_ACCEPTING: "Sipariş Listesi Onayı",
  PAYMENT_INFO: "Ödeme Bilgisi",
  ORDER_NATIVE: "Sıra",
  PRICE_WITH_VAT: "Fiyat (KDV DAHİL)",
  PRODUCT_TAG: "Ürün Etiketi",
  PRODUCT_TAGS: "Ürün Etiketleri",
  CUSTOMER_NOTE: "Müşteri Notu",
  MISSING_QTY: "Eksik Miktarı",
  MISSING_REPORT: "Eksik Bildirilen",
  IS_IN_STOCK: "Ürün Stokta mı?",
  COMPLETE: "Bitir",
  MISSING_PRODUCT_LIST: "Eksik Ürün Listesi",
  MISSING_PRODUCTS: "Eksik Ürünler",
  CREATE_ORDER: "Sipariş Oluştur",
  REVERT_ACTION_NATIVE: "İşlemi Etkisiz Kıl",
  INVOICE_INFOMSG1: "Sipariş için eksik ürün bilgisi girilmemiştir",
  INVOICE_INFOMSG2: "Stok Hareketi ile sipariş oluşturabilirsiniz",
  ARRIVAL_PERSONAL: "Teslim Alacak Kişi",
  ORDER: "Sipariş",
  ORDER_DETAIL: "Sipariş Detayı",
  CRITICAL_STOCK_LEVEL: "Kritik Stok Seviyesi",
  WAREHOUSE_MANAGEMENT: "Depo Yönetimi",
  WAITING: "Beklemede",
  CARGO_READY: "Kargo Hazır",
  IN_WAITING: "Bekliyor",
  IN_READY: "Hazırlanan",
  IN_COMPLETED: "Tamamlanan",
  IN_TRANSFER: "Aktarıldı",

  STATUS_WAIT: "Süreç Devam Ediyor",
  STATUS_OFFER_APPROVE: "Teklif Onaylandı",
  STATUS_OFFER_CREATE_ORDER: "Siparişi Oluşturuluyor",
  STATUS_OFFER_DONE: "Teklif Süreci Tamamlandı",
  STATUS_OFFER_CANCEL: "İptal Edildi",

  MINUTE: "Dakika",
  PLATFORM: "Platform",
  STOP_ADD_PRODUCT: "Ürün Eklemeyi Durdur",
  START_ADD_PRODUCT: "Ürün Eklemeye Başla",
  FILTER_DETAIL: "Detaylı Filtreler",
  OK: "Tamam",
  ATTACH: "Ata",
  BULK_PRINT: "Toplu Baskı Al",
  SELECT_PREPARE_USER: "Hazırlayıcı Seç",
  SELECT_ASSIGN_ACTION_LIST: "Listeler",
  NEW_ACTION_LIST: "Yeni Liste",
  ACTION_LIST_NAME: "Liste İsmi",
  NEW_ACTION_LIST_PRIORITY_NORMAL: "Standart",
  NEW_ACTION_LIST_PRIORITY_HIGH: "Öncelikli",
  NEW_ACTION_LIST_PRIORITY_VERY_HIGH: "Yüksek Öncelikli",
  NEW_ACTION_LIST_PRIORITY_URGENT: "Acil",
  USERS: "Kullanıcılar",
  ENTER_INVENTORY_SEARCH_CRITERIA: "Aramak için F3 tuşuna basın",
  SHOW_CHANNEL_DETAIL: "Kanal detay bilgisi",
  PROFILE_SETTINGS: "Profil Ayarları",
  ACCOUNT_SETTINGS: "Hesap Ayarları",
  TIMEZONE: "Zaman Dilimi",
  SELECT_TIMEZONE: "Zaman dilimi seçiniz",
  LANGUAGE: "Dil",
  SELECT_LANGUAGE: "Dil seçiniz",
  GENERAL_SETTINGS: "Genel Ayarlar",
  RELEASE_NOTES: "Sürüm Notları",
  SUPPORT: "Destek",
  ABOUT_US: "Hakkımızda",
  PREFERRED_CODE: "Ürün Ayırdedici Kod",
  PREFERRED_PRODUCT_CODE: "Tercih Edilen Ürün Ayırdedici Kod",
  RELOAD: "Yenile",
  LOADING: "Yükleniyor",
  SHELVE_SEARCH_INFO: " İşlem Yapılacak Rafı Arama",
  SHELVE_SEARCH_INFO2: "Raf Kodu Arama Bölümümüz ile İşlem Yapmak İstediğiniz Rafı Aratabilirsiniz",
  OFFER_SEARCH_INFO: " Teklif Arama",
  OFFER_SEARCH_INFO2: "Arama Kriterlerini Kullanarak Teklifleri Aratabilirsiniz",

  STOCKTAKING_INFO: "Stok Sayım",
  STOCKTAKING_INFO2: "Stok Sayım",
  STOCKTAKING_PRODUCT_INFO_EMPTY: "Henüz bu ürün için sayım oluşturulmamış",

  OFFER_PRODUCT_INFO: "Aktif Tekliflerdeki Adetler",
  OFFER_PRODUCT_INFO2: "Henüz kesinleşmemiş tekliflerde bulunan, bu ürüne ait adetler",

  RECEIVING_INFO: " Mal Kabul",
  RECEIVING_INFO2: "Henüz Bekleyen mal kabul süreci yok",

  RETURN_OFFER_SCREEN_INFO1: 'İade Oluşturma',
  RETURN_OFFER_SCREEN_INFO2: 'Aramayı sipariş numarası, kargo numarası ve sipairiş id si ile yapabilirsiniz',

  SORTING: "Sıralama",
  CHANGE_SORTING: "Sıralamayı Değiştir",
  PRODUCT_IMAGE_PATH: "Ürün Resim Url",
  DEFAULT_PRODUCT_IMAGE_UPLOAD: "Varsayılan Resim Yolu",
  ANALYTICS : 'ANALİZ',
  WITH_VAT : 'KDV Dahil',
  PRODUCT_PRICE_NO_DISCOUNT : 'İndirimsiz Ürün Fiyatı',
  ANALYTICS_EMPTY_INFO : 'Veri Yok',
  ANALYTICS_EMPTY_INFO2 : 'Analizle ilgili henüz veri oluşmamış',
  NEW: 'Yeni',
  EXISTS:'Mevcut',
  MATCH: 'Eşle',
  IMAGE : 'Resim',
  COUNTED : 'Sayılan',
  RECEIVING_MESSAGE_INFO : 'Son kullanma tarihi ve seri numara bilgiler..',
  RECEIVING_MESSAGE_INFO2 : 'Ürünlerin formu bozulması gibi durumlarda göres olarak kanıtı',
  PRODUCT_EXPIRE_DATE : 'Son Kullanma Tarihi',
  SELECT_PRODUCT : 'Ürünü Seç',
  STOCK_MOVEMENT_SUMMARY: 'Stok Hareket Özeti',
  PRODUCT_SALES_REPORT: 'Ürün Satış Raporu',
  TURNOVER_REPORT: 'Ciro Raporu',
  SUMMARY_LIST_TYPE : 'Özet Liste Biçimi',
  SUMMARY_LIST_TYPE_MARKETPLACE : 'Pazaryerleri Sütun Olarak',
  SUMMARY_LIST_TYPE_DEFAULT : 'Varsayılan',
  VENDOR_CODE: 'Tedarikçi Kodu',
  SELECT_VENDOR: 'Tedarikçi Bilgileri',
  VENDOR: 'Tedarikçi',
  EXCHANGE_RATE_INFO : 'Döviz İşlemleri',
  EXCHANGE_RATE_INFO2 : 'Para birimlerinin değişim değerlerini buradan görebilir ve düzeltebilrisiniz. Değer girmediğiniz dövizler varsayılan 1 yazılır',

  EMPTY_PURCHASE_REQUEST_INFO : 'Satın alma Talebi',
  EMPTY_PURCHASE_REQUEST_INFO2 : 'Aradığınız kriterlerde ürün bulunamadı. Arama kriterlerinizi değiştirerek tekrrar deneyiniz.',
};

var offerTypeTr = {
  sales: 'Satış',
  purchase: 'Satın Alma',
  return_inside: 'İçeriden İade',
  return_outside: 'Dönen İade',
  missing_action: 'Eksik Bildirilen',
  OFFER_RECIPE : 'Üretim Teklifi',

  PRODUCTION_RECIPE: 'Üretim Reçetesi',
  NEW_PRODUCTION_RECIPE : 'Yeni Üretim Reçetesi',

  PRODUCTION_OFFER : 'Üretim Teklifi Taslğı',
  NEW_PRODUCTION_OFFER : 'Yeni Üretim Teklifi Taslğı',
  PURCHASE_PROCESS: 'Tedarik Sürecinde'
};

var langTr = angular.merge(
    MenuTr,
    accountSettingsTr,
    cargoTr,
    customersTr,
    dashboardIndexTr,
    integrationPageTr,
    batchOperationsTr,
    invoicesTr,
    locationTr,
    loginTr,
    batchOperationsForTsoftTr,
    actionTr,
    notificationTr,
    ordersPrepareTr,
    reportsTr,
    commonTr,
    paginationTr,
    offerTypeTr,
);

export {langTr};
