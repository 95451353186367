export function TimeElapsedFilter() {
    'ngInject';

	 return function( input ){

		 const now = new Date();
		 const createdAtDate = new Date(input);
		 const timeDiff = Math.abs(now - createdAtDate);
		 const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

		 const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		 const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
		 const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

		 if (days > 0) {
			 if (hours === 0) {
				 return `${days} gün önce`;
			 } else {
				 return `${days} gün, ${hours} saat önce`;
			 }
		 } else if (hours > 0) {
			 if (minutes === 0) {
				 return `${hours} saat önce`;
			 } else {
				 return `${hours} saat, ${minutes} dakika önce`;
			 }
		 } else if (minutes > 0) {
			 if (seconds === 0) {
				 return `${minutes} dakika önce`;
			 } else {
				 return `${minutes} dakika, ${seconds} saniye önce`;
			 }
		 } else {
			 return `${seconds} saniye önce`;
		 }
	 }
}
