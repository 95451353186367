import "./authorize.scss"
class AuthorizeController {
    constructor($auth, API, ToastService, $state, $stateParams, UserService) {
        'ngInject';

        this.$auth = $auth;
        this.API = API;
        this.$state = $state;
        this.ToastService = ToastService;
        this.$stateParams = $stateParams;
        this.UserService = UserService;
    }

    $onInit(){
        this.client_id = this.$stateParams.client_id;
        this.token = this.$stateParams.token;
        this.submit();
    }

    submit() {
        this.API.all('auth/authorize').post({
            client_id: this.client_id,
            token: this.token
        }).then((response) => {
            this.$auth.setToken(response.data.token);
            this.UserService.getUserObject().then(r=>{
                this.ToastService.success('Başarıyla giriş yapıldı.');
                //this.$state.go('app.dashboard')
                this.$state.go('app.landing');
            })
        });
    }
}

AuthorizeController.$inject = ["$auth", "API", "ToastService", "$state", "$stateParams", "UserService"];
export const AuthorizeComponent = {
    templateUrl: './views/angular/app/components/authorize/authorize.component.html',
    controller: AuthorizeController,
    controllerAs: 'vm',
    bindings: {}
}
