import {openIntroductionVideo} from "../../../utils/introduction-videos";
import "./settings.scss";
import angular from "angular";

class SettingsController {
    constructor(API, ToastService, DialogService, FileUploader, $window, $state, $location, $rootScope,$scope) {
        "ngInject";

        this.API = API;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.transferSettingsSelectedStats = this.orderTransferToPolyStat = this.roles = [];
        this.$window = $window;
        this.uploader = new FileUploader({
            url: $window.__env.apiUrl + "/api/settings/save-firm-logo",
            headers: {
                Authorization: "Bearer " + this.$window.localStorage.satellizer_token,
            },
        });
        this.$state = $state;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.video = this.selectedTabIndex = this.defaultChannelId = undefined
        this.statsForEdit = this.paymentTypesForEdit = this.orderStatus = this.paymentType = [];
        this.isShowRecommendedOrderTransferSettings = false;
        this.modifySchedue = false; // sadece üst seviye adminlerin true olmalı, daha sonra ayarlanacak
        this.channelProviders = [
            {id: 'default', name: 'Polypus'},
            {id: 'tsoft', name: 'T-Soft'},
            {id: 'ikas', name: 'Ikas'},
            {id: 'shopiverse', name: 'ShopiVerse'},
            {id: 'shopify', name: 'Shopify'},
            {id: 'entegra', name: 'Entegra'},
            {id: 'vsrm', name: 'Vsrm'},
        ];
        this.channelProvider = 'default';
        this.actionStatusSettings = {};
        this.branches= [];

        this.branch = {
            name            : null,
            address         : null,
            banks           : [],
            logo            : null,
            watermark       : null,
            default         : 0,
            packageDetail   : 1,
            productImage    : 0,
        }
        //this.preferredProductCode = 'ts_product_code';
        this.wmsSettings = {};
    }

  $onInit() {
    this.toggleStatusSaveForm = this.togglePaymentTypeSaveForm = false;

    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;


    this.API.all("settings/wms-settings").doGET()
      .then((response) => {
        if (204 === response.response.status) return;
        this.groupsNameList = response.data.list;
        this.wmsSettings.roleIdForOrderPreparing = this.groupsNameList['prepare-order'].map(item => {
          return item.id;
        });
        this.wmsSettings.roleIdForOrderController = this.groupsNameList['controller-order'].map(item => {
          return item.id;
        });
        this.wmsSettings.transporterDebit = this.groupsNameList['transporter-organization']['debit'];
        this.wmsSettings.transporterOrganizationPattern = this.groupsNameList['transporter-organization']['pattern'];
        this.wmsSettings.assignCount = this.groupsNameList['transporter-organization']['assignCount'];

        this.wmsSettings.preferredProductCode = this.groupsNameList['preferred-product-code'];
        this.wmsSettings.currencyDigit = this.groupsNameList['currency-digit'];
        this.wmsSettings.notifyEmails = this.groupsNameList['notify-emails'];
      });

    this.API.all("settings/branch").doGET()
      .then((response) => {
        if (204 === response.response.status) return;
        this.branches = response.data.list

        this.defaultBranch = response.data.list.filter(f => f.default)[0].id
      });

    this.transferSettingsSelectedStats = [];
    this.API.all("settings/get-payment-types-by-channel-id")
      .post()
      .then((response) => {
        this.paymentType = response.data.paymentTypes;
      });

    var parent = this;
    this.API.all("settings/get-channels")
      .post()
      .then((response) => {
        parent.channels = response.data.channels;
        if (parent.channels.length > 0) {
          parent.defaultChannelId = parent.channels[0].id;

          // kanal selectlerinin model initleri
          parent.channelForStatusSearch = parent.defaultChannelId;
          parent.channelForPaymentTypeSearch = parent.defaultChannelId;
          parent.orderTransferToPolyChannel = parent.defaultChannelId;
          parent.stockTransferToB2cStockChannel = parent.defaultChannelId;
          parent.transferSettingsChannel = parent.defaultChannelId;
          parent.statsForEdit = parent.orderStatus[parent.defaultChannelId];
        } else {
          throw new Error("Kanal yok");
        }
      })
      .then((response) => {
        this.API.all("settings/get-stats")
          .post()
          .then((response) => {
            if (this.channels.length === 1) {
              this.orderStatus[this.defaultChannelId] = response.data.statsArray;
            } else {
              this.orderStatus = response.data.statsArray;
            }
          });
        this.API.all("action/get-payment-types")
          .post()
          .then((response) => {
            this.paymentTypes = response.data.types;
          });
      })
      .catch((response) => {
      });

    this.API.all("location")
      .customGET()
      .then((response) => {
        if (response.errors === false) {
          this.locations = response.data.location;
        }
      });


    this.dropdownStatsTranslationsText = {
      buttonDefaultText: "Sipariş durumlarını seçiniz...",
      searchPlaceholder: "Durum Ara",
      uncheckAll: "Seçimleri Kaldır",
      checkAll: "Hepsini Seç",
      dynamicButtonTextSuffix: " durum seçildi.",
    };
    this.dropdownStatsSettings = {enableSearch: true};

    this.API.all("settings/get-transfer-settings-for-accounting")
      .doPOST()
      .then((response) => {
        this.transferSettingsForAccounting = response.data.settingsArr;
      });

    this.API.all("settings/scheduler")
      .doGET()
      .then((response) => {
        this.schedulers = response.data.schedulers;
      });

    this.API.all("settings/get-stock-transfer-settings-to-b2c")
      .doPOST()
      .then((response) => {
        this.transferSettingsForB2c = response.data.settingsArr;
      });

    this.API.all("settings/get-order-transfer-settings-to-poly")
      .doPOST()
      .then((response) => {
        this.orderTransferSettingsForPoly =
          response.data.settingsForOrderTransferToPolyArr;
      });

    this.API.all("action-type")
      .customGET()
      .then((response) => {
        this.actionTypes = response.data.types;
      });

    this.API.all("user")
      .customGET("roles")
      .then((response) => {
        this.roles = response.data.roles_data_with_perm;
      });

    this.API.all("settings/currency")
      .customGET()
      .then((response) => {
        this.currency = response.data.list;
      });
  }


    onSelectTab(obj) {
        this.$location.search(obj);
        this.video = undefined;
        if (obj.tab == 0) {
            this.video = 'settings';
        }
        if (obj.tab == 3) {
            this.video = 'settingsAutomaticTransfer';
        }
        if (obj.tab == 4) {
            this.video = 'settingsCronjob';
        }
        if (obj.tab == 6){
            this.$scope.$emit('currencyEvent', obj)
        }
    }

    startElasticSearch() {
        this.API.all("settings/start-elastic-search")
            .doPOST()
            .then((response) => {
                this.ToastService.success(response.data.status);
            });
    }

    saveTransferSettings() {
        let reqObject = {
            transferToAccountingId: this.transferToAccountingId,
            selectedStats: this.transferSettingsSelectedStats,
            paymentType: this.transferSettingsPaymentType,
            transferSettingsChannel: this.transferSettingsChannel,
        };

        this.API.all("settings/save-transfer")
            .post(reqObject)
            .then((response) => {
                this.transferSettingsForAccounting = response.data.settingsArr;
                this.ToastService.success("Muhasebe Programı için otomatik aktarım ayarları girildi.");
            });
    }

    saveChannel() {
        let reqObject = {
            channelId: this.channelId,
            provider: this.channelProvider,
            channelName: this.channelName,
            protocol: this.protocol,
            webServiceHost: this.webServiceHost,
            webServiceUsername: this.webServiceUsername,
            webServicePass: this.webServicePass,
            currencyId: this.channelCurrencyId,
            vat: this.channelVatExist,
        };
        var parent = this;

      if (this.channelProvider == 'default') {
        parent.ToastService.error(
          "Varsayılan kanal tekrar açılamaz"
        );
      } else {
        this.API.all("settings/channel")
          .post(reqObject)
          .then((response) => {
            this.channels = response.data.channels;
            this.channelId = this.channels[0].id;
            this.ToastService.success(
              "Satış kanalının web servis ayarları girildi."
            );
          })
          .then((response) => {
            if (reqObject.provider != 'default') {
              parent.syncChannel(this.channelId);
            }
          })
          .then((response) => {
            parent.clearChannelForm();
          })
          .catch((err) => {
            parent.ToastService.error(
              "Satış kanalının ayarlarında sorun var."
            );
          });
      }

    }

    deleteChannel(channelId) {
        this.DialogService.confirm(
            "Onay Mesajı",
            "Bu kanalı silmek istediğinize emin misiniz?"
        ).then(
            () => {
                var reqData = {
                    channel_id: channelId,
                };

                var parent = this;

                this.API.all("settings/channel/delete")
                    .post(reqData)
                    .then((response) => {
                        this.channels = response.data.channels;
                    });
            },
            () => {
            }
        );
    }

    setChannelIsActive(channelId, channelIsActive) {
        let reqObject = {
            channelId: channelId,
            isActive: channelIsActive,
        };
        var parent = this;

        this.API.all("settings/channel-active")
            .post(reqObject)
            .then((response) => {
                this.ToastService.success(
                    "Satış kanalı aktif/pasif ayarı yapıldı."
                );
            })
            .catch((err) => {
                parent.ToastService.error(
                    "Satış kanalı aktif/pasif ayarlanamadı"
                );
            });
    }

    deleteTransferSettingForAccounting(id) {
        this.DialogService.confirm(
            "Onay Mesajı",
            "Muhasebeye otomatik aktarım ayarını silmek istediğinize emin misiniz?"
        ).then(
            () => {
                var reqData = {
                    id: id,
                };

                var parent = this;

                this.API.all("settings/transfer-setting-for-accounting/delete")
                    .post(reqData)
                    .then((response) => {
                        //this.channels = response.data.channels;
                        this.transferSettingsForAccounting = response.data.settingsArr;
                    });
            },
            () => {
            }
        );
    }

    editTransferSettingForAccounting(id, channel_id) {
        let reqObject = {
            id: id,
        };

        let reqObject2 = {
            channelId: channel_id,
        };
        this.transferSettingsSelectedStats = [];

        this.API.all("settings/get-stats")
            .post(reqObject2)
            .then((response) => {
                this.stats = response.data.statsArray;
            })
            .finally((response) => {
                this.API.all("settings/get-transfer-settings-for-accounting")
                    .post(reqObject)
                    .then((response) => {
                        this.transferToAccountingId = response.data.settingsArr.id;

                        this.transferSettingsChannel = response.data.settingsArr.channel_id;
                        this.transferSettingsPaymentType =
                            response.data.settingsArr.auto_transfer_payment_type;
                        var self_ = this;
                        self_.transferSettingsSelectedStats = new Array();

                        angular.forEach(
                            response.data.settingsArr.auto_transfer_order_status,
                            function (category, key) {
                                key = self_.findElement(self_.stats, category.id);
                                if (key !== "" && key != -1)
                                    self_.transferSettingsSelectedStats.push(self_.stats[key]);
                            }
                        );
                    });
            });
    }

    editChannel(channelId) {
        let reqObject = {
            channel_id: channelId,
        };

        this.API.all("settings/get-channels")
            .post(reqObject)
            .then((response) => {
                this.channelId = response.data.channels.id;
                this.channelName = response.data.channels.name;
                this.protocol = response.data.channels.protocol;
                this.webServiceHost = response.data.channels.uri;
                this.webServiceUsername = response.data.channels.web_service_username;
                //this.webServicePass = response.data.channels.web_service_password;
                this.channelCurrencyId = response.data.channels.currency;
                this.channelVatExist = response.data.channels.vat;
                this.channelProvider = response.data.channels.provider;
                document.documentElement.scrollTop = 0;
            });
    }

    changeChannelProvider() {
      this.webServiceHost = '';
      if (this.channelProvider === 'ikas') {
        this.webServiceHost = 'https://api.myikas.com/api/v1/admin/graphql';
      }
      if (this.channelProvider === 'shopify') {
        this.webServiceHost = 'https://{shop}.myshopify.com/admin/api/2021-07/graphql.json';
      }
    }
    syncChannel(channelId) {
        this.channelForStatusTransfer = channelId;
        this.saveOrderStatusFromTsoft();
        this.channelForPaymentTypeTransfer = channelId;
        this.savePaymentTypeFromTsoft();
    }

    saveScheduler() {
        var reqData = {
            id: this.schedulerId,
            name: this.schedulerName,
            command: this.schedulerCommand,
            arguments: this.schedulerArgument,
            cron_expression: this.schedulerCronExpression,
        };

        this.API.all("settings/scheduler")
            .post(reqData)
            .then((response) => {
                if (response.data.error == false) {
                    this.ToastService.success("Eksik alanlarınız var");
                }
            });
    }

    disableChange(id, disabled) {
        let reqData = {
            id: id,
            disabled: disabled
        };

        this.API.all("settings/scheduler/" + id)
            .post(reqData)
            .then((response) => {
                this.ToastService.success(response.data.msg);
            })
            .catch(this.statusChangeFailed.bind(this));
    }

    editScheduler(id) {
        this.API.all("settings/scheduler/" + id)
            .get()
            .then((response) => {
                this.schedulerId = response.data.scheduler.id;
                this.schedulerName = response.data.scheduler.name;
                this.schedulerCommand = response.data.scheduler.command;
                this.schedulerArgument = response.data.scheduler.argument;
                this.schedulerCronExpression = response.data.scheduler.cron_expression;
            });
    }

    runSchedulerManuel(commandId) {
        let reqObject = {
            command_id: commandId,
        };

        this.API.all("queue/call")
            .post(reqObject)
            .then((response) => {
                this.queueResult = response.data;
                this.ToastService.success("Komut çalıştırılmak üzere kuyruğa alındı. Size haber vereceğiz.");
            });
    }

    showSchedulerLog(commandId) {
        var options = {
          controller        : 'SettingsLogController',
          controllerAs      : 'vm',
          //  scope             : this.$scope,
          locals            : {
            commandId     : commandId,
          },
          bindToController  : true,
          multiple          : true,
          onShowing         : function (scope, element, options, controller){
            controller.getSchedulerLog()
          },
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
          './views/angular/app/components/settings/partials/settings-logs.component.html',
          options
        );
        customerDialogPromise.catch(err=>{
          console.log(err)
        })
    }


    clearChannelForm() {
        this.channelId = this.channelName = this.webServiceHost = this.webServiceUsername = null;
        this.protocol = 0;
        this.webServicePass = this.channelCurrencyId = this.channelVatExist = null;
    }

    clearB2CForm() {
        this.orderTransferToPolyChannel = this.orderTransferToPolyStat = this.orderTransferToPolyPaymentType = null;
        this.orderTransferToPolyStockLocation = this.orderTransferToPolyActionType = null;
    }

    clearAccountingForm() {
        this.transferSettingsChannel = this.transferSettingsSelectedStats = this.transferSettingsPaymentType = null;
    }

    clearTSoftOrderStatus() {
        this.channelForStatusSearch = null;
        this.statsForEdit = [];
    }

    clearTSoftChannel() {
        this.channelForStatusTransfer = null;
    }

    clearPaymentTypes() {
        this.channelForPaymentTypeSearch = null;
        this.paymentTypesForEdit = [];
    }

    clearPaymentTypesAdd() {
        this.channelForPaymentTypeTransfer = null;
    }

    saveStockTransferToB2CSettings() {
        let reqObject = {
            id: this.stockTransferToB2cId,
            channel_id: this.stockTransferToB2cStockChannel,
            location_id: this.stockTransferToB2cStockLocation,
            total_stock: this.stockTransferToB2cTotalStock,
        };

        this.API.all("settings/save-stock-transfer-settings-to-b2c")
            .post(reqObject)
            .then((response) => {
                this.transferSettingsForB2c = response.data.settingsArr;
                this.clearSaveStockTransferToB2CSettings();
            });
    }

    clearSaveStockTransferToB2CSettings() {
        this.stockTransferToB2cStockChannel = this.stockTransferToB2cStockLocation = null;
    }

    deleteTransferSettingForB2c(id) {
        this.DialogService.confirm(
            "Onay Mesajı",
            "B2C sitesine otomatik stok aktarım ayarını silmek istediğinize emin misiniz?"
        ).then(
            () => {
                var reqData = {
                    id: id,
                };

                var parent = this;

                this.API.all("settings/stock-transfer-settings-to-b2c/delete")
                    .post(reqData)
                    .then((response) => {
                        //this.channels = response.data.channels;
                        this.transferSettingsForB2c = response.data.settingsArr;
                    });
            },
            () => {
            }
        );
    }

    editTransferSettingForB2c(id) {
        let reqObject = {
            id: id,
        };

        this.API.all("settings/get-stock-transfer-settings-to-b2c")
            .post(reqObject)
            .then((response) => {
                this.stockTransferToB2cStockChannel =
                    response.data.settingsArr.channel_id;
                this.stockTransferToB2cStockLocation =
                    response.data.settingsArr.location_id;
                this.stockTransferToB2cTotalStock = parseInt(
                    response.data.settingsArr.send_total_stock
                );
                document.documentElement.scrollTop = 0;
            });
    }

    saveOrderStatusFromTsoft() {
        let reqObject = {
            channelId: this.channelForStatusTransfer,
        };

        this.API.all("settings/save-status-for-channel")
            .post(reqObject)
            .then((response) => {
                this.ToastService.success("Sipariş durumları aktarıldı.");
            })
    }

    findElement(array, id) {
        var returnKey = -1;
        angular.forEach(array, function (value, key) {
            if (id == value.id) {
                returnKey = parseInt(key);
            }
        });

        return returnKey;
    }

    saveDefaultStatus(is_default, status_id) {
        let reqObject = {
            is_default: is_default,
            status_id: status_id,
        };

        this.API.all("settings/set-default-stats")
            .post(reqObject)
            .then((response) => {
                let msg_suffix = "";
                if (is_default) {
                    msg_suffix = " durumu varsayılan olarak ayarlandı.";
                } else {
                    msg_suffix = " durumu ön seçimden kaldırıldı.";
                }
                this.ToastService.success(
                    '"' + response.data.orderStatus.ts_order_status + '"' + msg_suffix
                );
            });
    }

    deleteStatus(status_id) {
        this.DialogService.confirm(
            "Uyarı",
            "Bu durumu silmek istediğinize emin misiniz? Silinecek durum otomatik aktarım ayarlarından kaldırılmayacaktır. Lütfen otomatik aktarım ayarlarınızı gözden geçiriniz."
        ).then(() => {
                var reqData = {
                    id: status_id,
                };

                var parent = this;

                this.API.all("settings/status/delete")
                    .post(reqData)
                    .then((response) => {
                        this.API.all("settings/get-stats")
                            .post({})
                            .then((response) => {
                                parent.orderStatus = response.data.statsArray;
                            });
                    });
            },
            () => {
            }
        );
    }

    saveOrderTransferToPolySettings() {
        let reqObject = {
            id: this.orderTransferToPolyId,
            channel_id: this.orderTransferToPolyChannel,
            order_status: this.orderTransferToPolyStat,
            location_id: this.orderTransferToPolyStockLocation,
            action_type_id: this.orderTransferToPolyActionType,
            payment_types: this.orderTransferToPolyPaymentType,
        };

        this.API.all("settings/save-order-transfer-settings-to-poly")
            .post(reqObject)
            .then((response) => {
                this.orderTransferSettingsForPoly =
                    response.data.settingsForOrderTransferToPolyArr;
            });
    }

    showRecommendedOrderTransferSettings() {
        this.isShowRecommendedOrderTransferSettings = !this.isShowRecommendedOrderTransferSettings;
    }

    selectAllOptionsByModel(model, channelId) {
        if (this.paymentType[channelId] !== undefined) {
            this[model] = this.paymentType[channelId]
                .map(function (item) {
                    return item.payment_type_id
                });
        }
    }

    deselectAllOptionsByModel(model) {
        this[model] = null;
    }

    clearSelectedOrdersStatus(model) {
        this[model] = [];
    }

    deletetransferSettingForPoly(id) {
        this.DialogService.confirm(
            "Onay Mesajı",
            "Bu ayar silindikten sonra belirtilen durumlarda gelen siparişlerin ürünleri varsayılan olarak yukarıda eşleştirilen depodan sipariş durumu gözetilmeden düşülecektir. Bu ayarı kaldırmak istediğinizde emin misiniz?"
        ).then(
            () => {
                var reqData = {
                    id: id,
                };

                var parent = this;

                this.API.all("settings/order-transfer-settings-to-poly/delete")
                    .post(reqData)
                    .then((response) => {
                        //this.channels = response.data.channels;
                        this.orderTransferSettingsForPoly =
                            response.data.settingsForOrderTransferToPolyArr;
                    });
            },
            () => {
            }
        );
    }

    savePaymentTypeFromTsoft() {
        let reqObject = {
            channelId: this.channelForPaymentTypeTransfer,
        };

        this.API.all("settings/save-payment-types-for-channel")
            .post(reqObject)
            .then((response) => {
                this.ToastService.success("Ödeme tipleri aktarıldı.");
            });
    }

    openIntroductionVideoModal(video) {
        openIntroductionVideo(video);
    }

    selectTab() {
        var parent = this;
        this.loading = false;
        this.result = false;

        this.uploader.onErrorItem = function (fileItem, response, status, headers) {
            parent.ToastService.error(response.errors.message[0]);
        };

        this.uploader.onAfterAddingAll = function (item) {
            parent.addedFileName = item[0].file.name;
        };

        this.uploader.onBeforeUploadItem = function () {
            parent.loading = true;
        };

        this.uploader.onCompleteItem = function (item, response, status, headers) {
            parent.loading = false;
            if (response.data !== undefined) {
                parent.exportReportFilename = response.data.exportReportFilename;
                parent.result = true;
            }

            parent.uploader.destroy();
            parent.ToastService.success("Firma logosu yüklendi.");
            parent.addedFileName = null;
        };
    }

    createFileUploader() {
        this.uploader = new this.fileUploader({
            url: "settings/save-firm-logo",
            headers: {
                Authorization: "Bearer " + this.$window.localStorage.satellizer_token,
            },
        });
    }

    deleteFirmLogo() {
      this.API.all("settings/delete-firm-logo")
        .post()
        .then((response) => {
          this.ToastService.success("Yüklediğiniz logo silindi");
        });
    }

    saveWmsSettings() {
      this.API.all("settings/wms-settings")
        .post({
            settings: {
                'prepare-order': this.wmsSettings.roleIdForOrderPreparing,
                'controller-order': this.wmsSettings.roleIdForOrderController,
                'transporter-organization': {
                    'debit': this.wmsSettings.transporterDebit,
                    'pattern': this.wmsSettings.transporterOrganizationPattern,
                    'assignCount': this.wmsSettings.assignCount
                },
                'preferred-product-code': this.wmsSettings.preferredProductCode,
                'currency-digit': this.wmsSettings.currencyDigit,
                'notify-emails': this.wmsSettings.notifyEmails,
                'action-state-changes' : this.wmsSettings.actionStateChanges,
            }
        })
        .then((response) => {
          this.ToastService.success('Ayar Kayıt Edildi');
        });
    }

    wmsSettingForActionStatusControl(settings, itemId) {
        return Object.keys(settings).filter(s => settings[s].state === itemId).length === 0
    }

    prepareChannelActionStateRole(channelId) {
        if (!this.actionStatusSettings[channelId]) this.actionStatusSettings[channelId] = {
            actionCreate: {
                label: 'Sipariş Polypus a düştüğünde',
                state: null,
            },
            actionAssigning: {
                label: 'Sipariş kullanıcıya atandığında',
                state: null,
            },
            actionPrepare: {
                label: 'Sipariş toplama tamamlandığında',
                state: null,
            },
            actionControl: {
                label: 'Sipariş kontrol tamamlandığında',
                state: null,
            },
            actionPackaging: {
                label: 'Sipariş paketleme tamamlandığında',
                state: null,
            },
            actionDelivery: {
                label: 'Sipariş teslimat tamamlandığında (Kargo,Elden Tesim)',
                state: null,
            },
            actionDelete: {
                label: 'Sipariş polypus tarafından silindiğinde',
                state: null,
            },
            missing: {
                label: 'Eksik Bildirimde',
                state: null,
            },
            return: {
                label: 'iade',
                state: null,
            },
        }
        if (!this.groupsNameList?.['action-state-changes']?.[channelId]) return true;
        Object.keys(this.groupsNameList['action-state-changes'][channelId]).map(settings => {
            this.actionStatusSettings[channelId][settings]['state'] = this.groupsNameList['action-state-changes'][channelId][settings]
        })
    }

    saveChannelActionStateRole(settings, channelId) {
        let settingsData = {
            channelId: channelId,
            rules: {}
        }
        Object.keys(settings).map(role => {
            settingsData['rules'][role] = settings[role]['state'];
        })
        this.wmsSettings['actionStateChanges'] = settingsData;
        this.saveWmsSettings(settingsData, 'action-state-changes')
    }

    showBranchDialog() {
        let options = {
            controller: 'SettingsController',
            controllerAs: 'vm',
            locals: {},
            bindToController: true,
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/settings/partials/branch-dialog.component.html',
            options
        ).then(response=>{
            if(response){
                this.branches.push(response)
                if (response.default) this.defaultBranch = response.id
                this.ToastService.showHtml('success','Firma bilgileri kayıt edildi')

            }
        })
    }

    editBranchDialog(id) {
        this.API.all("settings/branch/" + id).doGET()
            .then((response) => {
                if (204 === response.response.status) return;
                response = response.data.list;
                let options = {
                    controller: 'SettingsController',
                    controllerAs: 'vm',
                    locals: {
                        branch:{
                            id                  : response.id,
                            name                : response.name,
                            address             : response.address,
                            banks               : response.banks,
                            packageDetail       : response.packageDetail,
                            productImage        : response.productImage,
                            tax                 : response.tax,
                            taxId               : response.taxId,
                            contact             : response.contact,
                            footerMessage       : response.footerMessage,
                            tradeRegistrationId : response.tradeRegistrationId,
                            default             : response.default ? 1 : 0,
                            logo                : null,
                            signature           : null,
                            watermark           : null,
                        },
                        logo        : response.logo && 'data:image/png;base64,'+ response.logo,
                        signature   : response.signature && 'data:image/png;base64,'+ response.signature,
                        watermark   : response.watermark && 'data:image/png;base64,'+ response.watermark,
                        dialog      : 'edit',
                    },
                    bindToController: true,
                    onShowing         : function (scope, element, options, controller) {},
                }
                this.DialogService.fromTemplateV2(
                    './views/angular/app/components/settings/partials/branch-dialog.component.html',
                    options
                ).then(response=>{
                    if(response){
                        this.branches = this.branches.map(b => {
                            if (b.id === response.id) b = response;
                            return b
                        })

                        if (response.default) this.defaultBranch = response.id
                        this.ToastService.showHtml('success','Firma bilgileri güncellendi')
                    }
                })
            });

    }

    addBankRow(){
        this.branch.banks.push({
            name:null,
            iban:null
        })
    }

    deleteBankRow(index) {
        this.branch.banks = this.branch.banks.filter((b,i)=>i !== index);
    }

    loadImage(file,target) {
        if (!file) return false
        if (file[0].size >3145728){
            this.ToastService.error('Dosya boyutu en fazla 3mb olabilir')
            return ;
        }

        let parent = this
        parent.branch[target] =  parent[target] =null;
        const img = new Promise((resolve, reject)=>{
            parent.branch[target] = file;
            const reader = new FileReader();
            reader.readAsDataURL(parent.branch[target][0]);
            reader.onload = () => resolve(reader.result);
            reader.onerror = ()=> reject(reader.error)
        })

        Promise.all([img]).then(base64=>{
            parent[target] = base64
        }).catch(error=>{
            console.log('img-error->',error)
        })
    }

    cancelBranch() {
        this.DialogService.$mdDialog.hide()
    }

    sendBranch() {
        let parent =this;
        if (!this.branch.name){
            this.ToastService.error('Firma ismi alanı boş bırakılamaz')
            return false;
        }

        if (!this.branch.address){
            this.ToastService.error('Firma Adresi alanı boş bırakılamaz')
            return false;
        }

        this.branch.banks.filter(b=> !b.name || !b.iban)
        if (0 < this.branch.banks.filter(b=> !b.name || !b.iban).length){
            this.ToastService.error('Banka bilgileri eksiksiz girilmelidir')
            return false;
        }
        let data = new FormData();

        data.append('default', this.branch.default)
        data.append('packageDetail', this.branch.packageDetail)
        data.append('productImage', this.branch.productImage)
        data.append('name', this.branch.name)
        data.append('address', this.branch.address)
        data.append('banks', JSON.stringify(this.branch.banks));

        data.append('tax', this.branch.tax)
        data.append('taxId', this.branch.taxId)
        data.append('contact', this.branch.contact)
        data.append('footerMessage', this.branch.footerMessage)
        data.append('tradeRegistrationId', this.branch.tradeRegistrationId)


        if (this.branch.logo) {
            data.append('logo', this.branch.logo[0])
        }
        if (this.branch.signature) {
            data.append('signature', this.branch.signature[0])
        }
        if (this.branch.watermark) {
            data.append('watermark', this.branch.watermark[0])
        }

        if (this.branch.deletelogo){
            data.append('deleteLogo', this.branch.deletelogo)
        }
        if (this.branch.deletesignature){
            data.append('deleteSignature', this.branch.deletesignature)
        }
        if (this.branch.deletewatermark){
            data.append('deleteWatermark', this.branch.deletewatermark)
        }

        let url = this.$window.__env.apiUrl + "/api/settings/branch";
        if ('edit' === this.dialog){
            url += '/'+ this.branch.id;
        }
        let xhr = new XMLHttpRequest();

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                if (this.status === 207){
                    const response  = JSON.parse(this.response)
                    parent.DialogService.hide(response.data.list)
                }
            }
        });

        xhr.open("POST", url);
        xhr.setRequestHeader("Authorization", "Bearer " + this.$window.localStorage.satellizer_token,);

        xhr.send(data);
    }

    deleteBranch(id){
        this.API.all("settings/branch/"+id).customDELETE()
            .then((response) => {
                this.ToastService.showHtml('success','Firma bilgisi silindi')
                this.branches = this.branches.filter(b=>b.id !== id)
                if (response.data.newDefaultBranch) this.defaultBranch =response.data.newDefaultBranch;
            }).catch(error=>{

        });
    }
    changeDefaultBranch(id){
        this.API.all("settings/branch/"+id).customPUT()
            .then((response) => {
                this.ToastService.showHtml('success','Varsayılan Firma değiştirildi')
            }).catch(error=>{
        });
    }

    clearImage(key){
        this[key] = this.branch[key] = null ;
        this.branch['delete'+key] = 1;
    }
}

SettingsController.$inject = ["API", "ToastService", "DialogService", "FileUploader", "$window", "$state", "$location", "$rootScope","$scope"];
const SettingsComponent = {
    templateUrl: "./views/angular/app/components/settings/settings.component.html",
    controller: SettingsController,
    controllerAs: "vm",
    bindings: {},
};

export {SettingsComponent, SettingsController}
