import "./dashboard_index.scss"
class DashboardIndexController{
    constructor(API, ToastService, uiGridConstants, $timeout, UserService, $mdBottomSheet){
        'ngInject';

        this.API = API;
        this.ToastService = ToastService;
        this.uiGridConstants = uiGridConstants;
        this.$timeout = $timeout;
        this.UserService = UserService;
        this.$mdBottomSheet = $mdBottomSheet;

        this.orderTotalCount="Hazırlanıyor ...";
        this.elasticSearchStatus = true;
        this.productCount = 0;
        this.video = undefined;
        this.formatMoney = (n, c, d, t) =>{
            var c = isNaN(c = Math.abs(c)) ? 2 : c,
                d = d == undefined ? "," : d,
                t = t == undefined ? "." : t,
                s = n < 0 ? "-" : "",
                i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                j = (j = i.length) > 3 ? j % 3 : 0;

            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        }

        this.criticalStock = {};
        this.locationRevenues = {};
        this.movementStock = {};
        this.dailyWms = {};
        this.stockTurnover = {};
        this.searchEngineInformation = []
        this.earnings = {
            labels: [],
            value: [],
            cache: false,
            createdAt: null,
            options:{
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var datasetLabel = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            var c =  2,
                                d =',',
                                t =  '.',
                                s = datasetLabel < 0 ? "-" : "",
                                i = String(parseInt(datasetLabel = Math.abs(Number(datasetLabel) || 0).toFixed(c))),
                                j = (j = i.length) > 3 ? j % 3 : 0;

                            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(datasetLabel - i).toFixed(c).slice(2) : "") + 'TL';
                        }
                    }
                },
                scales: {
                    yAxes: [
                        {
                            display:true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Kazanç TL'
                            }
                        },
                    ]
                }
            }
        }
        this.generalWms = {
            labels: [],
            data:[
                [],[],[],[],
            ],
            onclick: function (points, evt) {
            },
            datasetOverride :[
                {
                    label: "Sipariş Sayısı",
                    borderWidth: 1,
                    type: 'bar',
                },
                {
                    label: "Tamamlanmayan",
                    borderWidth: 3,
                    type: 'line'
                },
                {
                    label: "Hazırlanıyor",
                    borderWidth: 3,
                    type: 'line'
                },
                {
                    label: "Hazırlanan",
                    borderWidth: 2,

                    type: 'line'
                },
            ],
            options: {
                legend: {
                    position: 'bottom',
                    display: true,
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            type: 'linear',
                            display: true,
                            position: 'left',
                        }
                    ]

                }
            },
            cache: false,
            createdAt: null
        }
        this.dasboard = false;
        this.dasboardList={};
        this.dasboardFilters = {
            generalWms: {
                mode: '1week'
            },
            movementStock: {
                mode: 'year'
            },
            stockTurnover: {
                mode: 'max'
            },
            wholesaleEarnings: {
                month: new Date().getMonth() + 1
            },
        };
        this.updatedMessage = null;
        this.month = {
            1: 'Ocak',
            2: 'Şubat',
            3: 'Mart',
            4: 'Nisan',
            5: 'Mayıs',
            6: 'Haziran',
            7: 'Temmuz',
            8: 'Ağustos',
            9: 'Eylül',
            10: 'Ekim',
            11: 'Kasım',
            12: 'Aralık',
        }
        this.shortcuts = [];
    }

    $onInit() {
        //this.getWidgets();
        //this.getElasticInformation();
        //this.getUserPerformanceReport();
        //this.changeModeProductCount("month");

        var self_=this;
        this.options = {
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: function(tooltipItem, data) {
                var datasetLabel = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                return self_.formatMoney(datasetLabel) + ' TL';
              }
            }
          },
          scales: {
                yAxes: [
                  {
                    display:true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Kazanç TL'
                    }
                  },
                ]
            }
        };

        this.datasetOverride = [{label: 'Kazanç TL'}];
        this.series = ['Kazanç TL'];
        this.video = 'dashboard';

        this.API.all('dashboard').doGET().then((response) => {
            const data = response.data.list.data;
            const updatedSecond = response.data.list.updatedSecond;

            Object.keys(data).map(l=> this.dasboardList[l]=true)
            if (0 === updatedSecond){
                this.updatedMessage = 'Şimdi güncellendi'
            }else if (60 > updatedSecond) {
                this.updatedMessage = updatedSecond +' Saniye önce güncellendi'
            }

            if (60 < updatedSecond) {
                this.updatedMessage = Math.floor(updatedSecond / 60) + '  Dakika ' + updatedSecond % 60 + ' Saniye önce güncellendi'
            }

           // this.searchEngineInformation = data.searchEngine;
            this.criticalStock = data.criticalStock ?? {};
            this.locationRevenues = data.locationRevenues ?? {};
            this.movementStock = data.movementStock ?? {};

            this.dailyWms = data.dailyWms ? data.dailyWms[0] : [];
            this.stockTurnover = data.stockTurnover ?? {};
            this.wholesaleEarnings = data.wholesaleEarnings ?? {};

            this.generalWms.cache = data.generalWms?.cache;
            this.generalWms.createdAt = data.generalWms?.createdAt;
            data.generalWms && data.generalWms?.data?.map(wms => {
                this.generalWms.labels.push(wms.createdAt)
                this.generalWms.data[0].push(wms.action)                                 // Sipariş Sayısı
                this.generalWms.data[1].push(wms.pendingAction)                          // Tamamlanmayan
                this.generalWms.data[2].push(wms.pendingPrepare + wms.pendingControl)    // Hazırlanıyor
                this.generalWms.data[3].push(wms.completedAction)                       // Hazırlanan
            })
            this.earnings.cache = data.earnings?.cache;
            this.earnings.createdAt = data.earnings?.createdAt;
            data.earnings && data.earnings?.data?.map(earning => {
                this.earnings.value.push(parseFloat(earning.totalEarning));
                this.earnings.labels.push(earning.createdAt);
            })

            this.dasboard=true
        })
          .catch((response) => {
              this.dasboard = true;
              //this.ToastService.error(response.data.errors.message[0]);
          });

        this.getMyShortcuts();
    }

    dashboardFilter(dashboard, filter) {
        this.API.all('dashboard').customGET(dashboard,filter).then((response) => {
            if ('generalWms' === dashboard) {
                if (response.data.list){
                    this.generalWms.labels=[];
                    this.generalWms.data[0]=[];
                    this.generalWms.data[1]=[];
                    this.generalWms.data[2]=[];
                    this.generalWms.data[3]=[];
                    response.data?.list?.data?.map(wms=>{
                        this.generalWms.labels.push(wms.createdAt)
                        this.generalWms.data[0].push(wms.action)                                 // Sipariş Sayısı
                        this.generalWms.data[1].push(wms.pendingAction)                          // Tamamlanmayan
                        this.generalWms.data[2].push(wms.pendingPrepare + wms.pendingControl)    // Hazırlanıyor
                        this.generalWms.data[3].push(wms.completedPrepare)                       // Hazırlanan
                    })
                }
            }
            if ('stockTurnover' === dashboard || 'wholesaleEarnings' === dashboard || 'movementStock' === dashboard){
                this[dashboard] = response?.data?.list || [];
            }
            response.message && this.ToastService.showHtml('success', response.message)
        });
    }

    getUserPerformanceReport() {

        this.API.all('dashboard/get-user-performance').post().then((response) => {
            this.performanceData = response.data.performanceData;
            this.performanceLabels = response.data.performanceLabel;
            this.userPerformanceMode = response.data.mode;
        });
    }

    getWidgets() {
        var parent = this;

        this.API.all('dashboard/get-widgets').doGET().then((response) => {
            this.orderTotalCount = response.data.orderTotalCount;
            this.labels = response.data.label;
            this.earningMoney = response.data.value;
            this.locationRevenues = response.data.locationRevenues;
            //parent.ToastService.success('Genel görünüm listeleniyor.');

        });
    }

    getElasticInformation() {
        this.API.all('dashboard/get-elastic-information').doGET().then((response) => {
            this.elasticSearchStatus = response.data.elasticSearchStatus;
            this.indexInformation = response.data.indexInformation;

        });
    }

    changeModeUserPerformance(mode='day') {
        var reqData = {
            mode: mode
        };

        this.API.all('dashboard/get-user-performance').post(reqData).then((response) => {
            this.performanceData = response.data.performanceData;
            this.performanceLabels = response.data.performanceLabel;
            this.userPerformanceMode = response.data.mode;
        });
    }

    changeModeProductCount(mode='day') {

        var reqData = {
            mode: mode
        };

        this.API.all('dashboard/get-prepared-products').post(reqData).then((response) => {
            this.preparedProductCount = response.data.preparedProductCount;
            this.preparedProductCountLabel = response.data.preparedProductCountLabel;
            this.prepareProductCountMode = response.data.mode;
        });
    }

    changeMonthForSales(monthId) {

        var reqData = {
            monthId: monthId
        };

        this.API.all('dashboard/get-sales-amount-by-manager').post(reqData).then((response) => {
            this.wholeSale = response.data.wholeSale;
            this.monthName = response.data.monthName;
        });
    }

    removeShortcut(event, shortcut){
        event.stopPropagation();
        event.preventDefault();
        let my_shortcuts = localStorage.getItem('my_shortcuts');
        if (my_shortcuts) {
            my_shortcuts = JSON.parse(my_shortcuts);
        } else {
            this.ToastService.error('Kısayol silinemedi');
            return;
        }
        const indexToRemove = my_shortcuts.findIndex(s => s.name === shortcut.name && s.href === shortcut.href);

        if (indexToRemove !== -1) {
            my_shortcuts.splice(indexToRemove, 1);
            localStorage.setItem('my_shortcuts', JSON.stringify(my_shortcuts));
            this.ToastService.success('Kısayol kaldırıldı');
        } else {
            this.ToastService.error('Belirtilen kısayol bulunamadı');
        }
        this.shortcuts = my_shortcuts;
    }
    getMyShortcuts() {
        this.shortcuts = localStorage.getItem('my_shortcuts');

        if (this.shortcuts) {
            this.shortcuts = JSON.parse(this.shortcuts);
        } else {
            this.shortcuts = [];
        }
        if (!this.shortcuts || this.shortcuts.length === 0) {
            var parent = this;
            this.API.all('dashboard/shortcut')
                .customGET()
                .then((response) => {
                    parent.shortcuts = response.data.shortcuts;
                    localStorage.setItem('my_shortcuts', JSON.stringify(parent.shortcuts));
                });
        }
    }

    showProductBottomSheet(productId) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                id: productId
            }
        })
    }
}
DashboardIndexController.$inject = ["API", "ToastService", "uiGridConstants", "$timeout", "UserService", "$mdBottomSheet"];
export const DashboardIndexComponent = {
    templateUrl: './views/angular/app/components/dashboard_index/dashboard_index.component.html',
    controller: DashboardIndexController,
    controllerAs: 'vm',
    bindings: {}
}
