import { openIntroductionVideo } from "../../../utils/introduction-videos";
import "./register-form.scss";
import {setToken} from "../../../utils/users";
class RegisterFormController {
  constructor(
    $auth, ToastService, API, $anchorScroll, $window) {
    "ngInject";

    this.$auth = $auth;
    this.$anchorScroll = $anchorScroll;
    this.$window = $window;
    this.ToastService = ToastService;
    this.API = API;
    this.users = {};
  }

  $onInit() {
    this.name = "";
    this.email = "";
    this.password = "";
    this.users = {};
    this.per_page = 10;
    this.filterArr = {};
    this.nameSearchInput = null;
    this.emailSearchInput = null;
    this.statusSearchInput = null;
    this.per_page = 10;
    this.selected_permissions = [];
    this.edit_selected_permissions = [];
    this.edited = [];
    this.edit_user_disabled = [];

    this.API.all("user")
      .customGET("permissions")
      .then((response) => {
        this.permissions = response.data.permissions;
      });

    this.API.all("user")
      .customGET("roles")
      .then((response) => {
        this.roles = response.data.roles_data_with_perm;
      });

    this.create_form = false;
    this.edit_form = false;
    this.roleId = 0;
  }

  register() {
    let user = {
      name: this.name,
      email: this.email,
      password: this.password,
      role: this.create_role,
    };

    this.$auth
      .signup(user)
      .then((response) => {
        //remove this if you require email verification
        //this.$auth.setToken(response.data);

        this.ToastService.success("Kullanıcı oluşturuldu.");
        this.clearRegisterForm();
        this.getUser();
      })
      .catch(this.failedRegistration.bind(this));
  }

  clearRegisterForm() {
    this.name = null;
    this.email = null;
    this.password = null;
    this.create_role = null;
  }

  getUser(
    pageNumber = 1,
    filterArr = this.filterArr,
    per_page = this.per_page
  ) {
    var parent = this;

    this.per_page = per_page;

    let customGet = {
      per_page: per_page,
      page: pageNumber,
    };

    var reqObject = angular.extend(customGet, filterArr);

    reqObject.per_page = this.per_page;

    this.API.all("user")
      .customGET("list", reqObject)
      .then((response) => {
        parent.users = response.data.users;
        parent.current_user_id = response.data.currentUserId;
        parent.total = response.data.users.total;
        parent.current_page = response.data.users.current_page;
        parent.per_page = response.data.users.per_page;
        parent.last_page = response.data.users.last_page;
        parent.prev_page_url = response.data.users.prev_page_url;
        parent.next_page_url = response.data.users.next_page_url;

        parent.userPagesArray = response.data.userPagesArray;

        parent.prev_index = response.data.prevIndex;
        parent.next_index = response.data.nextIndex;
      });

    //this.per_page = per_page;
  }

  failedRegistration(response) {
    if (response.status === 422) {
      for (let error in response.data.errors) {
        return this.ToastService.error(response.data.errors[error][0]);
      }
    }
    this.ToastService.error(response.statusText);
  }

  filter() {
    let filter = {
      per_page: this.per_page,
      pageNumber: 1,
      nameSearchInput: this.nameSearchInput,
      emailSearchInput: this.emailSearchInput,
      statusSearchInput: this.statusSearchInput,
    };

    var parent = this;

    this.API.all("user/filter")
      .post(filter)
      .then((response) => {
        parent.users = response.data.users;
        parent.total = response.data.users.total;
        parent.current_user_id = response.data.currentUserId;
        parent.current_page = response.data.users.current_page;
        parent.per_page = response.data.users.per_page;
        parent.last_page = response.data.users.last_page;
        parent.prev_page_url = response.data.users.prev_page_url;
        parent.next_page_url = response.data.users.next_page_url;

        parent.userPagesArray = response.data.userPagesArray;

        parent.prev_index = response.data.prevIndex;
        parent.next_index = response.data.nextIndex;
        this.filterArr = filter;
      });
  }

  userStatusChange(status, user_id) {
    let reqData = {
      status: status,
      user_id: user_id,
    };

    this.API.all("user/change-status")
      .post(reqData)
      .then((response) => {
        this.ToastService.success(response.data.msg);
      })
      .catch(this.statusChangeFailed.bind(this));
  }

  statusChangeFailed(response) {
    if (response.status === 422) {
      for (let error in response.data.errors) {
        return this.ToastService.error(response.data.errors[error][0]);
      }
    }

    if (response.status === 401) {
      for (let error in response.data.errors) {
        return this.ToastService.error(response.data.errors[error][0]);
      }
    }

    this.ToastService.error(response.statusText);
  }

  accordion(data) {

    this.create_form = data;
    localStorage.setItem("create_form", data);
  }

  closeEditForm() {
    this.edit_form = false;
    this.edited = false;
  }

  parseBoolean(data) {
    if (data == "false" || !data) {
      return false;
    } else if (data == "true" || data) {
      return true;
    }
  }

  saveRole() {
    var parent = this;
    this.selected_permissions_array = [];

    for (var key in this.selected_permissions) {
      if (this.selected_permissions.hasOwnProperty(key)) {
        if (this.selected_permissions[key]) {
          this.selected_permissions_array.push(key);
        }
      }
    }

    var reqData = {
      selected_permissions: this.selected_permissions_array,
      role_name: this.role_name,
      role_description: this.role_description,
    };

    this.API.all("user/save-role")
      .post(reqData)
      .then((response) => {
        parent.ToastService.success("Rol oluşturuldu.");
        parent.clearSaveRoleForm();
        parent.deselectAllPermissions();
        parent.roles = response.data.roles_data_with_perm;
        this.refreshToken()
      });
  }

  clearSaveRoleForm() {
    this.selected_permissions_array = null;
    this.role_name = null;
    this.role_description = null;
  }

  selectAllPermissions(mode = "add") {
    var i = 1;
    var parent = this;
    if (mode == "add") {
      this.selected_permissions = [];

      angular.forEach(this.permissions, function (value, key) {
        parent.selected_permissions[value.name] = true;
        i++;
      });
    } else {
      this.edit_selected_permissions = [];

      angular.forEach(this.permissions, function (value, key) {
        parent.edit_selected_permissions[value.name] = true;
        i++;
      });
    }
  }

  deselectAllPermissions() {
    this.selected_permissions = [];
    this.edit_selected_permissions = [];
  }

  editRole(roleId) {
    this.edit_form = true;
    this.edited = new Array();
    let reqObject = {
      role_id: roleId,
    };
    this.edit_selected_permissions = [];

    var parent = this;
    var body = $("html, body");
    this.edit_role_id = roleId;

    this.API.all("user")
      .customGET("get-role-by-id", reqObject)
      .then((response) => {
        var perm = response.data.roles_data_with_perm.perm;

        if (perm) {
          angular.forEach(perm, function (value, key) {
            parent.edit_selected_permissions[value.name] = true;
          });
        }

        parent.edit_role_name = response.data.roles_data_with_perm.role.name;
        parent.edit_role_description =
          response.data.roles_data_with_perm.role.description;

        body.animate({ scrollTop: 0 }, "600", "swing");

        var role = response.data.roles_data_with_perm.role;
        parent.edited.push({ [role.id]: true });
      });
  }

  updateRole(roleId) {


    //this.edited[0][this.roleId] = false;

    this.edit_selected_permissions_array = [];

    for (var key in this.edit_selected_permissions) {
      if (this.edit_selected_permissions.hasOwnProperty(key)) {
        if (this.edit_selected_permissions[key]) {
          this.edit_selected_permissions_array.push(key);
        }
      }
    }

    let reqData = {
      role_id: roleId,
      role_name: this.edit_role_name,
      role_description: this.edit_role_description,
      perm: this.edit_selected_permissions_array,
    };

    var parent = this;

    this.API.all("user/update-role")
      .post(reqData)
      .then((response) => {
        parent.ToastService.success(
          parent.edit_role_name + " rolü güncellendi."
        );
        parent.roles = response.data.roles_data_with_perm;
      });
    //
  }

  deleteRole(roleId) {
    let reqData = {
      role_id: roleId,
    };

    var parent = this;

    this.API.all("user/delete-role")
      .post(reqData)
      .then((response) => {
        parent.roles = response.data.roles_data_with_perm;
      });
  }

  editUser(userId) {
    let reqObject = {
      user_id: userId,
    };
    this.edit_user_role_id = null;
    this.edit_user_form = true;
    this.edit_user_disabled = [];
    this.edit_user_disabled[userId] = true;
    var body = $("html, body");

    this.API.all("user/get-by-id")
      .doPOST(reqObject)
      .then((response) => {
        this.edit_user_name = response.data.userObj.name;
        this.edit_user_email = response.data.userObj.email;
        this.edit_user_id = response.data.userObj.id;
        if (response.data.roleObj != null) {
          this.edit_user_role_id = response.data.roleObj.id;
        }
        document.documentElement.scrollTop = 0;
      });
  }

  updateUser(userId) {
    let reqObject = {
      user_id: userId,
      name: this.edit_user_name,
      email: this.edit_user_email,
      role_id: this.edit_user_role_id,
      password: this.edit_password,
    };
    var parent = this;

    this.API.all("user/update")
      .doPOST(reqObject)
      .then((response) => {
        parent.ToastService.success(
          parent.edit_user_name + " kullanıcısı güncellendi."
        );
        this.edit_user_disabled[userId] = false;
      });
  }

  closeUserEditForm() {
    this.edit_user_form = false;
    this.edit_user_disabled = [];
  }

  openIntroductionVideoModal(video) {
    openIntroductionVideo(video);
  }

  clearUserSearchForm() {
    this.nameSearchInput = null;
    this.emailSearchInput = null;
    this.statusSearchInput = null;
  }

  clearUserAddForm() {
    this.name = null;
    this.email = null;
    this.password = null;
    this.create_role = null;
  }
  refreshToken() {
    this.API.all("auth/token")
        .doGET()
        .then((response) => {
          if (response.data.token) setToken(response.data.token)
          else this.ToastService.showHtml('warn','Token Yenilenemedi')
        });
  }
}
RegisterFormController.$inject = ["$auth", "ToastService", "API", "$anchorScroll", "$window"];
export const RegisterFormComponent = {
  templateUrl:
    "./views/angular/app/components/register-form/register-form.component.html",
  controller: RegisterFormController,
  controllerAs: "vm",
  bindings: {},
};
