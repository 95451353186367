import {ok} from "glyphicons";

class DialogService {
    constructor($mdDialog) {
        'ngInject';

        this.$mdDialog = $mdDialog
        this.containerData = [];
    }

    setContainerData(data){
        this.containerData = data;
    }

    getContainerData(){
        return this.containerData;
    }

    fromTemplate(template, options) {
        if (!template) {
            return false;
        }

        if (!options) {
            options = {};
        }

        options.templateUrl = './views/angular/dialogs/' + template + '/' + template + '.dialog.html?v=19'
        options.clickOutsideToClose= true;
        return this.$mdDialog.show(options);
    }

    fromTemplateV2(template, options) {
        if (!template) {
            return false;
        }

        if (!options) {
            options = {};
        }

        options.templateUrl = template
        options.clickOutsideToClose= true;

        return this.$mdDialog.show(options);
    }

    hide(params) {
        return this.$mdDialog.hide(params);
    }

    cancel(){
        return this.$mdDialog.cancel();
    }

    alert(title, content, params) {
        let alert = this.$mdDialog.alert(params)
            .title(title)
            .textContent(content)
            .ariaLabel(content)
            .ok('Tamam');

        this.$mdDialog.show(alert);
    }

    confirm(title, content, params) {
        let confirm = this.$mdDialog.confirm(params)
            .title(title)
            .textContent(content)
            .ariaLabel(content)
            .ok('Tamam')
            .cancel('Vazgeç');

        return this.$mdDialog.show(confirm);
    }

    prompt(title, content, placeholder, params) {
        let prompt = this.$mdDialog.prompt(params)
            .title(title)
            .textContent(content)
            .placeholder(placeholder)
            .ariaLabel(placeholder)
            .ok('Tamam')
            .cancel('Vazgeç');

        return this.$mdDialog.show(prompt);
    }
}
DialogService.$inject = ["$mdDialog"];
export {DialogService};
