import {introductionVideos, openIntroductionVideo} from "../../../utils/introduction-videos";
import "./rightbar.scss";

class RightbarController {
  constructor(
    $auth,
    ToastService,
    $state,
    $mdSidenav,
    $translate,
    UserService,
    $http,
    $q,
    $rootScope,
    $stateParams,
    API,
    $location
  ) {
    "ngInject";

    this.$auth = $auth;
    this.ToastService = ToastService;
    this.state = $state;
    this.$mdSidenav = $mdSidenav;
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.$translate = $translate;
    this.API = API;
    this.$location = $location;
    this.$state = $state;
    this.pageHelp = false;
    this.helpContent= false;
    this.introductionVideos = introductionVideos;
  }

  $onInit() {
  }

  wizardItemClick(wizardItem) {
    // aynı state ise
    if (this.state.is(wizardItem.link.state)) {
      //tab farklıysa
      if (wizardItem.link.params.tab != this.$location.search().tab) {
        this.state.params.tab = wizardItem.link.params.tab;
        this.$location.search(wizardItem.link.params);
      }
    } else {
      this.state.go(wizardItem.link.state, wizardItem.link.params)
    }
  }

  openIntroductionVideoModal() {
    let video = this.$state.current.name.split(".")[1]+'_'+this.$state.params.tab;
    openIntroductionVideo(video);
  }

  showIcon(status) {
    return (status ? 'check_circle' : 'radio_button_unchecked');
  }

  close() {
    this.$mdSidenav("right").close();
  }

  open() {
    this.$mdSidenav("right").open();
  }

  makeShortcut() {
    let my_shortcuts = localStorage.getItem('my_shortcuts');
    if (my_shortcuts) {
      my_shortcuts = JSON.parse(my_shortcuts);
    } else {
      my_shortcuts = [];
    }

    const currentState = this.$state.current;
    const tab = this.$stateParams.tab ? this.$stateParams.tab : 0;
    const shortcutNames = currentState.data.shortcutNames;

    if (!shortcutNames){
      this.ToastService.error('Sayfa kısayolu oluşturulmaya uygun değildir')
      return;
    }
    const newShortcut = {
      name: shortcutNames && shortcutNames[tab] ? this.$translate.instant(shortcutNames[tab]) : currentState.name,
      icon: this.$state.current.data.icon,
      href: this.$state.current.name+'({tab:'+ tab + '})'
    };
    const isDuplicate = my_shortcuts.some(shortcut =>
        shortcut.name === newShortcut.name &&
        shortcut.href === newShortcut.href
    );

    if (!isDuplicate) {
      my_shortcuts.push(newShortcut);
      localStorage.setItem('my_shortcuts', JSON.stringify(my_shortcuts));
      this.ToastService.success('Kısayol oluşturuldu')
    } else {
      this.ToastService.error('Bu sayfa daha önce kısayol olarak eklenmiş')
    }
  }
}

RightbarController.$inject = ["$auth", "ToastService", "$state", "$mdSidenav", "$translate", "UserService", "$http", "$q", "$rootScope", "$stateParams", "API", "$location"];

export const RightbarComponent = {
  templateUrl: "./views/angular/app/components/rightbar/rightbar.component.html",
  controller: RightbarController,
  controllerAs: "vm",
  bindings: {
    customerInformation:"<",
  },
};
